import { newGenerationHomeDataProps } from "../interface";
import syllabusImg from '@icons/syllabus.svg';
import lessonPlanner from '@icons/lesson_planner.svg';
import ShareImg from '@public/share.svg';
import Copy from '@public/copy.svg';
import DownloadImage from '@public/download.svg';
import research from '@icons/research.svg';
import article from '@icons/article.svg';
import comprehensive from '@icons/comphrensive.svg';
import socialMedia from '@icons/social_media.svg';
import letterImg from '@icons/letter.svg';
import { copyData, handleShare } from "@src/utils/app_functions";
import { PromptOptionsType } from "@src/core/interfaces/general";
import transcribe from '@assets/essay/transcription.png';

import { ReportCardSvg } from "../svg";


const newGenerationHomeData: Array<newGenerationHomeDataProps> = [
    {
        img: syllabusImg,
        head: 'Copywriting Tool',
        text: 'Create a comprehensive copywriting plan for your project.',
        pageIndex: 1,
        pageName: "Copywriting Tool",
        label: 'copywriting_tool',
    },
    {
        img: comprehensive,
        head: 'Campaign Ad Generator',
        text: 'Generate ads that resonate with your audience and drive results.',
        pageIndex: 2,
        pageName: "Campaign generator",
        label: 'campaign_generator',
    },
    {
        img: letterImg,
        head: 'Policy Writer',
        text: 'Craft compelling documents that persuade and inspire action.',
        pageIndex: 3,
        pageName: "Policy Writer",
        label: 'policy_writer',
    },
    {
        img: lessonPlanner,
        head: 'Job Description',
        text: 'Craft compelling job descriptions that attract top talent.',
        pageIndex: 4,
        pageName: "Job Description",
        label: 'job_description',
        imgElement: ReportCardSvg()
    },
    {
        img: research,
        head: 'Project Report Generator',
        text: 'Produce detailed project reports that comprehensively summarize project objectives.',
        pageIndex: 5,
        pageName: "Project Report",
        label: 'project_report',
    },
    {
        img: transcribe,
        head: 'Transcriber',
        text: 'Accurately transcribe audio content, ensuring high-quality text output.',
        pageIndex: 6,
        pageName: "Transcriber",
        label: 'transcriber',
    },
    {
        img: socialMedia,
        head: 'Social Media Writer',
        text: 'Turn your ideas into shareable, likable, and lovable social media content, fast',
        pageIndex: 7,
        pageName: "Social Media",
        label: 'social_media',
    },

  
  
];

const contentPromptOptionsData = ({response, onSuccess, urlRef, showDownload}: {
    response:string;
    onSuccess: React.Dispatch<React.SetStateAction<boolean>>;
    urlRef: React.MutableRefObject<string>;
    showDownload: React.Dispatch<React.SetStateAction<boolean>>;
} ): PromptOptionsType[]  => [
    {
        name: 'Share',
        icon: ShareImg,
        onClick: () => handleShare({
            response,
            onSuccess,
            urlRef,
        }),
    },
    {
        name: 'Copy',
        icon: Copy,
        onClick: () => copyData(response),
    },
    {
        name: 'Download',
        icon: DownloadImage,
        onClick: () => showDownload(true),
    },
];

const contentPromptOptionsData2 = ({response, showDownload}: {
    response:string;
    showDownload: React.Dispatch<React.SetStateAction<boolean>>;
} ): PromptOptionsType[]  => [
    {
        name: 'Copy',
        icon: Copy,
        onClick: () => copyData(response),
    },
    {
        name: 'Download',
        icon: DownloadImage,
        onClick: () => showDownload(true),
    },
];


export {
    newGenerationHomeData,
    contentPromptOptionsData,
    contentPromptOptionsData2,
}
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import React, { Suspense } from 'react';
import StatusComponent from './pages/acquisition/components/status/index.tsx';

import Root from './pages/dashboard/pages/root';
import Chat from './pages/dashboard/pages/chat';
import NotFoundPage from './pages/404';
import Curriculum from './pages/dashboard/pages/curriculum/index.tsx';
import Slide from './pages/dashboard/pages/slide/index.tsx';
import Transcriber from './pages/dashboard/pages/transcriber';
import EditorPage from './pages/dashboard/pages/essay/editor/index.tsx';
import Essay from './pages/dashboard/pages/essay';
import Generate from './pages/dashboard/pages/generate/index.tsx';
import Research from './pages/dashboard/pages/research/index.tsx';
import Image from './pages/dashboard/image/index.tsx';
import Assessment from './pages/dashboard/pages/assessment_2/index.tsx';
import NewEssayPage from './pages/dashboard/pages/essay/new_index.tsx';
import ComingSoonPage from './pages/dashboard/pages/coming_soon/index.tsx';
import Speech from './pages/dashboard/pages/speech/index.tsx';
import PricingHome from './pages/acquisition/pricing/home/page.tsx';
import DashboardPricing from './pages/dashboard/pages/dashboard_pricing/index.tsx';
import Layout from './pages/acquisition/layout/Layout.tsx';
import Cookies from './pages/acquisition/policies/cookies/index.tsx';
import TermsOfService from './pages/acquisition/policies/tos/index.tsx';
import Privacy from './pages/acquisition/policies/privacy/index.tsx';
import ImageEditor from './pages/dashboard/pages/image_editor/index.tsx';
import SuccessPage from './pages/dashboard/pages/payment_successful/index.tsx';
import ProfileScreen from './pages/dashboard/pages/profile/index.tsx';
import SchoolAdmin from './pages/dashboard/pages/school_admin/dashboard/index.tsx';
import Teachers from './pages/dashboard/pages/school_admin/teachers/index.tsx';
import AddTeachers from './pages/dashboard/pages/school_admin/add_teacher/index.tsx';
import ContactUsPage from './pages/dashboard/pages/school_admin/contact_us/index.tsx';
import SchoolProfileScreen from './pages/dashboard/pages/school_admin/profile/index.tsx';
import BusinessResources from './pages/dashboard/pages/business_resources/index.tsx';
import MaintenancePage from './pages/acquisition/maintanance/maintainance.tsx';
import SchoolLetters from './pages/dashboard/pages/school_letters copy/index.tsx';
import MediaResources from './pages/dashboard/pages/media_resources/index.tsx';
import Affiliate from './pages/dashboard/pages/affiliate/dashboard/index.tsx';
import Referrals from './pages/dashboard/pages/affiliate/dashboard/referrals.tsx';
import Transactions from './pages/dashboard/pages/affiliate/dashboard/transactions.tsx';


// const Root = React.lazy(() =>  import('./pages/dashboard/pages/root') );
// const Chat = React.lazy(() => import('./pages/dashboard/pages/chat'));
// const NotFoundPage = React.lazy(() => import('./pages/404'));
// const Curriculum = React.lazy(() => import('./pages/dashboard/pages/curriculum/index.tsx'));
// const Slide = React.lazy(() => import('./pages/dashboard/pages/slide/index.tsx'));
// const Transcriber = React.lazy(() => import('./pages/dashboard/pages/transcriber'));
// const EditorPage = React.lazy(() => import('./pages/dashboard/pages/essay/editor/index.tsx'));
// const Essay = React.lazy(() => import('./pages/dashboard/pages/essay'));
// const Generate = React.lazy(() => import('./pages/dashboard/pages/generate/index.tsx'));
// const Research = React.lazy(() => import('./pages/dashboard/pages/research/index.tsx'));
// const Image = React.lazy(() => import('./pages/dashboard/image/index.tsx'));
// const Assessment = React.lazy(() => import('./pages/dashboard/pages/assessment_2/index.tsx'));
// const NewEssayPage = React.lazy(() => import('./pages/dashboard/pages/essay/new_index.tsx'));
// const ComingSoonPage = React.lazy(() => import('./pages/dashboard/pages/coming_soon/index.tsx'));
// const Speech = React.lazy(() => import('./pages/dashboard/pages/speech/index.tsx'));
// const PricingHome = React.lazy(() => import('./pages/acquisition/pricing/home/page.tsx'));
// const DashboardPricing = React.lazy(() => import('./pages/dashboard/pages/dashboard_pricing/index.tsx'));
// const Layout = React.lazy(() => import('./pages/acquisition/layout/Layout.tsx'));
// const Cookies = React.lazy(() => import('./pages/acquisition/policies/cookies/index.tsx'));
// const TermsOfService = React.lazy(() => import('./pages/acquisition/policies/tos/index.tsx'));
// const Privacy = React.lazy(() => import('./pages/acquisition/policies/privacy/index.tsx'));
// const ImageEditor = React.lazy(() => import('./pages/dashboard/pages/image_editor/index.tsx'));
// const SuccessPage = React.lazy(() => import('./pages/dashboard/pages/payment_successful/index.tsx'));
// const ProfileScreen = React.lazy(() => import('./pages/dashboard/pages/profile/index.tsx'));
// const SchoolAdmin = React.lazy(() => import('./pages/dashboard/pages/school_admin/dashboard/index.tsx'));
// const Teachers = React.lazy(() => import('./pages/dashboard/pages/school_admin/teachers/index.tsx'));
// const AddTeachers = React.lazy(() => import('./pages/dashboard/pages/school_admin/add_teacher/index.tsx'));
// const ContactUsPage = React.lazy(() => import('./pages/dashboard/pages/school_admin/contact_us/index.tsx'));
// const SchoolProfileScreen = React.lazy(() => import('./pages/dashboard/pages/school_admin/profile/index.tsx'));
// const BusinessResources = React.lazy(() => import('./pages/dashboard/pages/business_resources/index.tsx'));
// const MaintenancePage = React.lazy(() => import('./pages/acquisition/maintanance/maintainance.tsx'));
// const SchoolLetters = React.lazy(() => import('./pages/dashboard/pages/school_letters copy/index.tsx'));
// const MediaResources = React.lazy(() => import('./pages/dashboard/pages/media_resources/index.tsx'));
// const Affiliate = React.lazy(() => import('./pages/dashboard/pages/affiliate/dashboard/index.tsx'));
// const Referrals = React.lazy(() => import('./pages/dashboard/pages/affiliate/dashboard/referrals.tsx'));
// const Transactions = React.lazy(() => import('./pages/dashboard/pages/affiliate/dashboard/transactions.tsx'));


const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route path={'/dashboard'} element={<Root />} errorElement={<NotFoundPage />}>
                <Route path="chat" element={<Chat />} />
                
                <Route path="generate_content" element={<Generate />} />
                <Route path="assessment" element={<Assessment />} />
                <Route path="article" element={<NewEssayPage />} />
                <Route path="article/editor" element={<EditorPage />} />
                <Route path="curriculum" element={<Curriculum />} />
                <Route path="speech_synthesizer" element={<Speech />} />
                <Route path="video_mode" element={<ComingSoonPage />} />
                <Route path="image_maker" element={<Image />} />
                <Route path="image_maker/edit" element={<ImageEditor />} />
                <Route path="research" element={<Research />} />
                <Route path="research" element={<Research />} />
                <Route path="class-resources" element={<SchoolLetters />} />
                <Route path="media-resources" element={<MediaResources />} />
                <Route path="business-resources" element={<BusinessResources />} />
                <Route path="school-admin" element={<SchoolAdmin />} />
                <Route path="school-profile" element={<SchoolProfileScreen />} />
                <Route path="school-admin/school-teacher" element={<Teachers />} />
                <Route path="school-admin/school-teacher/add-teacher" element={<AddTeachers />} />
                <Route path="pricing" element={<DashboardPricing />} />
                <Route path="payment-success" element={<SuccessPage />} />
                <Route path="profile" element={<ProfileScreen />} />
            </Route>
            ,
            <Route path="" element={<Layout isLoggedIn={true} />} errorElement={<NotFoundPage />}>
                <Route path="" element={<Layout isLoggedIn={true} />} errorElement={<NotFoundPage />} />
                <Route path="features" element={<Layout isLoggedIn={true} />} errorElement={<NotFoundPage />} />
                <Route path="cookies" element={<Cookies />} errorElement={<NotFoundPage />} />
                <Route path="terms-of-service" element={<TermsOfService />} errorElement={<NotFoundPage />} />
                <Route path="privacy-policy" element={<Privacy />} errorElement={<NotFoundPage />} />
                <Route path="privacy-policy" element={<Privacy />} errorElement={<NotFoundPage />} />
                <Route path="pricing" element={<PricingHome />} />
                <Route path="status" element={<StatusComponent />} />
            </Route>,


            <Route path={'/affiliate'} element={<Root />} errorElement={<NotFoundPage />}>
                <Route path="dashboard" element={<Affiliate />} />
                <Route path="referrals" element={<Referrals />} />
                <Route path="transactions" element={<Transactions />} />
            </Route>
            
        </>,
    ),
);

export default router;

import React from "react";
import { MyCircleIcon, MyFileIcon } from "@components/Svg";
interface UploadRecordProps {
  onUpload: () => void;
}

const UploadRecord = ({onUpload}: UploadRecordProps) => {
  return <div className="upload--audio--card" onClick={onUpload}>
    <div className=" border-2 border-gray-500 h-4 w-4 " style={{borderRadius:"50%"}}/>
    <div style={{ marginLeft: "12px" }}><MyFileIcon/></div>
    <span style={{ marginLeft: "8px" }}>Upload Audio</span>
  </div>;
};

export default UploadRecord;

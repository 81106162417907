import minus from '@icons/minus.svg';
import plus from '@icons/plus.svg';
import { useState } from 'react';

type AccordionProps = {
    question: string;
    response: string;
};

const Faq = () => {
    const accordionQuestion: Array<AccordionProps> = [
        {
            question: 'What is Gmind.ai?',
            response:
                "GMind AI saves educator's time taking care of the content creation process allowing them concentrate on the core of their work which is teaching their students.",
        },
        {
            question: 'How does Gmind.ai Work?',
            response:
                'Gmind.ai employs advanced AI and machine learning algorithms to provide insights, generate predictive intelligence, and guide problem-solving. When users input data or ask for information, the system learns from the given input and provides output, either by giving information, making recommendations, or performing tasks. As the platform engages with more data over time, its learning curve improves and it becomes more proficient at delivering user-specific results.',
        },
        {
            question: 'What Services Does Gmind.ai Offer?',
            response:
                'Gmind.ai offers a wide range of AI-based services. This includes a powerful AI language model capable of engaging communication, natural language processing tools for thorough conversation analysis, data analytics solutions for in-depth interpretation and insight generation, and machine learning systems for robust predictive modeling. Additionally, we also offer custom AI solutions tailored to meet your unique business needs and objectives.',
        },
        {
            question: 'How Can Gmind.ai Benefit Users?',
            response:
                'Gmind.ai delivers multiple benefits across various industries. For businesses, it can revolutionize operations, enhance productivity, and improve customer relationship. Through AI-driven analysis, businesses can make data-based decisions, identify pain points, and forecast future trends.\n\nFor individuals, Gmind.ai can be a powerful assistant for day-to-day tasks, academic research, study, content generation, and more. Its language model can simplify communication and ensure understanding even with complex concepts. With continuous use, Gmind.ai grows to understand the user better, leading to more personalized results and experiences.',
        },
        {
            question: 'Is Gmind.ai Safe to Use?',
            response:
                "Absolutely. Gmind.ai has robust security protocols integrated into its systems to ensure the safety and privacy of user data. We respect confidentiality and abide strictly by international standards and legal requirements for data protection. User data is only employed to improve our system's efficiency and effectiveness.",
        },
    ];

    const handleAccordion = (index: number) => {
        if (currentAccordion === index) {
            setCurrentAccordion(-1);
        } else {
            setCurrentAccordion(index);
        }
    };

    const [currentAccordion, setCurrentAccordion] = useState(0);

    return (
        <div
            id="faq_section"
            className="w-full overflow-y-auto mt-10 mb-10 flex flex-col gap-y-3"
            style={{
                maxHeight: '100vh',
            }}
        >
            <h2 className="font-mediumm text-[2rem] text-[var(--gmind-black)]" style={{ alignSelf: 'center' }}>
                FAQS
            </h2>

            {accordionQuestion.map((item, index) => (
                <div className="w-full flex flex-col gap-y-1" key={index}>
                    <div
                        onClick={() => handleAccordion(index)}
                        className="flex items-center justify-between p-3"
                        style={{
                            borderBottom: '1.5px solid var(--gmind-playground-grey)',
                        }}
                    >
                        <h3 className="font-medium text-[1rem] text-[var(--gmind-black)]">{item.question}</h3>
                        <img src={currentAccordion === index ? minus : plus} alt="arrow drop down" />
                    </div>

                    <div
                        className="flex flex-col p-3 gap-y-3 bg-[var(--gmind-playground-grey)] shadow-lg"
                        style={{
                            borderLeft: '3px solid var(--gmind-orange)',
                            display: currentAccordion === index ? 'block' : 'none',
                        }}
                    >
                        <h3 className="font-medium text-[1rem] text-[var(--gmind-orange)]">{item.question}</h3>

                        <p className="w-full font-medium text-[0.9rem] text-[--gmind-accordion-header]">
                            {item.response}
                        </p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Faq;

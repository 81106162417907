import { Button, Form, Input } from 'antd';
import { useState } from 'react';
import './style.scss';
import AIGenerate from '@icons/generate.svg';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';

const { TextArea } = Input;

interface BlogWriterLoadingStates {
    title: boolean;
    objective: boolean;
    targetAudience: boolean;
    toneStyle: boolean;
    keyTopics: boolean;
    introduction: boolean;
    contentStructure: boolean;
    mainPoints: boolean;
    callToAction: boolean;
    conclusion: boolean;
    multimediaElements: boolean;
}

interface BlogWriterFormValues {
    title: string;
    objective: string;
    targetAudience: string;
    toneStyle: string;
    keyTopics: string;
    introduction: string;
    contentStructure: string;
    mainPoints: string;
    callToAction: string;
    conclusion: string;
    multimediaElements: string;
}

const CustomTextAreaWithButton = ({
    placeholder,
    uniqueKey,
    loadingStates,
    setLoadingStates,
    value, 
    onChange,                                                     
}: {
    placeholder: string;
    uniqueKey: keyof BlogWriterLoadingStates;
    loadingStates: BlogWriterLoadingStates;
    setLoadingStates: React.Dispatch<React.SetStateAction<BlogWriterLoadingStates>>;
    value?: string;
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}) => {
    const handleButtonClick = () => {
        setLoadingStates((prevState) => ({
            ...prevState,
            [uniqueKey]: true,
        }));

        setTimeout(() => {
            setLoadingStates((prevState) => ({
                ...prevState,
                [uniqueKey]: false,
            }));
        }, 3000);
    };

    return (
        <div className="relative">
            <TextArea
                rows={4}
                placeholder={placeholder}
                className="p-2 border-gray-200 focus:outline-none"
                style={{ outline: 'none', boxShadow: 'none' }}
                value={value}
                onChange={onChange}
            
            />
            {!loadingStates[uniqueKey] ? (
                <div
                    onClick={handleButtonClick}
                    className="cursor-pointer shadow-md rounded-[1rem] absolute top-[55px] right-[10px] py-[0.3rem] px-[1rem] bg-white text-normal text-[0.75rem] text-[var(--gmind-black)] flex gap-x-2"
                >
                    <img src={AIGenerate} alt="" />
                    <span>Use Gmind AI</span>
                </div>
            ) : (
                <div className="absolute top-[65px] right-[20px] ">
                    <CircularProgress size={15} className='w-1 h-1' />
                </div>
            )}
        </div>
    );
};

const BlogWriter = ({ onGenerate }: { onGenerate: any }) => {
    const [form] = Form.useForm<BlogWriterFormValues>();
    const [loadingStates, setLoadingStates] = useState<BlogWriterLoadingStates>({
        title: false,
        objective: false,
        targetAudience: false,
        toneStyle: false,
        keyTopics: false,
        introduction: false,
        contentStructure: false,
        mainPoints: false,
        callToAction: false,
        conclusion: false,
        multimediaElements: false,
    });
    
    const [currentPage, setCurrentPage] = useState(1); // Track the current page

    const onFinish = (values: BlogWriterFormValues) => {
        const {
            title,
            objective,
            targetAudience,
            toneStyle,
            keyTopics,
            introduction,
            contentStructure,
            mainPoints,
            callToAction,
            conclusion,
            multimediaElements,
        } = values;

        if (!title || !objective || !targetAudience || !toneStyle || !keyTopics || !introduction || !contentStructure || !mainPoints || !callToAction || !conclusion || !multimediaElements) {
            toast.error('Please fill all the required fields before generating.');
            return;
        }

        const promptMessage = `
            Title: ${title}
            Objective: ${objective}
            Target Audience: ${targetAudience}
            Tone and Style: ${toneStyle}
            Key Topics and Keywords: ${keyTopics}
            Introduction and Hook: ${introduction}
            Content Structure: ${contentStructure}
            Main Points and Supporting Details: ${mainPoints}
            Call to Action: ${callToAction}
            Conclusion: ${conclusion}
            Multimedia Elements: ${multimediaElements}
        `;

        onGenerate(promptMessage);
    };

    const handleNext = () => {
        if (currentPage < 2) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            initialValues={{
                title: '',
                objective: '',
                targetAudience: '',
                toneStyle: '',
                keyTopics: '',
                introduction: '',
                contentStructure: '',
                mainPoints: '',
                callToAction: '',
                conclusion: '',
                multimediaElements: '',
            }}
        >
            <h1 className="text-xl font-bold font-Poppins mb-4 mt-4">Blog Writer AI</h1>
            <p className="text-md font-Poppins mb-4">
                Create compelling blog posts with the help of this AI tool.
            </p>

            {currentPage === 1 && (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <Form.Item
                        label="Title"
                        name="title"
                        rules={[{ required: true, message: 'Title is required' }]}
                    >
                       <Input placeholder="Enter the title of the blog post" />
                    </Form.Item>
                    <Form.Item
                        label="Objective"
                        name="objective"
                        rules={[{ required: true, message: 'Objective is required' }]}
                    >
                       <Input placeholder="Describe the purpose of the blog post" />
                    </Form.Item>
                    <Form.Item
                        label="Target Audience"
                        name="targetAudience"
                        rules={[{ required: true, message: 'Target Audience is required' }]}
                    >
                        <CustomTextAreaWithButton
                            placeholder="Identify and tailor the content to your audience"
                            uniqueKey="targetAudience"
                            loadingStates={loadingStates}
                            setLoadingStates={setLoadingStates}
                            value={form.getFieldValue('targetAudience')} // Bind value to form field
                            onChange={(e) => form.setFieldValue('targetAudience', e.target.value)} // Update form value on change
                        />
                    </Form.Item>
                    <Form.Item
                        label="Tone and Style"
                        name="toneStyle"
                        rules={[{ required: true, message: 'Tone and Style are required' }]}
                    >
                        <CustomTextAreaWithButton
                            placeholder="Choose an appropriate tone and style"
                            uniqueKey="toneStyle"
                            loadingStates={loadingStates}
                            setLoadingStates={setLoadingStates}
                            value={form.getFieldValue('toneStyle')} // Bind value to form field
                            onChange={(e) => form.setFieldValue('toneStyle', e.target.value)} // Update form value on change
                        />
                    </Form.Item>
                    <Form.Item
                        label="Key Topics and Keywords"
                        name="keyTopics"
                        rules={[{ required: true, message: 'Key Topics are required' }]}
                    >
                        <CustomTextAreaWithButton
                            placeholder="Highlight the main topics and keywords"
                            uniqueKey="keyTopics"
                            loadingStates={loadingStates}
                            setLoadingStates={setLoadingStates}
                            value={form.getFieldValue('keyTopics')} // Bind value to form field
                            onChange={(e) => form.setFieldValue('keyTopics', e.target.value)} // Update form value on change
                        />
                    </Form.Item>
                </div>
            )}

            {currentPage === 2 && (
                <div className="grid grid-cols-1 md:grid-col-2 gap-4">
                    <Form.Item
                        label="Introduction and Hook"
                        name="introduction"
                        rules={[{ required: true, message: 'Introduction is required' }]}
                    >
                        <CustomTextAreaWithButton
                            placeholder="Write an engaging introduction"
                            uniqueKey="introduction"
                            loadingStates={loadingStates}
                            setLoadingStates={setLoadingStates}
                            value={form.getFieldValue('introduction')} // Bind value to form field
                            onChange={(e) => form.setFieldValue('introduction', e.target.value)} // Update form value on change
                        />
                    </Form.Item>
                    <Form.Item
                        label="Content Structure"
                        name="contentStructure"
                        rules={[{ required: true, message: 'Content Structure is required' }]}
                    >
                        <CustomTextAreaWithButton
                            placeholder="Organize the post into a logical flow"
                            uniqueKey="contentStructure"
                            loadingStates={loadingStates}
                            setLoadingStates={setLoadingStates}
                            value={form.getFieldValue('contentStructure')} // Bind value to form field
                            onChange={(e) => form.setFieldValue('contentStructure', e.target.value)} // Update form value on change
                        />
                    </Form.Item>
                    <Form.Item
                        label="Main Points and Supporting Details"
                        name="mainPoints"
                        rules={[{ required: true, message: 'Main Points are required' }]}
                    >
                        <CustomTextAreaWithButton
                            placeholder="Develop core arguments with supporting details"
                            uniqueKey="mainPoints"
                            loadingStates={loadingStates}
                            setLoadingStates={setLoadingStates}
                            value={form.getFieldValue('mainPoints')} // Bind value to form field
                            onChange={(e) => form.setFieldValue('mainPoints', e.target.value)} // Update form value on change
                        />
                    </Form.Item>
                    <Form.Item
                        label="Call to Action (CTA)"
                        name="callToAction"
                        rules={[{ required: true, message: 'Call to Action is required' }]}
                    >
                        <CustomTextAreaWithButton
                            placeholder="Encourage the reader to take action"
                            uniqueKey="callToAction"
                            loadingStates={loadingStates}
                            setLoadingStates={setLoadingStates}
                            value={form.getFieldValue('callToAction')} // Bind value to form field
                            onChange={(e) => form.setFieldValue('callToAction', e.target.value)} // Update form value on change
                        />
                    </Form.Item>
                    <Form.Item
                        label="Conclusion"
                        name="conclusion"
                        rules={[{ required: true, message: 'Conclusion is required' }]}
                    >
                        <CustomTextAreaWithButton
                            placeholder="Summarize key points and close the article"
                            uniqueKey="conclusion"
                            loadingStates={loadingStates}
                            setLoadingStates={setLoadingStates}
                            value={form.getFieldValue('conclusion')} // Bind value to form field
                            onChange={(e) => form.setFieldValue('conclusion', e.target.value)} // Update form value on change
                        />
                    </Form.Item>
                    <Form.Item
                        label="Multimedia Elements"
                        name="multimediaElements"
                        rules={[{ required: true, message: 'Multimedia Elements are required' }]}
                    >
                        <CustomTextAreaWithButton
                            placeholder="Include relevant images, videos, or graphics"
                            uniqueKey="multimediaElements"
                            loadingStates={loadingStates}
                            setLoadingStates={setLoadingStates}
                            value={form.getFieldValue('multimediaElements')} // Bind value to form field
                            onChange={(e) => form.setFieldValue('multimediaElements', e.target.value)} // Update form value on change
                        />
                    </Form.Item>
                </div>
            )}

            <div className="flex justify-between">
                {currentPage > 1 && (
                    <button  className="w-full md:w-[8rem] bg-transparent border border-customOrange text-customOrange rounded p-2" onClick={handlePrevious}>
                        Previous
                    </button>
                )}
                {currentPage < 2 ? (
                    <button  className="w-full md:w-[8rem] bg-customOrange text-white rounded p-2" onClick={handleNext}>
                        Next
                    </button>
                ) : (
                    <button type="submit" className="w-full md:w-[8rem] bg-customOrange text-white rounded p-2">
                  Generate
                </button>
                )}
            </div>
        </Form>
    );
};

export default BlogWriter;

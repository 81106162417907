import { Button, Col, Form, Input, Row, Select } from 'antd';
import { useState } from 'react';
import './style.scss';
import { toast } from 'react-toastify';

const { Option } = Select;

interface CampaignAdGeneratorFormInterface {
  onGenerate: (v: string) => void;
  setTopic: (e: string) => void;
}

const CampaignAdGeneratorForm = ({ onGenerate, setTopic }: CampaignAdGeneratorFormInterface) => {
  const [isPageOne, setIsPageOne] = useState(true); // Boolean to control page visibility
  const [form] = Form.useForm();

  const [pageOneValues, setPageOneValues] = useState<{[key: string]: string}>({
    'objective': '',
    'metrics': '',
    'demographics': '',
    'interests': '',
    'platforms': '',
  });

  const onFinish = (values: any) => {
    let promptMessage = '';

    const objective = pageOneValues['objective'];
    const metrics = pageOneValues['metrics'];
    const demographics = pageOneValues['demographics'];
    const interests = pageOneValues['interests'];
    const platforms = pageOneValues['platforms'];
    

    const {formats, creativeSpecs, messaging, cta, budget, distribution, kpis, trackingTools } = values;

    // Validate if all data is passed
    if (!objective 
        || !metrics 
        || !demographics
        || !interests
        || !platforms
        || !formats
        || !creativeSpecs
        || !messaging
        || !cta
        || !distribution
        || !kpis
        || !trackingTools
        || !budget
    ) {
      toast.error('Please enter all prompts field');
      return;
    }

    let informationData = `
      Objective: ${objective}
      Measurable Metrics: ${metrics}
      Demographics: ${demographics}
      Interests & Behaviors: ${interests}
      Ad Platform: ${platforms}
      Ad Format: ${formats}
      Creative Specifications: ${creativeSpecs}
      Messaging: ${messaging}
      Call to Action: ${cta}
      Total Budget: ${budget}
      Distribution: ${distribution}
      KPIs: ${kpis}
      Tracking Tools: ${trackingTools}
    `;

    promptMessage = `Generate a comprehensive Ads Campaign that aids marketers in planning, crafting, and optimizing their advertising campaigns with the following information: ${informationData}`;
    onGenerate(promptMessage);
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      initialValues={{
        objective: '',
        demographics: '',
        platforms: [],
        formats: [],
        messaging: '',
        cta: '',
        totalBudget: 0,
        kpis: '',
        trackingTools: '',
      }}
    >
      <h1 className="text-xl font-bold font-Poppins mb-[16px] mt-[16px]">Campaign Ad Generator</h1>
      <p className="text-md font-Poppins mb-[16px]">
        Create and customize ad campaigns to reach your target audience effectively.
      </p>

      {/* Page 1 Inputs */}
      {isPageOne && (
        <>
          <div  className='grid grid-cols-1  md:grid-cols-2 gap-4'>
            <div>
              <Form.Item label="Objective" name="objective"   rules={[{ required: true, message: 'Please define your objective' }]}>
                <Input placeholder="Define specific goals" />
              </Form.Item>
            </div>
            <div>
              <Form.Item label="Measurable Metrics" name="metrics"   rules={[{ required: true, message: 'Please include measurable metrics' }]}>
                <Input placeholder="Include measurable metrics and timelines" />
              </Form.Item>
            </div>
          </div>

          <div className='grid grid-cols-1  md:grid-cols-2 gap-4'>
            <div>
              <Form.Item label="Demographics" name="demographics"  rules={[{ required: true, message: 'Please define demographics' }]}>
                <Input placeholder="Age, gender, location, income level" />
              </Form.Item>
            </div>
            <div>
              <Form.Item label="Interests & Behaviors" name="interests" rules={[{ required: true, message: 'Please define interests and behaviors' }]}>
                <Input placeholder="Hobbies, purchasing habits, online behavior" />
              </Form.Item>
            </div>
          </div>

          <div className='grid grid-cols-1  md:grid-cols-2 gap-4'>
            <div>
              <Form.Item label="Ad Platforms" name="platforms" rules={[{ required: true, message: 'Please select platforms' }]}>
                <Select placeholder="Choose platforms">
                  <Option value="google">Google Ads</Option>
                  <Option value="facebook">Facebook</Option>
                  <Option value="instagram">Instagram</Option>
                </Select>
              </Form.Item>
            </div>
          </div>

          {/* Next Button */}
          <Row gutter={16}>
            <Col span={24}>
              <button
               
                onClick={() => form.validateFields().then(() => {
                  setPageOneValues({
                    'objective': form.getFieldValue('objective'),
                    'metrics': form.getFieldValue('metrics'),
                    'demographics': form.getFieldValue('demographics'),
                    'interests': form.getFieldValue('interests'),
                    'platforms': form.getFieldValue('platforms'),
                  });
                  setIsPageOne(false);
                })}
                className="w-full md:w-[8rem] bg-customOrange text-white rounded p-2"
              >
                Next
              </button>
            </Col>
          </Row>
        </>
      )}

      {/* Page 2 Inputs */}
      {!isPageOne && (
        <>
          <div className='grid grid-cols-1  md:grid-cols-2 gap-4'>
            <div>
              <Form.Item label="Ad Formats" name="formats" rules={[{ required: true, message: 'Please select ad formats' }]}>
                <Select placeholder="Select ad formats">
                  <Option value="video">Video</Option>
                  <Option value="display">Display</Option>
                  <Option value="carousel">Carousel</Option>
                </Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item label="Creative Specifications" name="creativeSpecs" rules={[{ required: true, message: 'Please specify creative specs' }]}>
                <Input placeholder="Outline size, duration, and content requirements" />
              </Form.Item>
            </div>
          </div>

          <div className='grid grid-cols-1  md:grid-cols-2 gap-4'>
            <div>
              <Form.Item label="Messaging" name="messaging" rules={[{ required: true, message: 'Please define messaging' }]}>
                <Input placeholder="Develop key messages" />
              </Form.Item>
            </div>
            <div>
              <Form.Item label="Call to Action (CTA)" name="cta" rules={[{ required: true, message: 'Please define CTA' }]}>
                <Input placeholder="Design compelling CTAs" />
              </Form.Item>
            </div>
          </div>

          <div className='grid grid-cols-1  md:grid-cols-2 gap-4'>
            <div>
              <Form.Item label="Total Budget" name="budget" rules={[{ required: true, message: 'Please set a budget' }]}>
                <Input placeholder="Set an overall budget for the campaign" />
              </Form.Item>
            </div>
            <div>
              <Form.Item label="Distribution" name="distribution" rules={[{ required: true, message: 'Please define distribution' }]}>
                <Input placeholder="Allocate funds across platforms and formats" />
              </Form.Item>
            </div>
          </div>

          <div  className='grid grid-cols-1  md:grid-cols-2 gap-4'>
            <div>
              <Form.Item label="KPIs" name="kpis" rules={[{ required: true, message: 'Please define KPIs' }]}>
                <Input placeholder="Define key performance indicators" />
              </Form.Item>
            </div>
            <div>
              <Form.Item label="Tracking Tools" name="trackingTools"    rules={[{ required: true, message: 'Please specify tracking tools' }]}>
                <Input placeholder="Suggest analytics tools" />
              </Form.Item>
            </div>
          </div>

          {/* Submit Button */}
          <div className='flex flex-row justify-between' >
          <div>
              <button
               
                onClick={() => setIsPageOne(true)}
                className="w-full md:w-[8rem] bg-transparent border border-customOrange text-customOrange rounded p-2"
              >
                Prev
              </button>
            </div>
            <div>
            <button 
                    type="submit"
                    className="no-hover-effect mt-2 w-full md:w-[8rem] py-2 px-4 bg-customOrange text-white rounded-lg hover:none"
                >
                    Generate
                </button>
            </div>
          </div>
        </>
      )}
    </Form>
  );
};

export default CampaignAdGeneratorForm;

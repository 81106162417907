import { FC } from 'react';
import clsx from 'clsx';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import SyntaxHighlighter from 'react-syntax-highlighter';
import agate from 'react-syntax-highlighter/dist/esm/styles/hljs/agate';
import 'github-markdown-css';
import './index.css';

interface ChatContent {
    text?: string;  // Optional text property
}

interface ChatMessageProps {
    role: string;
    className?: string;
    content: string | ChatContent[];
   
}

function isChatContentArray(content: any): content is ChatContent[] {
    return Array.isArray(content) && content.every(item => typeof item.text === 'string' || typeof item.text === 'undefined');
}

function ChatMessage({ role, content,className }: ChatMessageProps) {
    const messageContent =
        typeof content === 'string'
            ? content
            : isChatContentArray(content) && content.length > 0
                ? content[0].text || ''  // Handle undefined text
                : '';

    return (
        <>
            {messageContent && (
                <div className={`flex ${role === 'assistant' ? 'justify-start' : ' flex flex-row justify-end  '}  font_Poppins text-[#333237] text-[16px]   my-2`}>
                    <div
                        className={`  rounded-xl ${
                            role === 'assistant'
                                ? 'text-left w-full bg-transparent'
                                : 'p-3 text-left max-w-[90%] min-w-[20%] bg-white'
                        }`}
                    >
                         <div className={clsx(className, 'markdown-body', '!font-optimistic')}>
                         <ReactMarkdown
                            children={messageContent}
                            remarkPlugins={[remarkGfm]}
                            rehypePlugins={[rehypeRaw]}
                            components={{
                                //ts-ignore
                                code({ className, children, ...props }) {
                                    const match = /language-(\w+)/.exec(className || '');
                                    return match ? (
                                        <SyntaxHighlighter
                                            children={String(children).replace(/\n$/, '')}
                                            style={agate    as any}
                                            language={match[1]}
                                            showLineNumbers
                                            lineNumberStyle={{ color: '#999' }}
                                            PreTag="div"
                                            {...props}
                                        />
                                    ) : (
                                        <code className={className} {...props}>
                                            {children}
                                        </code>
                                    );
                                },                            }}
                        />
                    </div>
                </div>
                </div>
            )}
        </>
    );
}

export default ChatMessage;

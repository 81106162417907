/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import Profile from '@assets/Avatar.svg';
import { Form, Input } from 'antd';
import { toast } from 'react-toastify';;
import LoadingComponent from '@src/components/LoadingComponent';
import ShareImg from '@public/share.svg';
import Copy from '@public/copy.svg';
import DownloadImage from '@public/download.svg';
import { io } from 'socket.io-client';
import type { Socket } from 'socket.io-client';
import { prompts } from '@src/utils/prompts';
import { ChatType, getHistory, handleAddHistory, handleDeleteAllHistory, handleDeleteHistory } from '../../endpoints';
import DashboardFrame from '@src/components/DashboardFrame';
// import { Markdown } from '../../components/Markdown';
import MarkdownDesign from '@src/components/Markdown';
import ResponseTextbox from '../../components/responseTextBox';
import { clickDomElement, convertStringToDelta, copyData, getUrlPath, openEditor, shareResponse } from '@src/utils/app_functions';
import NewCurriculum from './components/new_curriculum_home';
import BackButton from '@src/components/BackButton';
import { useNavigate } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import saveAs from 'file-saver';
import axios from 'axios';
import moment from 'moment';
import { pdfExporter } from 'quill-to-pdf';
import { deltaToMarkdown } from 'quill-delta-to-markdown';
import { Download } from '../../components/Share/Download';
import { Share } from '../../components/Share';
import right from '@assets/right_gray.svg';
import ContentTypingComponent from '../../components/content_typing';
import { BASE_URL, socketUrl } from '@src/utils/constants';
import { Chats } from '../../components/interface/interface';
import ReusableDialog from '../../components/comment_modal';
import { HistoryTypeFormat } from '@src/core/interfaces/chat';



const Curriculum = () => {
    const pageHistory = 'curriculum';
    const UrlRef = useRef<string>('');
    const navigate = useNavigate();
    const [rememberMe, setRememberMe] = useState(false);
    const DocumentId = useRef('');
    const Document = useRef<any>();
    const [generate, setGenerate] = useState(false);
    const [generating, setGenerating] = useState(false);
    const [histories, setHistories] = useState<any>([]);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [message, setMessage] = useState('');
    const [topic, setTopic] = useState('');
    const [newMessage, setNewMessage] = useState('');
    const [promptSent, setPromptSent] = useState('');
    const [chats, setChats] = useState<Chats>({ role: '', content: '', type: "gpt-4" });
    const [isTyping, setIsTyping] = useState(false);
    // socket.io
    const [response, setResponse] = useState('');
    const [socket, setSocket] = useState<Socket | null>(null);
    const [chatList, setChatList] = useState<ChatType[]>([]);
    const currentEntity = useRef<ChatType>();
    const StreamId = useRef<any>();
    const [showEditorButton, setShowEditorButton] = useState(false);
    const [isMobile, setIsMobile] = useState(true);
    const [showDownload, setShowDownload] = useState<boolean>(false);
    const [showShareModal, setShowShareModal] = useState<boolean>(false);
    const [page, setPage] = useState<string>('');
    const [pageClick, setPageClick] = useState<()=> void>(()=> {});
    const historyCloseId = "historyClose";
    const [canNavigate, setCanNavigate] = useState(false);
    const [docId, setDocId] = useState<string | undefined>(undefined);
    const [showingHistory, setShowingHistory] = useState(false);
    const [streamEndMessage, setStreamEndMessage] = useState<ChatType>({role: 'assistant', content: ''});


    const baseurl = BASE_URL;

    const bottomRef = useRef<HTMLDivElement>(null);


    useEffect(() => {
        if ( isTyping && response != '' ){
            console.log("scrolling")
            bottomRef.current?.scrollTo({
                top: bottomRef.current?.scrollHeight,
                behavior: 'smooth',
            });
        }
        
    }, [isTyping, response]);
    

    const handlePDF = async () => {
        let data = convertStringToDelta(response);
        const blob = await pdfExporter.generatePdf(data)
        const current_time = moment().format('YYYY-MM-DD HH:mm:ss');
        saveAs(blob as Blob, `gmind_document-${current_time}.pdf`);
    }
    const handleWord = async () => {
        let dataToDownload = response;
        const responseData = await axios.post(
            `${baseurl}/document/markdown-to-doc`,

            { content: dataToDownload },
            {},
        );
        const { data: d } = responseData;
        if (d?.statusCode === 1) {
            const link = document.createElement('a');
            link.href = `${baseurl}/${d?.url}`;
            const current_time = moment().format('YYYY-MM-DD HH:mm:ss');

            link.download = `gmind_document-${current_time}.docx`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            toast.success('Download successful');
        }
    }

    const handleShare = async () => {
        // const data = Document.current?.data?.ops;
        // const to_markdown = deltaToMarkdown(data);
        

        if (UrlRef.current) {
            setShowShareModal(true);
            return;
        }
        const responseData = await axios.post(
            `${baseurl}/document/markdown-to-doc`,
            { content: response },
            {},
        );

        const { data: d } = responseData;
        if (d?.statusCode === 1) {
            const url = `${baseurl}/${d?.url}`;
            UrlRef.current = url;
            setShowShareModal(true);
        }
    }

    const getPageHistory = () => {
        getHistory(pageHistory).then((response) => {
            if (response?.statusCode === 1) {
                const data = response?.data;
                // const filtered = data?.map((c: any) => {
                //     return [...c.conversation, { id: c._id, date: c.createdAt }];
                // });
                const filtered: HistoryTypeFormat[]  = data?.map((d: any) => {
                    return {
                            'date': d.date,
                            'histories': d.histories?.map((history: any) => {
                                return [
                                    ...history.conversation,
                                    { chat_id: history?.chat_id },
                                    { id: history._id, },
                                    { date: history.updatedAt },
                                ];
                            })
                        }
                    
                });
                setHistories(filtered);
            }else {
                setHistories([]); 
            }
        });
    };

    useEffect(() => {
        if (window.innerWidth >= 820) {
            setIsMobile(false);
        }
    }, [window.innerWidth]);

    const setClickedHistory = (id: string) => {
        let filterHistory: any = histories
            .flatMap((historyFormat: any) => historyFormat.histories)
            .filter((history: any) => {
                const chatIdObj = history.find((h: any) => h.id === id);
                return chatIdObj !== undefined;
            })
            .flat();

        // historyId.current = filterHistory.find((h: any) => h.chat_id)?.chat_id;
        filterHistory = filterHistory.filter((h: any) => h?.role && h?.content);
        let userPrompt = filterHistory.find((element: any) => ( element.role == 'user' ) );
        let assistantResponse = filterHistory.find((element: any) => ( element.role == "assistant" ) );
        setPromptSent(userPrompt.content);
        setResponse(assistantResponse.content);
            setShowingHistory(true);
        // setChatList(filterHistory);
    };

    useEffect(() => {
        getPageHistory();
    }, []);

    useEffect(() => {
        const newSocket = io(socketUrl.slice(0, -2));
        setSocket(newSocket);
        return () => {
            newSocket.close();
        };
    }, []);

    const handleStreamEnd = async (prompt: string, data: ChatType ) => {
    
        // let dataToSave: ChatType[]  = [
        //     {
        //         role: 'user',
        //         content: prompt,
        //     },
        //     data
        // ]
        let dataToSave = data;

        await handleAddHistory(dataToSave, pageHistory);
        getPageHistory();
    }

    useEffect(() => {
        if (!socket) return;

        socket.on('data', (data: string) => {
            setResponse((response) => response + data);
        });

        socket.on('stream_end', async (data: { streamId: string; assistant: ChatType }) => {
            const { streamId, assistant } = data;
            setStreamEndMessage(assistant);
            setShowEditorButton(true);
            setIsTyping(false);
            setCanNavigate(true);
            
            if (streamId === StreamId.current) {
                StreamId.current = '';
                // setStreaming(false);
                const uuid = uuidv4();
                setDocId(uuid);
                const user = JSON.parse(localStorage.getItem('user') || '');
                try {
                    socket?.emit('store-document', {
                        id: uuid,
                        title: promptSent,
                        value: assistant.content,
                        owner_id: user?.id,
                    });
                } catch (error) {
                    socket?.emit('store-document', {
                        id: uuid,
                        title: promptSent,
                        value: assistant.content,
                    });
                }
                socket.emit('get-documents', user?.id);
            }
        });

        return () => {
            socket.off('message');
        };
    }, [socket]);

    useEffect(() => {
        if ( !isTyping && response != '' ){
            handleStreamEnd(promptSent, streamEndMessage);
        }

        if ( canNavigate && response != '' ){
            openEditor({response, pageId: docId, promptSent, promptType: prompts.curriculum, navigate, pageSocket: socket});
        }
    }, [response, canNavigate, isTyping, socket])


    const onGenerateHandler = async (messageSent: string, type: string) => {
        toast.info('Please sit tight, your beautiful content is on its way.');
        setShowEditorButton(false);
        setIsTyping(true);
        let msgs = chats;
        msgs = { role: 'user', content: messageSent };
        console.log(msgs);
        // return;
        setChats(msgs);

        setMessage('');
        setNewMessage('');
        setResponse('');

        try {
            socket?.emit('data', {
                data: {
                    messages: [
                        {
                            role: 'system',
                            //   content: `You are G-Mind. You can help with all educational or academic questions or tasks`,
                            content: type == 'activity' ? prompts.activity : prompts.curriculum,
                            type: 'gpt-4'
                        },
                        msgs,
                    ],
                },
                
            });
        } catch (error) {
            setIsTyping(false);
        } finally {
            // setIsTyping(false);
        }
    };

    const handleOpenDialog = () => {
        setDialogVisible(true);
    };

    const handleCloseDialog = () => {
        setDialogVisible(false);
    };

    const handleSaveComment = (comment: any) => {
        console.log('Saved comment:', comment);
        handleCloseDialog();
    };

    const onFinish = async (promptMessage: string, type: string) => {
        console.log(promptMessage);
        setMessage(promptMessage);
        console.log(message);
        setPromptSent(promptMessage);
        await onGenerateHandler(promptMessage, type);
        setGenerate(true);
        setGenerating(true);
        const timeoutId = setTimeout(() => {
            setGenerating(false);
        }, 3000);
    };

    const regenerate = () => {
        // onGenerateHandler(promptSent);
        // setGenerate(true);
        // setGenerating(true);
        // const timeoutId = setTimeout(() => {
        //     setGenerating(false);
        // }, 3000);
    };

    const openTextEditor = () => {
        const documentID = uuidv4();
        console.log(DocumentId.current);
        localStorage.setItem(
            'essay_content',
            JSON.stringify({
                title: topic,
                value: response,
                documentId: DocumentId.current ?? documentID,
            }),
        );
        navigate(`/dashboard/article/editor?document=${DocumentId.current ?? documentID}`);
    };

    const chatWithAI = async () => {
        const promptMessage = newMessage;
        setMessage(promptMessage);
        setPromptSent(promptMessage);

        // await onGenerateHandler(promptMessage);
        // setGenerate(true);
        // setGenerating(true);
        // const timeoutId = setTimeout(() => {
        //     setGenerating(false);
        // }, 3000);
    };
    const handleShareClick = () => {
        handleShare();
    };

    const handleCopyClick = () => {
        copyData(response);
    };

    const handleMessageClick = () => {
        handleOpenDialog();
    };
    const handleLikeClick = () => {};
    const handleDisLikeClick = () => {};
    const handleDownloadClick = () => {
        setShowDownload(true);
    };

    const promptOptionsData = [
        {
            name: 'Share',
            icon: ShareImg,
            onClick: handleShareClick,
        },
        {
            name: 'Copy',
            icon: Copy,
            onClick: handleCopyClick,
        },
        {
            name: 'Download',
            icon: DownloadImage,
            onClick: handleDownloadClick,
        },
    ];

    const handleDeleteContentHistory = async (id: string) => {
        await handleDeleteHistory(id, pageHistory);
        getPageHistory();
        toast.success("Chat deleted successfully");
    }

    const handleDeleteAllAssesmentHistory = async () => {
        await handleDeleteAllHistory(pageHistory);
        getPageHistory();
        toast.success("Chat cleared successfully");
    }

    return (
        <DashboardFrame
            canNavigateHome={true}
            showSidebar={!isMobile}
            showHistory={!isMobile}
            showTop={!isMobile}
            history={histories}
            showRightComponent={false}
            rightComponentPlaceholder='Curriculum'
            selectedHistory={(v: string) => setClickedHistory(v)}
            closeIdValue={historyCloseId}
            showHistoryOption={ page != ''}
            showPagePath={page != ''}
            onDeleteHistory={(id: string) => {
                handleDeleteContentHistory(id);
            }}
            onDeleteAllHistory={() => handleDeleteAllAssesmentHistory()}
            // topExtraData={
            //     page != ''  && <div className='flex items-center'>
            //         <img src={right} className="w-3" />
            //         <p onClick={() => { pageClick; clickDomElement(historyCloseId) }} className='font-light text-xs text-nowrap cursor-pointer'>{page}</p>
            //     </div>
            // }
            
        >
            <div ref={bottomRef} className="h-[90vh] overflow-auto">
                {!generate && !showingHistory ? (
                    <NewCurriculum onGenerate={(prompt, type) => onFinish(prompt, type)} setTopic={(e) => setTopic(e) } setPageName={(e) => setPage(e) } setPageClickFn={(fn) => setPageClick(fn)} />
                ) : (
                    <div className="w-full flex flex-col gap-0 bg-custom-opacity">
                        <div className="w-full flex flex-row gap-0 bg-custom-opacity">
                            <div className="w-full flex flex-col gap-0 bg-custom-opacity px-10 py-10">
                                <div style={{ marginTop: '23px' }}>
                                    <div className="flex flex-row">
                                       {
                                            <BackButton
                                                onclick={() => {
                                                    if ( showingHistory ){
                                                        setShowingHistory(false)
                                                        if ( generate ){
                                                            setGenerate(false)
                                                        }
                                                        return; 
                                                    }
                                                    setGenerate(false);
                                                }}
                                            />
                                        }
                                        
                                        { <ContentTypingComponent isTyping={isTyping} />} 
                                    </div>

                                    {
                                        showingHistory &&
                                        <div className="w-full flex flex-row gap-0 bg-custom-opacity px-10 py-10 overflow-y-auto">
                                            <div
                                                style={{
                                                    height: '70px',
                                                    backgroundColor: '#F9FAFC',
                                                    fontFamily: 'Inter',
                                                    fontSize: '0.8125rem',
                                                    fontWeight: '400',
                                                }}
                                                className="w-full flex flex-row gap-10 bg-custom-opacity"
                                            >
                                                <img src={Profile} alt="profile" />
                                                <span>{promptSent}</span>
                                            </div>
                                        </div>
                                    }
                                    
                                    {generating ? (
                                        <LoadingComponent isMobile={false} width={''} />
                                    ) : (
                                        <>
                                            <ResponseTextbox
                                                content={<MarkdownDesign className="">{response}</MarkdownDesign>}
                                                options={ showEditorButton ? promptOptionsData: []}
                                                disLikeClick={handleDisLikeClick}
                                                messageClick={handleMessageClick}
                                                likeClick={handleLikeClick}
                                                regenerateClick={regenerate}
                                                profileClick={() => {}}
                                            />
                                        </>
                                    )}
                                </div>
                                <div className="sticky bottom-0 bg-custom-opacity">
                                    <Input
                                        type="text"
                                        onChange={(e) => setNewMessage(e.target.value)}
                                        value={newMessage}
                                        placeholder="Describe what you want"
                                        style={{
                                            height: '50px',
                                            borderRadius: '16px',
                                            border: '1px solid lightgrey',
                                            marginTop: '17px',
                                        }}
                                        suffix={
                                            <svg
                                                onClick={() => chatWithAI()}
                                                width="36"
                                                height="36"
                                                viewBox="0 0 36 36"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M15 18L31.5 18"
                                                    stroke="#E55109"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M31.5004 18.0004L13.1254 26.6254C12.9116 26.7049 12.6711 26.6524 12.5098 26.4911C12.3485 26.3298 12.296 26.0893 12.3754 25.8754L15.0004 18.0004L12.3754 10.1254C12.296 9.91158 12.3485 9.67107 12.5098 9.50977C12.6711 9.34846 12.9116 9.29598 13.1254 9.37542L31.5004 18.0004"
                                                    stroke="#E55109"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                        }
                                    />
                                    <div className="advise">
                                        Gmind can make mistakes. It's advisable to verify crucial information.
                                    </div>
                                </div>

                                <ReusableDialog
                                    visible={dialogVisible}
                                    title="Add Comment"
                                    inputPlaceholder="Enter your comment"
                                    saveLabel="Save"
                                    onCancel={handleCloseDialog}
                                    onSave={handleSaveComment}
                                />
                                {showShareModal && <Share url={UrlRef.current} onClose={() => setShowShareModal(false)} />}
                                {showDownload && (
                                    <Download
                                        handlePDF={handlePDF}
                                        handleWord={handleWord}
                                        url={UrlRef.current}
                                        onClose={() => setShowDownload(false)}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </DashboardFrame>
    );
};

export default Curriculum;


/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import logo from '@assets/essay/Gmind Logo White.png';
import avatar from '@assets/default_user.png';
import collapse from '@assets/collapse.svg';
import Option from './option';
import ask from '@icons/brand-hipchat.svg';
import notes from '@assets/notes.svg';
import layout_grid from '@assets/layout-grid.svg';
import bookIcon from '@icons/book-svgrepo.svg';
import slideshow from '@assets/slideshow.svg';
import writing from '@assets/writing-sign.svg';
import image from '@assets/picture-in-picture.svg';
import transcriber from '@assets/transcriber.svg';
import activity from '@assets/activity.svg';
import speakerPhone from '@icons/speakerphone.svg';
import videoPlus from '@icons/video-plus.svg';
import { IoMdLogOut } from 'react-icons/io';
import classR from '@assets/essay/noun-educational-resources-41336.png';
import lectureR from '@assets/essay/resource.png';
import researchR from '@assets/essay/study.png';

import { RxCross2 } from 'react-icons/rx';
import { ReactNode, useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router';
import {
    AssessementImage,
    ContentImage,
    CurriculumImage,
    ImageGeneratorImage,
    SchoolLettersImage,
    SlideImage,
    SpeechImage,
    TranscriberImage,
    VideoMakerImage,
} from './images';
import { getUrlPath } from '@src/utils/app_functions';
import { UserDetailsData } from '@src/core/interfaces/user';
import { useUserContext } from '@src/context/UseProvider';
import { SettingOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

type OptionType = {
    icon: typeof ask;
    text: string;
    label: string;
    comingSoon?: boolean;
    element?: JSX.Element;
    children?: OptionType[];
};

// Move optionsValue outside the component to avoid re-creation on every render
const optionsValue: OptionType[] = [
    { icon: ask, text: 'Ask G-mind Chatbot', label: 'chat' },
    { icon: layout_grid, text: 'Curriculum Planner', label: 'curriculum', element: CurriculumImage() },
    { icon: writing, text: 'Content Generator', label: 'generate_content', element: ContentImage() },
    {
        icon: bookIcon,
        text: 'Resources',
        label: 'resources',
        children: [
            { icon: classR, text: 'Class Resources', label: 'class-resources' },
            { icon: lectureR, text: 'Business Resources', label: 'business-resources' },
            // { icon: researchR, text: 'Media Resources', label: 'media-resources' },
        ],
    },
    {
        icon: speakerPhone,
        text: 'Speech Synthesizer',
        label: 'speech_synthesizer',
        comingSoon: false,
        element: SpeechImage(),
    },
    { icon: image, text: 'Image Generator', label: 'image_maker', element: ImageGeneratorImage() },
    { icon: videoPlus, text: 'Video Mode ', label: 'video_mode', comingSoon: true, element: VideoMakerImage() },
];

const affiliateValue: OptionType[] = [
    { icon: ask, text: 'Dashboard', label: 'dashboard' },
    { icon: layout_grid, text: 'Referrals', label: 'referrals', element: CurriculumImage() },
    { icon: writing, text: 'Transactions', label: 'transactions', element: ContentImage() },

    { icon: slideshow, text: 'Log Out', label: 'chat', element: SlideImage() },
];

const MobileSidebar = React.memo(
    ({
        selected,
        className,
        style,
        onMobileClose,
        collapsed,
        isMobile,
        userIsAffiliate,
        hasPendingAffiliateRequest,
        onBecomeAffiliate,
    }: {
        selected: (v: string) => void;
        className?: string;
        style?: React.CSSProperties;
        collapsed?: (v: boolean) => void;
        onMobileClose?: () => void;
        isMobile?: boolean;
        userIsAffiliate?: boolean;
        hasPendingAffiliateRequest?: boolean;
        onBecomeAffiliate?: () => void;
    }) => {
        const user = JSON.parse(localStorage.getItem('user') ?? '{}');
        const [isCollapsed, setCollapsed] = useState(false);
        const navigate = useNavigate();
        const [userOptions, setUserOptions] = useState(false);
        const [selectedOption, setSelectedOption] = useState<string>('');
        const { userDetails } = useUserContext();
        const [openKeys, setOpenKeys] = useState<string[]>([]);
        const [affiliate, setAffiliate] = useState('Become an Affiliate');
        const [isAffiliate, setIsAffiliate] = useState(false);

        const [showBecomeAnAffiliate, setShowBecomeAnAffiliate] = useState<boolean>(false);
        const [showPendingAffiliate, setShowPendingAffiliate] = useState<boolean>(false);
        const path = window.location.pathname;

        useEffect(() => {
            setShowBecomeAnAffiliate(userIsAffiliate ?? false);
            setShowPendingAffiliate(hasPendingAffiliateRequest ?? false);
        }, [userIsAffiliate, hasPendingAffiliateRequest]);

        const handleAffiliate = () => {
            setIsAffiliate(true);
        };

        const openAffiliateModal = () => {
            if (onBecomeAffiliate) {
                onBecomeAffiliate();
            }
        };

        useEffect(() => {
            if (path.includes('affiliate')) {
                setIsAffiliate(true);
            }
        }, [path]);

        const handleSelect = useCallback(
            (label: string) => {
                setSelectedOption(label);
                selected(label);

                const parentKey = optionsValue.find((option) =>
                    option.children?.some((child) => child.label === label),
                )?.label;
                if (parentKey && !openKeys.includes(parentKey)) {
                    setOpenKeys([...openKeys, parentKey]);
                }
                if (label === 'chat') {
                    localStorage.removeItem('activeChat');
                }
            },
            [selected, openKeys],
        );

        useEffect(() => {
            const savedOpenKeys = localStorage.getItem('openKeys');
            if (savedOpenKeys) {
                setOpenKeys(JSON.parse(savedOpenKeys));
            }
        }, []);

        const handleOpenChange = (keys: string[]) => {
            setOpenKeys(keys); // Update the open keys state
            localStorage.setItem('openKeys', JSON.stringify(keys)); // Save open keys to localStorage
        };

        useEffect(() => {
            const { pathname } = window.location;
            const selected = pathname.split('/').slice(-1);
            setSelectedOption(selected[0]);
        }, []);

        // useEffect(() => {
        //     if (window.innerWidth <= 820 && !isMobile) {
        //         setCollapsed(true);
        //     }
        // }, [window.innerWidth]);

        return (
            <nav
                style={style}
                className={
                    'relative transition-all bg-[#eeeee4] border-e border-white h-full sidebar flex flex-col  px-0 pb-0 shadow-md ' +
                    className
                }
            >
                <div
                    style={
                        isCollapsed ? { justifyContent: 'center', borderBottom:"2px solid white" } : { justifyContent: 'space-between', backgroundColor:"" }
                    }
                    className="flex px-6 py-5 items-center bg-[#00027d] relative  "
                >
                    {!isCollapsed && (
                        <img
                            onClick={() => {
                                navigate('/');
                            }}
                            role="button"
                            src={logo}
                            className="w-20"
                        />
                    )}
                    {isMobile && (
                        <RxCross2
                            role="button"
                            onClick={onMobileClose}
                            className="absolute text-2xl top-3 right-5 text-white"
                        />
                    )}
                    <div
                        role="button"
                        onClick={() => {
                            setCollapsed(!isCollapsed);
                            if (collapsed) {
                                collapsed(!isCollapsed);
                            }
                        }}
                    >
                        {!isMobile && (
                            <img
                                style={isCollapsed ? { width: '1rem' } : {}}
                                role="button"
                                src={collapse}
                                className="max-md:hidden w-[1.5rem]"
                            />
                        )}
                    </div>
                </div>

                {!isAffiliate && (
                    <div className=" relative mt-6 px-4 h-full">
                        <Menu
                            mode="inline"
                            selectedKeys={[selectedOption]}
                            openKeys={openKeys} // Control open submenus
                            onOpenChange={handleOpenChange}
                            className="mt-6 bg-defaultWhiteLighter"
                            style={{
                                
                                fontSize: '12.8px',
                                height: '85%',
                                borderRight: 0,
                                backgroundColor: 'transparent',
                            }}
                        >
                            {optionsValue.map(({ icon, text, label, children }) =>
                                children && children.length > 0 ? (
                                    <Menu.SubMenu
                                        key={label}
                                        icon={<img className="w-[0.7rem]" src={icon} alt="icon" />}
                                        title={<span className="text-black text-[12.8px]">{ text}</span>}
                                    >
                                        {children.map(({ icon: childIcon, text: childText, label: childLabel }) => (
                                            <Menu.Item
                                                key={childLabel}
                                                icon={<img className="w-3" src={childIcon} alt="icon" />}
                                                onClick={() => handleSelect(childLabel)}
                                                style={{
                                                    backgroundColor:
                                                        selectedOption === childLabel
                                                            ? '#dad9df'
                                                            : 'transparent',
                                                    color: selectedOption === childLabel ? '#000' : '#000',
                                                }}
                                            >
                                                {childText}
                                            </Menu.Item>
                                        ))}
                                    </Menu.SubMenu>
                                ) : (
                                    <Menu.Item
                                        key={label}
                                        onClick={() => handleSelect(label)}
                                        icon={<img className="w-4" src={icon} alt="icon" />}
                                        style={{
                                            paddingRight:"0px",
                                            backgroundColor:
                                                selectedOption === label ? '#dad9df' : 'transparent',
                                            color: label === 'chat' ? ' #001F73' : '#000',
                                        }}
                                        className="flex items-center gap-2"
                                    >
                                       
                                            <span className="flex   items-center gap-3">
                                                {text}
                                                <span className=''>
                                                    {' '}
                                                    {label === 'video_mode' && (
                                                        <div
                                                            style={{ whiteSpace: 'nowrap' }}
                                                            className="   overflow-hidden h-7 font-normal text-[0.5rem] text-[#00027d] text-center flex flex-col p-2 justify-center rounded-[3rem] bg-[#B3B3F2]"
                                                        >
                                                            Coming soon
                                                        </div>
                                                    )}
                                                </span>
                                            </span>
                                        
                                    </Menu.Item>
                                ),
                            )}
                        </Menu>
                    </div>
                )}

                {isAffiliate && path.includes('affiliate') && (
                    <div className="mt-6 h-full">
                        <Menu
                            mode="inline"
                            selectedKeys={[selectedOption]}
                            openKeys={openKeys} // Control open submenus
                            onOpenChange={handleOpenChange}
                            className="mt-6"
                            style={{
                                fontSize: '12.8px',
                                height: '85%',
                                overflowY: 'scroll',
                                borderRight: 0,
                                scrollbarWidth: 'none',
                                backgroundColor:"transparent"
                            }}
                        >
                            {affiliateValue.map(({ icon, text, label }) => (
                                <Menu.Item
                                    key={label}
                                    icon={<img className="w-3" src={icon} alt="icon" />}
                                    onClick={() => handleSelect(label)}
                                    style={{
                                        backgroundColor:
                                            selectedOption === label ? '#dad9df' : 'transparent',
                                        color: selectedOption === label ? '#000' : '#000',
                                    }}
                                >
                                    {!isCollapsed && text}
                                </Menu.Item>
                            ))}
                        </Menu>
                    </div>
                )}

                <div className="flex flex-col justify-end h-full">
                    {!isAffiliate && (
                        <>
                            {showPendingAffiliate ? (
                                <button
                                    onClick={() => {}}
                                    className=" text-white hover:text-whitefont-medium text-xs flex items-center justify-center h-10 rounded-md mx-4 mb-4 transition-all cursor-pointer"
                                    style={isCollapsed ? { userSelect: 'none' } : {backgroundColor:"#00027d"}}
                                >
                                    {!isCollapsed && <span>Pending Affiliate Request</span>}
                                </button>
                            ) : showBecomeAnAffiliate ? (
                                <Link
                                    onClick={handleAffiliate}
                                    to="/affiliate/dashboard"
                                    className=" text-white hover:text-whitefont-medium text-xs flex items-center justify-center h-10 rounded-md mx-4 mb-4 transition-all cursor-pointer"
                                    style={isCollapsed ? { userSelect: 'none' } : {backgroundColor:"#00027d"}}
                                >
                                    {!isCollapsed && <span>View Affiliate Dashboard</span>}
                                </Link>
                            ) : (
                                <button
                                    onClick={openAffiliateModal}
                                    className=" text-white hover:text-white font-medium text-xs flex items-center justify-center h-10 rounded-md mx-4 mb-4 transition-all cursor-pointer"
                                    style={isCollapsed ? { userSelect: 'none' } : {backgroundColor:"#00027d"}}
                                >
                                    {!isCollapsed && <span>Become an Affiliate</span>}
                                </button>
                            )}
                        </>
                    )}
                </div>

                <section
                    role="button"
                    style={isCollapsed ? { userSelect: 'none', borderTop:"2px solid white" } : {borderTop:"2px solid white"}}
                    onClick={() => navigate('/dashboard/profile')}
                    className=" bg-transparent relative  mt-auto flex flex-col select-none transition-all h-[4rem] p-4 cursor-pointer gap-4 "
                >
                    <div className="flex items-start gap-2 transition-all">
                        <img
                            src={userDetails?.profilePic ? userDetails?.profilePic : avatar}
                            className="w-7 h-7 max-lg:w-5 bg-cover bg-center rounded-full"
                            style={
                                isCollapsed
                                    ? {
                                          position: 'absolute',
                                          top: '50%',
                                          left: '50%',
                                          transform: 'translate(-50%, -50%)',
                                      }
                                    : {}
                            }
                        />
                        {!isCollapsed && (
                            <div className="flex items-center justify-between w-full">
                                <div className="flex flex-col">
                                    <p className="font-medium text-xs max-lg:text-[0.6rem]">
                                        {user?.firstName ?? 'User'} {user?.lastName ?? ''}{' '}
                                    </p>
                                    <p className="text-[0.6rem] max-lg:hidden">{user?.email ?? ''}</p>
                                </div>

                                <SettingOutlined className="space-x-2" style={{ color: 'var(--gmind-orange)' }} />
                            </div>
                        )}
                    </div>
                </section>
            </nav>
        );
    },
);

export default MobileSidebar;

import Arrow from '@src/assets/arrow';
import { useEffect, useState } from 'react';

interface SelectType {
    options: string[];
    onSelect: (selected: string) => void;
    className?: string;
    placeholder?: string;
    value?: string;
}

export const Select = ({ options, onSelect, className, placeholder, value }: SelectType) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [title, setTitle] = useState<string>(
        options[0] != '' ? (options[0] ?? 'Select an Option') : (placeholder ?? 'Select an Option'),
    );

    useEffect(() => {
        console.log(value);
        if (value) {
            setTitle(value);
        }
    }, []);

    return (
        <section
            role="button"
            onClick={() => setIsOpen(!isOpen)}
            className="border border-gray-600 h-8 px-2 flex items-center rounded-md relative justify-between"
        >
            <p
                className={
                    `text-xs select-none font-normal ${title == 'Select an Option' ? 'text-gray-200' : 'text-black-300'}` +
                    className
                }
            >
                {title}
            </p>
            <ul
                className={`${isOpen ? 'max-h-[13rem] overflow-auto' : ''} flex absolute left-0 right-0 top-8 bg-white shadow-md z-[200] flex-col`}
            >
                {isOpen &&
                    options.map((option) => (
                        <li
                            role="button"
                            className="p-2 select-none text-xs hover:bg-gray-300"
                            onClick={() => {
                                setTitle(option);
                                onSelect(option);
                            }}
                            key={option}
                        >
                            {option}
                        </li>
                    ))}
            </ul>
            <Arrow
                color="#ed5405"
                style={isOpen ? { transform: 'rotate(90deg) scale(0.75)' } : {}}
                className="-rotate-90 scale-75 transition-all"
            />
        </section>
    );
};

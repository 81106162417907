import Img1 from '@assets/women_tab.svg';
import Img2 from '@assets/guy_img.svg';
import Img3 from '@assets/img.svg';
import ellipse from '@assets/ellipse.svg';
import './styles/index.scss';
import { useEffect, useState } from 'react';
import { getUrlPath } from '@src/utils/app_functions';
import BackButton from '@src/components/BackButton';

interface pageInfoType {
    name: string;
    description: string;
}

const imageList = [
    { id: 0, src: Img3, alt: 'img-1', buttontext: 'For Educators' },
    { id: 1, src: Img2, alt: 'img-2', buttontext: 'For Students' },
    { id: 3, src: Img1, alt: 'img-3', buttontext: 'For Schools' },
];

const pageInfo: pageInfoType[] = [
    {
        name: 'Anticpate Video Mode',
        description:
            'This feature enhances video playback by predicting scenes, ensuring smoother transitions, reduced buffering, and improved quality, benefiting streaming, gaming, and virtual reality applications.',
    },
    {
        name: 'Anticpate Speech Synthesizer',
        description:
            'This feature help converts text into natural-sounding speech, enhancing communication with lifelike intonation and emotion, benefiting education, healthcare, and customer service applications.',
    },
];

const NewComingSoon = () => {
    const [pageToShow, setPageToShow] = useState<pageInfoType>(pageInfo[0]);

    useEffect(() => {
        let pageDetails = getUrlPath();
        let pagename = pageDetails.pagename;

        if (pagename.toLowerCase() == 'video_mode') {
            setPageToShow(pageInfo[0]);
        } else if (pagename.toLowerCase() == 'speech_synthesizer') {
            setPageToShow(pageInfo[1]);
        }
    }, [window.location.pathname]);

    return (
        <div className="w-full h-full bg-custom-opacity flex flex-col md:flex-row gap-y-3 px-10 py-3 md:py-5 gap-x-3">
            <div className="w-full md:w-[45%] flex flex-col items-start justify-center gap-y-3 mb-10">
                <BackButton />
                <p className="font-semibold text-[2.5rem] text-[var(--gmind-orange2)] mb-5">Coming Soon</p>
                <p className="font-semibold text-[2rem] text-[var(--gmind-border-black2)]">{pageToShow.name}</p>
                <p
                    style={{
                        lineHeight: '1.5rem',
                    }}
                    className="font-normal text-[0.813rem] text-[var(--gmind-light-black)]"
                >
                    {pageToShow.description}
                </p>

                <input className="shadow-md bg-white anticipate-input mb-3" placeholder="Enter your email" />

                <p className="anticipate-button">Get Notified</p>
            </div>
            <div className="w-full md:w-[60%] flex gap-x-2">
                {imageList.map((item, index) => (
                    <>
                        {index == 0 && (
                            <div className="relative rounded-[1rem] w-[69%] h-[85%]">
                                <div
                                    className="rounded-[1rem] w-full h-full px-3 py-[1.5rem] flex flex-col gap-y-3 justify-end"
                                    style={{
                                        backgroundImage: `url(${Img3})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat',
                                    }}
                                >
                                    {/* <div className="flex gap-x-3">
                                            <img src={ellipse} alt="ellipse" />
                                            <p className="font-semibold text-[1.8rem] text-white">For Educators</p>
                                        </div>

                                        <p className="ml-5 font-normal text-[0.9rem] text-white">This feature enhances video playback by predicting scenes, ensuring smoother transitions, reduced buffering, and improved quality, benefiting streaming, gaming, and virtual reality applications.</p> */}
                                </div>
                                <div className="rounded-[1rem] absolute bottom-0 h-[35%] w-full bg-gradient-to-t from-[#001F73] to-[#001F7313]"></div>
                            </div>
                        )}

                        {index > 0 && (
                            <div className="w-[30%] h-[85%] rounded-[1rem] relative">
                                <div
                                    className="rounded-[1rem] py-[4rem] flex flex-col justify-end h-full w-full"
                                    style={{
                                        backgroundImage: `url(${item.src})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat',
                                    }}
                                >
                                    {/* <div className="w-[90%] h-[7rem] bg-[var(--gmind-orange2)] px-5 py-5">
                                            <p className="font-medium text-[1.3rem] text-white text-left" >{item.buttontext}</p>
                                        </div> */}
                                </div>

                                <div className="rounded-[1rem] absolute bottom-0 h-full w-full bg-gradient-to-t from-[#001F73] to-[#001F7313]"></div>
                            </div>
                        )}
                    </>
                ))}
            </div>
        </div>
    );
};

export default NewComingSoon;

import React from 'react';
import { Table, Button, Tag } from 'antd';
import './TeacherTable.scss';
import { TransactionsInterface } from '../interfaces';

interface TransactionsTableProps {
    transactions: TransactionsInterface[];
}
  
  
  const ReferralTable = ({ transactions }: TransactionsTableProps ) => {
    const columns = [
        {
          title: 'Date',
          dataIndex: 'createdAt',
          key: 'createdAt',
        },
        {
          title: 'Transaction ID',
          dataIndex: '_id',
          key: '_id',
        },
        {
          title: 'Fullname',
          dataIndex: 'referredFullname',
          key: 'referredFullname',
        },
        {
          title: 'Amount',
          dataIndex: 'amountEarned',
          key: 'amountEarned',
        },
        {
          title: 'Amount Paid',
          dataIndex: 'amountPaid',
          key: 'amountPaid',
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          render: (status: string) => (
            <div style={{ backgroundColor : status === 'success' ? 'rgba(17, 123, 0, 0.08)' : ( status === 'pending' ? 'rgba(239, 132, 71, 0.08)'  : 'rgba(242, 16, 16, 0.08)' ), color: status === 'success' ? 'rgba(17, 123, 0, 1)' : ( status === 'pending' ? 'rgba(239, 132, 71, 1)'  :'rgba(242, 16, 16, 1)' )}} className='p-1 rounded-md flex  items-center justify-center'>
              {status !== '' ? 'Active': 'Inactive' }
            </div>
          ),
        },
      ];
  
    return <Table dataSource={transactions} columns={columns} pagination={{ pageSize: 5 }} className="custom-table" />;
  };
  
  export default ReferralTable;
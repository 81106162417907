import About from '../components/about';
import wavyDesign from '../../../assets/Wave element.png';
import Playground from '../components/playground/index.tsx';
import Testimonies from '../components/testimonies';
import { Footer } from '../components/footer/footer';
import Faq from '../components/faq';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet-async';
import { landingPagePrps } from '@src/core/interfaces/general.tsx';
import AdvancedFeatures from '../features/components/advanced_features.tsx';
import { useEffect, useState } from 'react';

const LandingPage = ({ faqRef, featuresRef, isLoggedIn }: landingPagePrps) => {
    const navigatePage = useNavigate();

    const [isMobile, setIsMobile] = useState(true);

    useEffect(() => {
        if (window.innerWidth >= 820) {
            setIsMobile(false);
        }
    }, [window.innerWidth]);

    return (
        <div className="w-full bg-white">
            <Helmet>
                <meta name="google-site-verification" content="9kRjjJnXQxcAzarlLOs0gHWJCAfJTZ01WZaUSK-tTec" />
                <meta
                    name="description"
                    content="GMIND AI is an advanced AI tool designed to help teachers and educators streamline lesson plans, grading, and student engagement. Boost your teaching experience with AI-powered solutions."
                />

                <title>GMIND AI - Best AI for Teachers and Schools</title>
                <link rel="canonical" href="https://gmind.ai/" />
                <meta name="keywords" content="gmind, ai, chat, gmindai, teachers ai" />
            </Helmet>
            <div className="bg-[#FFCEB740] h-[85vh] md:h-[65vh] lg:h-screen">
                <div className="relative w-full h-[45vh] md:h-[50vh] lg:h-[65vh] mb-20 md:mb-[8rem] z-10">
                    <img
                        className="absolute top-[95%] md:top[75%] lg:top[70%] bottom-0 w-full h-[5rem] md:h-[7rem] z-0"
                        src={wavyDesign}
                        alt="wavyDesign"
                        loading="lazy"
                    />
                    <div className="w-full px-10 md:px-20 absolute">
                        <About isLoggedIn={isLoggedIn} />
                    </div>
                </div>
            </div>

            {/* <section className="w-full flex items-center justify-center mb-10">
                <img className="self-center hidden md:flex w-[45rem] h-[30rem]" src={FeaturesImg} alt="feature_img" loading="lazy" />
            </section> */}

            <div className="w-full mt-10 bg-white">
                <section ref={featuresRef} className="w-full mt-5 md:mt-0 px-0 md:px-20">
                    <Playground />
                    {/* <Partners /> */}
                </section>

                <section className="w-full mt-10">
                    <AdvancedFeatures />
                </section>

                {/* <div className="w-full mt-5 md:mt-0 px-10 md:px-20">
                    <QRSection />
                </div> */}
                {/* <section className="w-full mt-20 md:mt-5 px-10 md:px-20 mb-10">
                    <Services />
                </section> */}
                {/* <section className="w-full mt-20 md:mt-0 px-10 md:px-20">
                    <Engagement />
                </section> */}
                <section className="w-full mt-10 md:mt-5 px-10 md:px-20">
                    <Testimonies />
                </section>
                <section ref={faqRef} className="w-full mt-5 md:mt-5 px-10 md:px-40">
                    <Faq />
                </section>
            </div>
            <section>
                <Footer />
            </section>
        </div>
    );
};

export default LandingPage;

import React from 'react';
import { Table, Button, Tag } from 'antd';
import './TeacherTable.scss';
import { ReferralInterface } from '../interfaces';

interface ReferralTableProps {
    referrals: ReferralInterface[];
    limit?: boolean;
}
  
  
  const ReferralTable = ({ referrals, limit }: ReferralTableProps) => {
    const columns = [
        {
          title: 'Join Date',
          dataIndex: 'createdAt',
          key: 'createdAt',
        },
        {
          title: 'User ID',
          dataIndex: '_id',
          key: '_id',
        },
        {
          title: 'Name of Users',
          dataIndex: 'referredFullname',
          key: 'referredFullname',
        },
        {
          title: 'Subscription',
          dataIndex: 'planName',
          key: 'planName',
        },
        {
          title: 'Duration',
          dataIndex: 'planInterval',
          key: 'planInterval',
        },
        {
          title: 'Amount Paid',
          dataIndex: 'amountPaid',
          key: 'amountPaid',
        },
        {
          title: 'Amount Earned',
          dataIndex: 'amountEarned',
          key: 'amountEarned',
        },
        {
          title: 'Status',
          dataIndex: 'planName',
          key: 'planName',
          render: (status: string) => (
            <div style={{ backgroundColor : status !== '' ? 'rgba(17, 123, 0, 0.08)' : 'rgba(52, 63, 125, 0.12)', color:status !== '' ? 'green' : 'gray' }} className='p-1 rounded-md flex  items-center justify-center'>
              {status !== '' ? 'Active': 'Inactive' }
            </div>
          ),
        },
      ];
  
    return <Table dataSource={ limit ? referrals.slice(0, 5) :referrals} columns={columns} pagination={{ pageSize: 5 }}  className="custom-table" />;
  };
  
  export default ReferralTable;
import DashboardFrame from "@src/components/DashboardFrame";
import PricingHome from "@src/pages/acquisition/pricing/home/page";
import { useEffect, useState } from "react";

const DashboardPricing = () => {
    const [isMobile, setIsMobile] = useState(true);

    useEffect(() => {
        if (window.innerWidth >= 820) {
            setIsMobile(false);
        }
    }, [window.innerWidth]);
    return (
        <DashboardFrame
            showSidebar={false}
            showHistory={false}
            showTop={!isMobile}
            history={[]}
            showRightComponent={false}
            rightComponentPlaceholder='Pricing'
            selectedHistory={(v: string) => {}}
            closeIdValue={''}
            showHistoryOption={false}
            showPagePath={false}
            onDeleteHistory={(id: string) => {
                
            }}
            onDeleteAllHistory={() => {}}
            canNavigateHome = {false}
            showExpiredModal={false}
            showCompleteProfileModal={false}
        >
            <PricingHome />
        </DashboardFrame>
    );    
}


export default DashboardPricing
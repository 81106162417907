import { Col, Form, Input, Row } from 'antd';
import { useEffect, useState } from 'react';
import './style.scss';
import AIGenerate from '@icons/generate.svg';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import { BASE_URL } from '@src/utils/constants';
import { io, Socket } from 'socket.io-client';
import { onGenerateWithAI } from '../../curriculum/functions';

const { TextArea } = Input;
interface LoadingStates {
    definitions: boolean;
    policyStatement: boolean;
    procedures: boolean;
    rolesResponsibilities: boolean;
    complianceEnforcement: boolean;
    conclusion: boolean;
}

interface PolicyWriterProps {
    onGenerate: (e: string) => void;
    setTopic: (e: string) => void;
}

const CustomTextAreaWithButton = ({
    placeholder,
    uniqueKey,
    loadingStates,
    setLoadingStates,
    value,
    onChange,
    onClickButton,
}: {
    placeholder: string;
    uniqueKey: keyof LoadingStates;
    loadingStates: LoadingStates;
    setLoadingStates: React.Dispatch<React.SetStateAction<LoadingStates>>;
    value?: string; // Optional value prop
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void; // Optional onChange prop
    onClickButton: () => boolean;
}) => {
    const handleButtonClick = () => {
        let response = onClickButton();
        if ( response ){
            setLoadingStates((prevState) => ({
                ...prevState,
                [uniqueKey]: true,
            }));
        }

    };

    return (
        <div className="relative">
            <TextArea
                autoSize={{ minRows: 4}}
                placeholder={placeholder}
                className="p-2 border border-gray-500 focus:outline-none"
                style={{ outline: 'none', boxShadow: 'none' }}
                value={value}
                onChange={onChange}
            />
            {!loadingStates[uniqueKey] ? (
                <div
                    onClick={handleButtonClick}
                    className="cursor-pointer shadow-md rounded-[1rem] absolute top-[55px] right-[10px] py-[0.3rem] px-[1rem] bg-white text-normal text-[0.75rem] text-[var(--gmind-black)] flex gap-x-2"
                >
                    <img src={AIGenerate} alt="" />
                    <span>Use Gmind AI</span>
                </div>
            ) : (
                <div className="absolute top-[65px] right-[20px] ">
                    <CircularProgress size={15} className="w-1 h-1" />
                </div>
            )}
        </div>
    );
};

const PolicyWriter = ({ onGenerate, setTopic }: PolicyWriterProps) => {
    const [form] = Form.useForm();
    const [isPageOne, setIsPageOne] = useState(true);
    const [pageOneValues, setPageOneValues] = useState<{[key: string]: string}>({
        'policyTitle': '',
        'purposeAndScope': '',
        'definitions': '',
        'policyStatement': '',
    });
    const [sockets, setSockets] = useState<{ [key: string]: Socket | null}>({});
    const baseurl = BASE_URL;
    const socketKey = ['definitions', 'policyStatement', 'procedures', 'rolesResponsibilities', 'complianceEnforcement', 'conclusion']
    const [loadingStates, setLoadingStates] = useState({
        definitions: false,
        policyStatement: false,
        procedures: false,
        rolesResponsibilities: false,
        complianceEnforcement: false,
        conclusion: false,
    });


    useEffect(() => {
        const newSockets: { [key: string]: Socket } = {};
        socketKey.forEach(item => {
            newSockets[item] = io(baseurl.slice(0, -2));
        });
        setSockets(newSockets);

        return () => {
            Object.values(newSockets).forEach(socket => {
                socket.close();
            });
        };
    }, []);

    useEffect(() => {
        socketKey.forEach(item => {
            const socket = sockets[item];
            if (!socket) return;

            const handleData = (data: string) => {
                let previousValue = form.getFieldValue(item);
                form.setFieldValue( item, previousValue + data);
            };

            const handleStreamEnd = () => {
                setLoadingStates((prevState) => ({
                    ...prevState,
                    [item]: false,
                }));
            };

            socket.on('data', handleData);
            socket.on('stream_end', handleStreamEnd);
            return () => {
                socket.off('data', handleData);
                socket.off('stream_end', handleData);
            };
        });
    }, [sockets]);


    const onFinish = (values: any) => {
        const {
            policyTitle,
            purposeAndScope,
            definitions,
            policyStatement,
        } = pageOneValues;

        const {
            procedures,
            rolesResponsibilities,
            complianceEnforcement,
            conclusion,
            reviewRevision,
            relatedPolicies,
        } = values;

        if (
            !policyTitle ||
            !purposeAndScope ||
            !definitions ||
            !policyStatement ||
            !procedures ||
            !rolesResponsibilities ||
            !complianceEnforcement ||
            !conclusion
        ) {
            toast.error('Please fill all the required fields before generating.');
            return;
        }

        let informationData = `
            Policy Title: ${policyTitle}
            Purpose and Scope: ${purposeAndScope}
            Definitions and Terms: ${definitions}
            Policy Statement: ${policyStatement}
            Procedures: ${procedures}
            Roles and Responsibilities: ${rolesResponsibilities}
            Compliance and Enforcement: ${complianceEnforcement}
            Conclusion: ${conclusion}
            ${ reviewRevision ? `Review and Revision Schedule: ${reviewRevision}` : '' }
            ${ relatedPolicies ? `Related Policies: ${relatedPolicies}`: '' }
        `;

        const promptMessage = `Generate a well-structured and professionally written policy with the following details: ${informationData}`;
        onGenerate(promptMessage);
    };

    return (
        <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            initialValues={{
                policyTitle: '',
                purposeAndScope: '',
                definitions: '',
                policyStatement: '',
                procedures: '',
                rolesResponsibilities: '',
                complianceEnforcement: '',
                conclusion: '',
                reviewRevision: '',
                relatedPolicies: '',
            }}
        >
            <h1 className="text-xl font-bold font-Poppins mb-[16px] mt-[16px]">Policy Writer</h1>
            <p className="text-md font-Poppins mb-[16px]">
                Create comprehensive and clear policy documents with the help of this tool.
            </p>

            {isPageOne && (
                <>
                    <div className="grid grid-col-1 md:grid-cols-2 gap-4">
                        <div>
                            <Form.Item
                                label="Policy Title"
                                name="policyTitle"
                                rules={[{ required: true, message: 'Policy Title is required' }]}
                            >
                                <Input placeholder="Enter policy title" />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                label="Purpose and Scope"
                                name="purposeAndScope"
                                rules={[{ required: true, message: 'Purpose and Scope are required' }]}
                            >
                                <Input placeholder="Define the purpose and scope of the policy" />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="grid grid-cols-1  md:grid-cols-2 gap-4">
                        <div className="">
                            <Form.Item
                                label="Definitions and Terms"
                                name="definitions"
                                rules={[{ required: true, message: 'Definitions and Terms are required' }]}
                            >
                                <CustomTextAreaWithButton
                                    placeholder="Define terms used in the policy"
                                    uniqueKey="definitions"
                                    loadingStates={loadingStates}
                                    setLoadingStates={setLoadingStates}
                                    value={form.getFieldValue('definitions')} // Bind value to form field
                                    onChange={(e) => form.setFieldValue('definitions', e.target.value)} // Update form value on change
                                    onClickButton={() => {
                                        form.setFieldValue('definitions', '')
                                        let policyTitle = form.getFieldValue('policyTitle');
                                        if ( !policyTitle ){
                                            toast.error('Enter policy title to use this feature');
                                            return false;
                                        }
                                        let prompt = `Generate a definition and terms with not more than 300 characters for this policy named ${policyTitle}`;
                                        const socket = sockets["definitions"];
                                        onGenerateWithAI(socket, prompt);
                                        return true;
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                label="Policy Statement"
                                name="policyStatement"
                                rules={[{ required: true, message: 'Policy Statement is required' }]}
                            >
                                <CustomTextAreaWithButton
                                    placeholder="Clearly state the policy"
                                    uniqueKey="policyStatement"
                                    loadingStates={loadingStates}
                                    setLoadingStates={setLoadingStates}
                                    value={form.getFieldValue('policyStatement')} // Bind value to form field
                                    onChange={(e) => form.setFieldValue('policyStatement', e.target.value)} // Update form value on change
                                    onClickButton={() => {
                                        form.setFieldValue('policyStatement', '')
                                        let policyTitle = form.getFieldValue('policyTitle');
                                        if ( !policyTitle ){
                                            toast.error('Enter policy title to use this feature');
                                            return false;
                                        }
                                        let prompt = `Generate a policy statement with not more than 300 characters for this policy named ${policyTitle}`;
                                        const socket = sockets["policyStatement"];
                                        onGenerateWithAI(socket, prompt);
                                        return true;
                                    }}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <Row gutter={16}>
                        <Col span={24}>
                            <button
                                onClick={() =>  form.validateFields().then(() => {
                                    setPageOneValues({
                                        'policyTitle': form.getFieldValue('policyTitle'),
                                        'purposeAndScope': form.getFieldValue('purposeAndScope'),
                                        'definitions': form.getFieldValue('definitions'),
                                        'policyStatement': form.getFieldValue('policyStatement'),
                                    })
                                    setIsPageOne(false);
                                }) }
                                className="w-full md:w-[8rem] bg-customOrange text-white rounded p-2"
                            >
                                Next
                            </button>
                        </Col>
                    </Row>
                </>
            )}

            {!isPageOne && (
                <>
                    <div className="grid grid-cols-1  md:grid-cols-2 gap-4">
                        <div>
                            <Form.Item
                                label="Procedures"
                                name="procedures"
                                rules={[{ required: true, message: 'Procedures are required' }]}
                            >
                                <CustomTextAreaWithButton
                                    placeholder="Detail the procedures to follow"
                                    uniqueKey="procedures"
                                    loadingStates={loadingStates}
                                    setLoadingStates={setLoadingStates}
                                    value={form.getFieldValue('procedures')} // Bind value to form field
                                    onChange={(e) => form.setFieldValue('procedures', e.target.value)} // Update form value on change
                                    onClickButton={() => {
                                        form.setFieldValue('procedures', '')
                                        let policyTitle = pageOneValues['policyTitle'];
                                        let purpose = pageOneValues['purposeAndScope']
                                        let prompt = `Generate a policy Procedures with not more than 500 characters for this policy named ${policyTitle} with purpose and scope ${purpose}`;
                                        const socket = sockets["procedures"];
                                        onGenerateWithAI(socket, prompt);
                                        return true;
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                label="Roles and Responsibilities"
                                name="rolesResponsibilities"
                                rules={[{ required: true, message: 'Roles and Responsibilities are required' }]}
                            >
                                <CustomTextAreaWithButton
                                    placeholder="List roles and responsibilities"
                                    uniqueKey="rolesResponsibilities"
                                    loadingStates={loadingStates}
                                    setLoadingStates={setLoadingStates}
                                    value={form.getFieldValue('rolesResponsibilities')} // Bind value to form field
                                    onChange={(e) => form.setFieldValue('rolesResponsibilities', e.target.value)} // Update form value on change
                                    onClickButton={() => {
                                        form.setFieldValue('rolesResponsibilities', '')
                                        let policyTitle = pageOneValues['policyTitle'];
                                        let purpose = pageOneValues['purposeAndScope']
                                        let prompt = `Generate a policy roles and responsibility with not more than 600 characters for this policy named ${policyTitle} with purpose and scope ${purpose}. Please note no title or header in your response. Ensure the roles and responsibility are properly itemized`;
                                        const socket = sockets["rolesResponsibilities"];
                                        onGenerateWithAI(socket, prompt);
                                        return true;
                                    }}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="grid grid-cols-1  md:grid-cols-2 gap-4">
                        <div>
                            <Form.Item
                                label="Compliance and Enforcement"
                                name="complianceEnforcement"
                                rules={[{ required: true, message: 'Compliance and Enforcement are required' }]}
                            >
                                <CustomTextAreaWithButton
                                    placeholder="Specify compliance and enforcement measures"
                                    uniqueKey="complianceEnforcement"
                                    loadingStates={loadingStates}
                                    setLoadingStates={setLoadingStates}
                                    value={form.getFieldValue('complianceEnforcement')} // Bind value to form field
                                    onChange={(e) => form.setFieldValue('complianceEnforcement', e.target.value)} // Update form value on change
                                    onClickButton={() => {
                                        form.setFieldValue('complianceEnforcement', '')
                                        let policyTitle = pageOneValues['policyTitle'];
                                        let purpose = pageOneValues['purposeAndScope']
                                        let prompt = `Generate a policy compliance and enforcement with not more than 600 characters for this policy named ${policyTitle} with purpose and scope ${purpose}. Please note no title or header in your response. Ensure the compliance and enforcement are properly itemized`;
                                        const socket = sockets["complianceEnforcement"];
                                        onGenerateWithAI(socket, prompt);
                                        return true;
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                label="Conclusion"
                                name="conclusion"
                                rules={[{ required: true, message: 'Conclusion is required' }]}
                            >
                                <CustomTextAreaWithButton
                                    placeholder="Write a conclusion for the policy"
                                    uniqueKey="conclusion"
                                    loadingStates={loadingStates}
                                    setLoadingStates={setLoadingStates}
                                    value={form.getFieldValue('conclusion')} // Bind value to form field
                                    onChange={(e) => form.setFieldValue('conclusion', e.target.value)} // Update form value on change
                                    onClickButton={() => {
                                        form.setFieldValue('conclusion', '')
                                        let policyTitle = pageOneValues['policyTitle'];
                                        let purpose = pageOneValues['purposeAndScope']
                                        let prompt = `Generate a conclusion with not more than 400 characters for this policy named ${policyTitle} with purpose and scope ${purpose}. Please note no title or header in your response. Ensure the compliance and enforcement are properly itemized`;
                                        const socket = sockets["conclusion"];
                                        onGenerateWithAI(socket, prompt);
                                        return true;
                                    }}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="grid grid-cols-1  md:grid-cols-2 gap-4">
                        <div>
                            <Form.Item label="Review and Revision Schedule (Optional)" name="reviewRevision">
                                <Input placeholder="Specify the review and revision schedule (if any)" />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item label="Related Policies (Optional)" name="relatedPolicies">
                                <Input placeholder="Mention any related policies (if applicable)" />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="flex flex-row justify-between">
                        <div>
                            <button
                                onClick={() => setIsPageOne(true)}
                                className="w-full md:w-[8rem] bg-transparent border border-customOrange text-customOrange rounded p-2"
                            >
                                Prev
                            </button>
                        </div>
                        <div>
                            <Form.Item>
                                <button
                                    type="submit"
                                    className="w-full md:w-[8rem] bg-customOrange text-white rounded p-2"
                                >
                                    Generate
                                </button>
                            </Form.Item>
                        </div>
                    </div>
                </>
            )}
        </Form>
    );
};

export default PolicyWriter;

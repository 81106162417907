import React from 'react';
import { Modal,  } from 'antd';

interface AffiliateModalProps {
    isModalVisible: boolean;
   
    setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const AffiliatePendingModal = ({ isModalVisible,setIsModalVisible}: AffiliateModalProps) => {
  

    const handleOk = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <Modal
                title="Affiliate Request Processing"
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleOk}
                okText="Awesome!"
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <p>
                    Thanks for your interest in becoming an affiliate! Your request is being processed, and we're
                    thrilled to have you join us. Please allow 24 to 72 hours for us to review and approve your
                    application. Stay tuned!
                </p>
            </Modal>
        </>
    );
};

export default AffiliatePendingModal;

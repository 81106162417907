import { newGenerationHomeDataProps } from "../interface";
import syllabusImg from '@icons/syllabus.svg';
import lessonPlanner from '@icons/lesson_planner.svg';
import ShareImg from '@public/share.svg';
import Copy from '@public/copy.svg';
import DownloadImage from '@public/download.svg';
import research from '@icons/research.svg';
import article from '@icons/article.svg';
import comprehensive from '@icons/comphrensive.svg';
import socialMedia from '@icons/social_media.svg';
import letterImg from '@icons/letter.svg';
import { copyData, handleShare } from "@src/utils/app_functions";
import { PromptOptionsType } from "@src/core/interfaces/general";
// import two_arrows from '@assets/chat/two_arrows.svg';
import two_arrows from '@icons/exit_ticket.svg';
// import support from '@assets/chat/support.svg';
import support from '@icons/hooks.svg';
import activity from '@assets/activity.svg';
import { AssessementWhiteImage } from "@src/components/Sidebar/images";
import { ReportCardSvg } from "../svg";


const newGenerationHomeData: Array<newGenerationHomeDataProps> = [
    {
        img: syllabusImg,
        head: 'Blog Writer Ai',
        text: 'Generate blog posts on any topic.',
        pageIndex: 1,
        pageName: "Blog Writer Ai",
        label: 'blog_writer_ai',
    },
    {
        img: comprehensive,
        head: 'News Writer',
        text: 'Generate news articles on any topic.',
        pageIndex: 2,
        pageName: "News Writer",
        label: 'news_writer',
    },
     
    {
        img: letterImg,
        head: 'Jingle Writer',
        text: 'Generate jingles on any topic.',
        pageIndex: 3,
        pageName: "Jingle Writer",
        label: 'jingle_writer',
    },

  
];

const contentPromptOptionsData = ({response, onSuccess, urlRef, showDownload}: {
    response:string;
    onSuccess: React.Dispatch<React.SetStateAction<boolean>>;
    urlRef: React.MutableRefObject<string>;
    showDownload: React.Dispatch<React.SetStateAction<boolean>>;
} ): PromptOptionsType[]  => [
    {
        name: 'Share',
        icon: ShareImg,
        onClick: () => handleShare({
            response,
            onSuccess,
            urlRef,
        }),
    },
    {
        name: 'Copy',
        icon: Copy,
        onClick: () => copyData(response),
    },
    {
        name: 'Download',
        icon: DownloadImage,
        onClick: () => showDownload(true),
    },
];

const contentPromptOptionsData2 = ({response, showDownload}: {
    response:string;
    showDownload: React.Dispatch<React.SetStateAction<boolean>>;
} ): PromptOptionsType[]  => [
    {
        name: 'Copy',
        icon: Copy,
        onClick: () => copyData(response),
    },
    {
        name: 'Download',
        icon: DownloadImage,
        onClick: () => showDownload(true),
    },
];


export {
    newGenerationHomeData,
    contentPromptOptionsData,
    contentPromptOptionsData2,
}
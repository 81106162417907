import { BASE_URL } from "@src/utils/constants";
import axios from "axios";

const baseURL = BASE_URL; //process.env.NEXT_PUBLIC_GMIND_BASEURL;


export const getAllReferrals =  async () => {

    const token = localStorage.getItem("token");

    try {
      const res = await axios.get(
        `${baseURL}/affiliates/get_all_referrals`,
        {
          timeout: 12000,

          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          validateStatus: (status: number) => status >= 200 && status <= 505,
        }
      );
      if (res.data.statusCode !== 1)
        throw new Error(res.data.message);
      return {
        ...res.data,
        success: true,
      };
    } catch (err: any) {
      return {
        success: false,
        message: err.message,
        statusCode: err.statusCode,
      };
    }
}



export const getAllTransactions = async () => {

  const token = localStorage.getItem("token");

  try {
    const res = await axios.get(
      `${baseURL}/affiliates/get_all_transactions`,
      {
        timeout: 12000,

        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        validateStatus: (status: number) => status >= 200 && status <= 505,
      }
    );
    if (res.data.statusCode !== 1)
      throw new Error(res.data.message);
    return {
      ...res.data,
      success: true,
    };
  } catch (err: any) {
    return {
      success: false,
      message: err.message,
      statusCode: err.statusCode,
    };
  }
}




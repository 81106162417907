import React from 'react';
interface BalanceCardProps {
  balance: number;
}
const BalanceCard: React.FC<BalanceCardProps> = ({ balance }) => {
  return (
    <div className=" flex flex-col justify-between mt-[24px] py-3 balance-card w-full lg:w-[26%] font-Poppins bg-customOrange text-white rounded-sm shadow-md">
      <h2 className="px-4 text-[10px] font-Poppins">Balance</h2>
      <div className='flex  flex-col items-center justify-center'>
      <p className="text-[23px] font-semibold">{balance.toLocaleString('en-US', { minimumFractionDigits: 2 })}</p>
      <p className="text-[10px]">Available</p>
      </div>
      <button className="bg-white text-orange-500 w-full px-4 py-2 mt-2 text-[14px] font-normal leading-6 text-center ">Request Withdraw</button>
      <p className="text-[12px] px-5 text-center  flex flex-row justify-center items-center mt-2">You will need at least 50 referrals to qualify to withdraw your first earnings.</p>
    </div>
  );
};
export default BalanceCard;
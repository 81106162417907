import { Button, Form, Input } from 'antd';
import { LessonMaterialProps, LetterWriterProps } from '../interface';
import { toast } from 'react-toastify';
import { AudioOutlined, PaperClipOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import { useEffect, useRef, useState } from 'react';
import "./style.scss";
import mammoth from 'mammoth';
import * as pdfjsLib from 'pdfjs-dist';


const TextReWriter = ({ onGenerate, setTopic }: LetterWriterProps) => {
    const recognitionRef = useRef<any>(null);
    const [recording, setRecording] = useState(false);
    const [form] = Form.useForm();
    const [finalTranscript, setFinalTranscript] = useState('');

    const [recordingField, setRecordingField] = useState<string | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null); // Ref for the file input
    const [docData, setDocData] = useState<string | ArrayBuffer | null>(null);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
    
            reader.onload = async (e) => {
                const content = e.target?.result;
    
                if (file.type === 'application/pdf') {
                    const pdfData = new Uint8Array(content as ArrayBuffer);
                    const pdfDoc = await pdfjsLib.getDocument({ data: pdfData }).promise;
                    let pdfText = '';
    
                    for (let i = 1; i <= pdfDoc.numPages; i++) {
                        const page = await pdfDoc.getPage(i);
                        const textContent = await page.getTextContent();
                        textContent.items.forEach((item: any) => {
                            pdfText += item.str + ' ';
                        });
                    }
    
                    setDocData(pdfText);
                    form.setFieldsValue({ [field]: pdfText });
                } else if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                    const arrayBuffer = e.target?.result as ArrayBuffer;
                    const result = await mammoth.extractRawText({ arrayBuffer });
                    setDocData(result.value);
                    form.setFieldsValue({ [field]: result.value });
                } else if (content) {
                    setDocData(content as string);
                    form.setFieldsValue({ [field]: content });
                }
            };    
            reader.onerror = () => console.error('Error reading file');
    
            reader.readAsArrayBuffer(file);
        }
    };





    const onPaperClipClick = () => {
        // Programmatically trigger the file input click
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleMicrophoneClicked = (field: string) => {
        if (recording && recordingField !== field) {
            toast.warning('Please stop the current recording before starting a new one.');
            return; // Prevent starting a new recording
        }

        setRecordingField(field); // Set the current field being recorded
        handleMicrophone(field);
        console.log(field);
    };
    async function handleMicrophone(field: string) {
        if (recording) {
            stopRecording();
        } else {
            startRecording(field);
        }
    }

    const startRecording = (field: string) => {
        try {
            toast.success('Recording in progress...');
            setRecording(true);
            recognitionRef.current = new (window as any).webkitSpeechRecognition();
            recognitionRef.current.continuous = true;
            recognitionRef.current.interimResults = true;

            recognitionRef.current.onresult = (event: any) => {
                const { transcript } = event.results[event.results.length - 1][0];
                const texts = Array.from(event.results)
                    .map((result: unknown) => (result as any)[0])
                    .map((result: unknown) => (result as any).transcript);
                setFinalTranscript(texts.join(' '));
                console.log('text', texts);
                console.log('final:', finalTranscript);
                // Use getFieldsValue and setFieldsValue for Antd form
                // Use getFieldsValue and setFieldsValue for Antd form
                const fields = form.getFieldsValue();
                console.log('Current fields:', fields);

                // Set the updated value in the correct field
                form.setFieldsValue({
                    [field as string]: texts.join(''), // Update the specific field
                });
                console.log('Current fields:', fields);

                console.log(`Updated ${field}:`, texts);
            };

            recognitionRef.current.start();
        } catch (err: any) {
            toast.error(err.message);
        }
    };

    const stopRecording = () => {
        try {
            toast('Recording stopped');
            if (recognitionRef.current) {
                recognitionRef.current.stop();
                setRecording(false); // Reset after stopping
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };
    useEffect(() => {
        if (fileInputRef.current) {
            fileInputRef.current.value = ''; // Reset the file input value
          }
    }, [fileInputRef]);

    const onFinish = (values: any) => {
        let promptMessage = '';

        const originalText = values.originalText;
        const rewriteText = values.rewriteText;

        // check if all data is passed
        if (!originalText || !rewriteText) {
            toast.error('Please enter all prompts field');
            return;
        }

        promptMessage = `From this text ${originalText}.\nRewrite in such a way that new text meets this criteria: ${rewriteText}`;

        console.log(promptMessage);
        onGenerate(promptMessage, true);
    };

    return (
        <Form form={form} onFinish={onFinish} className="flex flex-col px-4 py-4 space-y-4" layout="vertical">
            <h1 className="text-xl font-bold">Text Rewriter</h1>
            <p>Revamp your words, transform your message, effortlessly rewrite your text.</p>
            <Form.Item
                label="Initial Text"
                name="originalText"
                rules={[{ required: true, message: 'Please enter the original text' }]}
                
            >
                <div className="flex items-start border border-gray-700 rounded">
                    <div className=" flex gap-y-3 flex-col px-3 py-2 text-gray-500">
                        <AudioOutlined
                            className={`hover:text-green-500 transition-colors duration-200 ${recording ? 'text-customOrang' : 'text-gray-500 '}`}
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleMicrophoneClicked('originalText')}
                        />

                        <PaperClipOutlined onClick={onPaperClipClick} />
                      
                        <input
                            ref={fileInputRef}
                            type="file"
                            accept=".doc,.docx,.pdf,.txt"
                            style={{ display: 'none' }} // Hide the input element
                            onChange={(e) => handleFileChange(e, 'originalText')}
                        />
                    </div>
                    <TextArea
                        value={form.getFieldValue('originalText'|| docData)} // This makes it controlled
                        onChange={(e) => {
                            setDocData(e.target.value); // Update both docData and form values
                            form.setFieldsValue({ originalText: e.target.value });
                        }}
                        rows={12}
                        className="p-2 rounded-none border-none w-full focus:outline-none bg-transparent hover:bg-transparent placeholder-gray-700"
                        placeholder="Please enter the text you would like to have rewritten. This tool is most effective for texts with 1,500 words or fewer."
                        style={{ outline: 'none', boxShadow: 'none',borderColor: 'transparent',  }} // Remove outline and box shadow
                    />
                </div>
            </Form.Item>
            <Form.Item
                label="Rewrite so that:"
                name="rewriteText"
                rules={[{ required: true, message: 'Please enter your text here' }]}
                className="w-full md:w-[60%]"
            >
                <Input
                    value={form.getFieldValue('rewriteText')} // This makes it controlled
                    onChange={(e) => form.setFieldsValue({ rewriteText: e.target.value })}
                    prefix={
                        <AudioOutlined
                            className={`hover:text-green-500 transition-colors duration-200 ${recording ? 'text-red' : 'text-gray-500 '}`}
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleMicrophoneClicked('rewriteText')}
                        />
                    }
                    placeholder="Enter your text here"
                    style={{backgroundColor:"transparent", outline:"none"}}
                    className="p-2 rounded border border-gray-700 bg-transparent hover:bg-transparent placeholder-gray-700"
                />
            </Form.Item>
            <Form.Item>
                <button
                    type="submit"
                    className=" no-hover-effect mt-2 w-full md:w-[8rem] py-2 px-4 bg-customOrange text-white rounded hover:bg-[var(--gmind-orange)]"
                >
                    Generate
                </button>
            </Form.Item>
        </Form>
    );
};

export default TextReWriter;

import React, { useState } from 'react';
import { Form, Input, Row, Col } from 'antd';
import { CircularProgress } from '@mui/material';
import TextArea from 'antd/es/input/TextArea';
import AIGenerate from '@icons/generate.svg';

interface JingleWriterLoadingStates {
    title: boolean;
    objective: boolean;
    mainMessage: boolean;
    specialMessage: boolean;
    targetAudience: boolean;
    catchiness: boolean;
    length: boolean;
    emotionTone: boolean; // Changed from emotionAndTone to match usage
    callToAction: boolean;
    lyrics: boolean;
    sloganIntegration: boolean;
}

const CustomTextAreaWithButton = ({
    placeholder,
    uniqueKey,
    loadingStates,
    setLoadingStates,
    value,
    onChange,
}: {
    placeholder: string;
    uniqueKey: keyof JingleWriterLoadingStates;
    loadingStates: JingleWriterLoadingStates;
    setLoadingStates: React.Dispatch<React.SetStateAction<JingleWriterLoadingStates>>;
    value?: string;
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}) => {
    const handleButtonClick = () => {
        setLoadingStates((prevState) => ({
            ...prevState,
            [uniqueKey]: true,
        }));

        setTimeout(() => {
            setLoadingStates((prevState) => ({
                ...prevState,
                [uniqueKey]: false,
            }));
        }, 3000);
    };

    return (
        <div className="relative">
            <TextArea
                rows={4}
                placeholder={placeholder}
                className="p-2 border-gray-200 focus:outline-none"
                style={{ outline: 'none', boxShadow: 'none' }}
                value={value}
                onChange={onChange}
            />
            {!loadingStates[uniqueKey] ? (
                <div
                    onClick={handleButtonClick}
                    className="cursor-pointer shadow-md rounded-[1rem] absolute top-[55px] right-[10px] py-[0.3rem] px-[1rem] bg-white text-normal text-[0.75rem] text-[var(--gmind-black)] flex gap-x-2"
                >
                    <img src={AIGenerate} alt="AI Generate" />
                    <span>Use Gmind AI</span>
                </div>
            ) : (
                <div className="absolute top-[65px] right-[20px]">
                    <CircularProgress size={15} className="w-1 h-1" />
                </div>
            )}
        </div>
    );
};

const JingleScriptForm = ({ onGenerate, setTopic }: { onGenerate: any; setTopic: any }) => {
    const [page, setPage] = useState(1);
    const [loadingStates, setLoadingStates] = useState<JingleWriterLoadingStates>({
        title: false,
        mainMessage: false,
        specialMessage: false,
        targetAudience: false,
        catchiness: false,
        length: false,
        emotionTone: false,
        callToAction: false,
        lyrics: false,
        sloganIntegration: false,
        objective: false,
    });

    const [form] = Form.useForm();

    const handleSubmit = (values: any) => {
        console.log('Form values:', values);
    };

    const handleNext = () => {
        if (page < 2) {
            form.validateFields().then(() => {
                setPage(page + 1);
            });
        }
    };

    const handlePrevious = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    return (
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {page === 1 && (
                    <>
                        <Form.Item
                            label="Title"
                            name="title"
                            rules={[{ required: true, message: 'Title is required' }]}
                        >
                            <Input placeholder="Enter a catchy or descriptive title" />
                        </Form.Item>
                        <Form.Item
                            label="Objective"
                            name="objective"
                            rules={[{ required: true, message: 'Objective is required' }]}
                        >
                            <Input placeholder="Describe the purpose or goal of your jingle" />
                        </Form.Item>
                        <Form.Item
                            label="Main Message"
                            name="mainMessage"
                            rules={[{ required: true, message: 'Main message is required' }]}
                        >
                            <CustomTextAreaWithButton
                                placeholder="Highlight the core message of your jingle"
                                uniqueKey="mainMessage"
                                loadingStates={loadingStates}
                                setLoadingStates={setLoadingStates}
                                value={form.getFieldValue('mainMessage')}
                                onChange={(e) => form.setFieldsValue({ mainMessage: e.target.value })}
                            />
                        </Form.Item>
                        <Form.Item label="Special Message/Offer" name="specialMessage">
                            <CustomTextAreaWithButton
                                placeholder="Mention any special offers or promotions"
                                uniqueKey="specialMessage"
                                loadingStates={loadingStates}
                                setLoadingStates={setLoadingStates}
                                value={form.getFieldValue('specialMessage')}
                                onChange={(e) => form.setFieldsValue({ specialMessage: e.target.value })}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Target Audience"
                            name="targetAudience"
                            rules={[{ required: true, message: 'Target audience is required' }]}
                        >
                            <Input placeholder="Specify the target audience (age, interests, etc.)" />
                        </Form.Item>
                    </>
                )}

                {page === 2 && (
                    <>
                        <Form.Item
                            label="Catchiness"
                            name="catchiness"
                            rules={[{ required: true, message: 'Catchiness factor is required' }]}
                        >
                            <CustomTextAreaWithButton
                                placeholder="Incorporate a catchy hook, rhythm, or rhyme"
                                uniqueKey="catchiness"
                                loadingStates={loadingStates}
                                setLoadingStates={setLoadingStates}
                                value={form.getFieldValue('catchiness')}
                                onChange={(e) => form.setFieldsValue({ catchiness: e.target.value })}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Length"
                            name="length"
                            rules={[{ required: true, message: 'Length is required' }]}
                        >
                            <Input placeholder="Define the jingle's length (15-30 seconds)" />
                        </Form.Item>
                        <Form.Item
                            label="Emotion and Tone"
                            name="emotionTone"
                            rules={[{ required: true, message: 'Emotion and tone are required' }]}
                        >
                            <CustomTextAreaWithButton
                                placeholder="Set the emotion and tone for your jingle"
                                uniqueKey="emotionTone"
                                loadingStates={loadingStates}
                                setLoadingStates={setLoadingStates}
                                value={form.getFieldValue('emotionTone')}
                                onChange={(e) => form.setFieldsValue({ emotionTone: e.target.value })}
                            />
                        </Form.Item>
                        <Form.Item label="Call to Action" name="callToAction">
                            <CustomTextAreaWithButton
                                placeholder="Add a clear call to action (optional)"
                                uniqueKey="callToAction"
                                loadingStates={loadingStates}
                                setLoadingStates={setLoadingStates}
                                value={form.getFieldValue('callToAction')}
                                onChange={(e) => form.setFieldsValue({ callToAction: e.target.value })}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Lyrics"
                            name="lyrics"
                            rules={[{ required: true, message: 'Lyrics are required' }]}
                        >
                            <CustomTextAreaWithButton
                                placeholder="Write the lyrics for your jingle"
                                uniqueKey="lyrics"
                                loadingStates={loadingStates}
                                setLoadingStates={setLoadingStates}
                                value={form.getFieldValue('lyrics')}
                                onChange={(e) => form.setFieldsValue({ lyrics: e.target.value })}
                            />
                        </Form.Item>
                        <Form.Item label="Slogan Integration" name="sloganIntegration">
                            <CustomTextAreaWithButton
                                placeholder="Seamlessly integrate your brand's slogan"
                                uniqueKey="sloganIntegration"
                                loadingStates={loadingStates}
                                setLoadingStates={setLoadingStates}
                                value={form.getFieldValue('sloganIntegration')}
                                onChange={(e) => form.setFieldsValue({ sloganIntegration: e.target.value })}
                            />
                        </Form.Item>
                    </>
                )}
            </div>

            <div className="flex justify-between">
                {page > 1 && (
                    <button
                        className="w-full md:w-[8rem] bg-transparent border border-customOrange text-customOrange rounded p-2"
                        onClick={handlePrevious}
                    >
                        Previous
                    </button>
                )}
                {page < 2 ? (
                    <button className="w-full md:w-[8rem] bg-customOrange text-white rounded p-2" onClick={handleNext}>
                        Next
                    </button>
                ) : (
                    <button type="submit" className="w-full md:w-[8rem] bg-customOrange text-white rounded p-2">
                        Generate
                    </button>
                )}
            </div>
        </Form>
    );
};
export default JingleScriptForm;

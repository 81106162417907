import { GetUser } from '@src/core/endpoints/user';
import { AffiliateRecordInterface } from '@src/core/interfaces/user';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { toast } from 'sonner';

interface UserDetailsData {
    profilePic: string | null;
    firstName?: string;
    userId?: string;
    affiliateRecord?: AffiliateRecordInterface;

}

interface UserContextProps {
    userDetails: UserDetailsData | null;
    refreshUserDetails: () => Promise<void>;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

// Custom hook to use UserContext
// eslint-disable-next-line react-refresh/only-export-components
export const useUserContext = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUserContext must be used within a UserProvider');
    }
    return context;
};

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [userDetails, setUserDetails] = useState<UserDetailsData | null>(null);
    const [requestStatus, setRequestStatus] = useState<boolean>(true);

    const refreshUserDetails = async () => {
        const token = localStorage.getItem('token');
        if (!token) return;

        const details = await GetUser(token);
        if (details.statusCode == 0) {
            setRequestStatus(false);
            toast.error(details.message);
            return;
        }
        const detailsFetched: UserDetailsData = details.data;
        setUserDetails(detailsFetched);
    };

    useEffect(() => {
        if (!requestStatus) return;
        if (!userDetails) {
            refreshUserDetails();
        }
    }, [userDetails, requestStatus]);

    useEffect(() => {
        console.log('userDetails:', userDetails);
    }, [userDetails]);

    return <UserContext.Provider value={{ userDetails, refreshUserDetails }}>{children}</UserContext.Provider>;
};

// In DocumentProvider.tsx

import { BASE_URL } from '@src/utils/constants';
import React, { createContext, useState, useContext } from 'react';

interface UserDetails {
    email: string;
    docId: string;
    role: string;
}

interface DocumentContextProps {
    userDetails: UserDetails | null;
    error: string | null;
    fetchError: string | null;
    document: DocumentData | null;
    documentResponse: DocumentResponse | null;
    loading: boolean;
    fetchDocument: (arg0: string, arg1: boolean) => Promise<void>;
    isRole: boolean;
}
// Define the Collaborator interface
interface Collaborator {
    userId: string;
    email: string;
    acceptInvite: boolean;
    access: 'view' | 'edit';
    inviteExpiryDate: string;
    _id: string;
}

// Define the Data interface
interface DocumentData {
    id: string;
    data: string;
    prompt: string;
    promptType: string;
    versions: string[]; // Assuming it's an array of strings, adjust if needed
    createdAt: string;
    updatedAt: string;
    collaborators: Collaborator[];
}

// Define the main response interface
export interface DocumentResponse {
    statusCode: number;
    hasAccess: boolean;
    owner: boolean;
    role: string; // Adjust based on possible roles
    message: string;
    data: DocumentData;
}

export interface ErrorDocumentResponse {
    statusCode: number;
    data: string;
}

export interface SaveDocumentInterface {
    promptSent: string;
    promptType: string;
    fromPage:   string;
    content:    string;
    doc_id:     string;
}

export interface DocumentPropertiesJsonInterface {
    promptSent: string;
    promptType: string;
    fromPage:   string;
    doc_id:     string;
}

const DocumentContext = createContext<DocumentContextProps | undefined>(undefined);

export const DocumentProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [userDetails, setUserDetails] = useState<UserDetails | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [fetchError, setFetchError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isRole, setIsRole] = useState<boolean>(false);
    const [document, setDocument] = useState<DocumentData | null>(null);
    const [documentResponse, setDocumentResponse] = useState<DocumentResponse | null>(null);
    

    // Function to fetch the document data
    const fetchDocument = async (docId: string, canLoad = true) => {
        if (canLoad) {
            setLoading(true);
        }
        setError(null);

        const token = localStorage.getItem('token');

        try {
            const response = await fetch(`${BASE_URL}/document/get-document?documentId=${docId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                const errorMessage = `Error: ${response.status} ${response.statusText}`;

                // check if 
                const error: ErrorDocumentResponse =  await response.json();

                if ( error.statusCode == 401 ){
                    // save document from local storage data
                    await saveDocumentFromLocalStorage(docId)
                    return;
                }
                setError(errorMessage);
                throw new Error(errorMessage);
            }

            const data: DocumentResponse = await response.json();
            setDocument(data.data);
            setDocumentResponse(data);
            setFetchError(null); // Clear any previous errors
        } catch (error) {
            console.error('Error fetching document:', error);
            setError('Failed to fetch the document. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const saveDocumentFromLocalStorage = async (docId : string ) => {
        // check date from local storage
        const documentContent = window.localStorage.getItem('essay_data');

        if ( !documentContent ){
            setError('Failed to fetch the document. Please try again.');
            return;
        }

        const documentProperties = window.localStorage.getItem('prompt_data');

        if ( !documentProperties ){
            setError('Failed to fetch the document. Please try again.');
            return;
        }

        const documentPropertiesJson: DocumentPropertiesJsonInterface = JSON.parse(documentProperties);

        // check if docId on localstorage is the same as doc to save
        if ( documentPropertiesJson.doc_id != docId ){
            setError('Failed to fetch the document. Please try again.');
            return;
        }


        let dataToSend: SaveDocumentInterface = {
            promptSent: documentPropertiesJson.promptSent,
            promptType: documentPropertiesJson.promptType,
            content: documentContent,
            fromPage: documentPropertiesJson.fromPage,
            doc_id: docId
        } 



       
        setLoading(true);
        setError(null);

        const token = localStorage.getItem('token');

        try {
            const response = await fetch(`${BASE_URL}/document/save-document`, {
                method: 'POST',
                body: JSON.stringify(dataToSend),
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                const errorMessage = `Error: ${response.status} ${response.statusText}`;
                setError(errorMessage);
                throw new Error(errorMessage);
            }

            fetchDocument(docId, true);
        } catch (error) {
            console.error('Error fetching document:', error);
            setError('Failed to fetch the document. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    // Automatically fetch document on mount

    return (
        <DocumentContext.Provider
            value={{
                userDetails,
                error,
                document,
                loading,
                fetchDocument,
                documentResponse,

                isRole,
                fetchError,
            }}
        >
            {children}
        </DocumentContext.Provider>
    );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useDocumentContext = () => {
    const context = useContext(DocumentContext);
    if (context === undefined) {
        throw new Error('useDocumentContext must be used within a DocumentProvider');
    }
    return context;
};

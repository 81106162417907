/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import home from '@assets/home.svg';
import logo from '@assets/essay/Gmind Logo White.png';
import { ReactNode, useEffect, useState } from 'react';
import right from '@assets/right_gray.svg';
import search from '@assets/search.svg';
import question_circle from '@assets/question_circle.svg';
import { useLocation, useNavigate } from 'react-router';
import { IoMdLogOut } from 'react-icons/io';
import { capitalizeFirstLetter } from '@src/utils/app_functions';
import ContentTypingComponent from '@src/pages/dashboard/components/content_typing';
import { ArrowRight, ArrowRightIcon } from 'lucide-react';

const Top = ({
    onShowHistoryOption,
    showHistoryOption,
    sidebarCollapse,
    topChild,
    otherRightActions,
    onClickPage,
    breadCrumbPage,
    showPagePath,
    gmindTyping,
    canNavigateHome= true,
    packageName,
}: {
    header?: string;
    onClick?: (v: string) => void;
    isMobile?: boolean;
    sidebarCollapse: boolean;
    showHistoryOption: boolean;
    onShowHistoryOption?: () => void;
    topChild?: ReactNode;
    otherRightActions?: string[];
    onClickPage?: () => void;
    breadCrumbPage?: string;
    showPagePath?: boolean;
    gmindTyping?: boolean;
    canNavigateHome: boolean;
    packageName?: string;
}) => {
    const location = useLocation();
    const isEditorPage = location.pathname === '/dashboard/article/editor';
    const navigate = useNavigate();
    const [paths, setPaths] = useState<any[]>([]);
    const dictionary: any = {
        chat: 'Chat',
        course_content: 'Course Content',
        article: 'Article Writer',
        curriculum: 'Curriculum Planner',
        assessment: 'Create Assessment',
        transcriber: 'Transcriber',
        research: 'Make Research',
        generate_content: 'Generate Content',
        slide: 'Slider Maker',
    };
    const reverseDictionary: any = {
        'Chat': 'chat',
        'Course Content': 'course_content',
        'Article Writer': 'article',
        'Curriculum Planner': 'curriculum',
        'Create Assessment': 'assessment',
        'Make Research': 'research',
        'Generate Content': 'generate_content',
        'Slide Maker': 'slide',
        'Transcriber': 'transcriber',
    };

    const [authenticated, setAuthenticated ] = useState<boolean>(false);

    useEffect(() => {
        const token = localStorage.getItem("token");
        if ( token ){
            setAuthenticated(true);
        }else{
            setAuthenticated(false);
        }

    }, [window.location.pathname])

    useEffect(() => {
        const { pathname } = window.location;
        const split = pathname.split('/').slice(2);
        const formatPaths = split.map((path: string) => dictionary[path]);
        setPaths(formatPaths);
    }, [window.location.pathname]);

    function handleRoute(path: string) {
        // navigate(path);
        window.location.href = path;
    }

    function handleLogout() {
        if ( !authenticated ) {
            navigate("/login");
            return;
        }
        localStorage.clear();
        window.location.replace('/');
    }

    return (
        <section className="w-[100%] h-16 bg-[#00027d] shadow-md mb-[5px] border-b  border-gray-200 py-5 flex sticky top-0 z-[300] items-center px-4 justify-between gap-8">
            <div className="flex items-center gap-3">
                <img src={home} alt="" className="w-[1rem]" />

                <div className="flex items-center text-white gap-1 mt-[0.2rem]">
                    <p
                        
                        role="button"
                        onClick={() => canNavigateHome ?  handleRoute('/dashboard/chat'): handleRoute("/")}
                        className="m-0 text-md"
                    >
                        Home
                    </p>
                    {paths.length > 0 &&  <ArrowRightIcon className='h-3 w-3 text-white' />}
                    {breadCrumbPage && (
                        <div className="flex gap-1 items-ceneter">
                            <p
                                
                                role="button"
                                className=" m-0 text-md font-light text-nowrap"
                                onClick={() => {
                                    if (onClickPage) {
                                        onClickPage();
                                        return;
                                    }
                                    localStorage.removeItem('activeChat');
                                    handleRoute(`/dashboard/${breadCrumbPage}`);
                                }}
                            >
                                {capitalizeFirstLetter(breadCrumbPage)}
                            </p>
                        </div>
                    )}
                    {showPagePath &&
                        !breadCrumbPage &&
                        paths.map((p, i) => (
                            <div key={i} className="flex gap-1 items-center">
                                <p
                                    style={paths.length === i + 1 ? { fontWeight: '600' } : { fontWeight: '300' }}
                                    role="button"
                                    className=" m-0 text-md font-light text-nowrap"
                                    onClick={() => {
                                        if (onClickPage) {
                                            onClickPage();
                                            return;
                                        }
                                        const new_path = paths
                                            .slice(0, i + 1)
                                            .map((c) => reverseDictionary[c])
                                            .join('/');
                                        localStorage.removeItem('activeChat');
                                        handleRoute(`/dashboard/${new_path}`);
                                    }}
                                >
                                    {p}
                                </p>
                                {paths.length !== i + 1 && <img src={right} className="w-3" />}
                            </div>
                        ))}
                    {showPagePath && topChild}
                </div>
            </div>

            <img
                onClick={() => {
                    if ( !canNavigateHome ) return;
                    navigate('/');
                }}
                role="button"
                src={logo}
                className="w-[8rem]"
                alt=""
            />
            <div className="flex items-center gap-2 pe-10">
                {packageName && (
                    <button
                        onClick={() => {}}
                        type="button"
                        className="text-brandOrange w-fit p-1 px-2 text-xs"
                    >
                        {packageName}
                    </button>
                )}
                {showHistoryOption && (
                    <button
                        onClick={() => onShowHistoryOption && onShowHistoryOption()}
                        type="button"
                        className="text-white w-fit p-1 px-3 text-md"
                    >
                        History
                    </button>
                )}
                <button
                    onClick={handleLogout}
                    className="p-2 transition-all flex items-center hover:bg-brandOrange hover:text-white gap-2 text-md justify-center rounded-md w-full text-white"
                    type="button"
                >
                    <IoMdLogOut  className="text-lg text-white  rotate-180" />
                    <span> { authenticated ? 'Logout' : 'Login' }</span>
                </button>
                {otherRightActions?.map((action) => {
                    return (
                        <button className="text-brandOrange p-1 px-3 text-xs" key={action} type="button">
                            {action}
                        </button>
                    );
                })}
            </div>
        </section>
    );
};

export default Top;

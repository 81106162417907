import { Button, Form, Input } from 'antd';
import { useState } from 'react';
import './style.scss';
import AIGenerate from '@icons/generate.svg';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';

const { TextArea } = Input;

interface NewsWriterLoadingStates {
    headline: boolean;
    newsCategory: boolean;
    introduction: boolean;
    body: boolean;
    conclusion: boolean;
    audienceTargeting: boolean;
    languageTone: boolean;
    seoOptimization: boolean;
}

interface NewsWriterFormValues {
    headline: string;
    newsCategory: string;
    introduction: string;
    body: string;
    conclusion: string;
    audienceTargeting: string;
    languageTone: string;
    seoOptimization: string;
}

const CustomTextAreaWithButton = ({
    placeholder,
    uniqueKey,
    loadingStates,
    setLoadingStates,
    value, // Add value prop
    onChange, // Add onChange prop
}: {
    placeholder: string;
    uniqueKey: keyof NewsWriterLoadingStates;
    loadingStates: NewsWriterLoadingStates;
    setLoadingStates: React.Dispatch<React.SetStateAction<NewsWriterLoadingStates>>;
    value?: string; // Optional value prop
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void; // Optional onChange prop
}) => {
    const handleButtonClick = () => {
        setLoadingStates((prevState) => ({
            ...prevState,
            [uniqueKey]: true,
        }));

        setTimeout(() => {
            setLoadingStates((prevState) => ({
                ...prevState,
                [uniqueKey]: false,
            }));
        }, 3000);
    };

    return (
        <div className="relative">
            <TextArea
                rows={4}
                placeholder={placeholder}
                className="p-2 border-gray-200 w-full focus:outline-none"
                style={{ outline: 'none', boxShadow: 'none' }}
                value={value}
                onChange={onChange}
            />
            {!loadingStates[uniqueKey] ? (
                <div
                    onClick={handleButtonClick}
                    className="cursor-pointer shadow-md rounded-[1rem] absolute top-[55px] right-[10px] py-[0.3rem] px-[1rem] bg-white text-normal text-[0.75rem] text-[var(--gmind-black)] flex gap-x-2"
                >
                    <img src={AIGenerate} alt="" />
                    <span>Use Gmind AI</span>
                </div>
            ) : (
                <div className="absolute top-[65px] right-[20px] ">
                    <CircularProgress size={15} className="w-1 h-1" />
                </div>
            )}
        </div>
    );
};

const NewsWriter = ({ onGenerate, setTopic }: { onGenerate: any; setTopic: any }) => {
    const [form] = Form.useForm<NewsWriterFormValues>();
    const [loadingStates, setLoadingStates] = useState<NewsWriterLoadingStates>({
        headline: false,
        newsCategory: false,
        introduction: false,
        body: false,
        conclusion: false,
        audienceTargeting: false,
        languageTone: false,
        seoOptimization: false,
    });

    const [currentPage, setCurrentPage] = useState(1);

    const onFinish = (values: NewsWriterFormValues) => {
        const {
            headline,
            newsCategory,
            introduction,
            body,
            conclusion,
            audienceTargeting,
            languageTone,
            seoOptimization,
        } = values;

        if (
            !headline ||
            !newsCategory ||
            !introduction ||
            !body ||
            !conclusion ||
            !audienceTargeting ||
            !languageTone ||
            !seoOptimization
        ) {
            toast.error('Please fill all the required fields before generating.');
            return;
        }

        const promptMessage = `
      Headline: ${headline}
      News Category: ${newsCategory}
      Introduction: ${introduction}
      Body: ${body}
      Conclusion: ${conclusion}
      Audience Targeting: ${audienceTargeting}
      Language and Tone: ${languageTone}
      SEO Optimization: ${seoOptimization}
    `;

        onGenerate(promptMessage);
    };

    const nextPage = () => {
        setCurrentPage(2);
    };

    const previousPage = () => {
        setCurrentPage(1);
    };

    return (
        <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            initialValues={{
                headline: '',
                newsCategory: '',
                introduction: '',
                body: '',
                conclusion: '',
                audienceTargeting: '',
                languageTone: '',
                seoOptimization: '',
            }}
        >
            <h1 className="text-xl font-bold font-Poppins mb-4 mt-4">News Writer</h1>
            <p className="text-md font-Poppins mb-4">
                Create comprehensive and engaging news articles with the help of this tool.
            </p>

            {currentPage === 1 && (
                <>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <Form.Item
                                label="Headline Generation"
                                name="headline"
                                rules={[{ required: true, message: 'Headline is required' }]}
                            >
                                <Input placeholder="Enter headline" />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                label="News Categorization"
                                name="newsCategory"
                                rules={[{ required: true, message: 'News Category is required' }]}
                            >
                                <Input placeholder="Enter news category" />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <Form.Item
                                label="Introduction"
                                name="introduction"
                                rules={[{ required: true, message: 'Introduction is required' }]}
                            >
                                <CustomTextAreaWithButton
                                    placeholder="Let Gmind AI generate an introduction"
                                    uniqueKey="introduction"
                                    loadingStates={loadingStates}
                                    setLoadingStates={setLoadingStates}
                                    value={form.getFieldValue('introduction')} // Bind value to form field
                                    onChange={(e) => form.setFieldValue('introduction', e.target.value)} // Update form value on change
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="flex mt-2">
                        <button
                            onClick={() =>
                                form.validateFields().then(() => {
                                    setCurrentPage(currentPage + 1);
                                })
                            }
                            className="w-full md:w-[8rem] bg-customOrange text-white rounded p-2"
                        >
                            Next
                        </button>
                    </div>
                </>
            )}

            {currentPage === 2 && (
                <>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <Form.Item
                                label="Body"
                                name="body"
                                rules={[{ required: true, message: 'Body is required' }]}
                            >
                                <CustomTextAreaWithButton
                                    placeholder="Let Gmind AI generate the body"
                                    uniqueKey="body"
                                    loadingStates={loadingStates}
                                    setLoadingStates={setLoadingStates}
                                    value={form.getFieldValue('body')} // Bind value to form field
                                    onChange={(e) => form.setFieldValue('body', e.target.value)} // Update form value on change
                                />
                            </Form.Item>
                        </div>

                        <div>
                            <Form.Item
                                label="Conclusion"
                                name="conclusion"
                                rules={[{ required: true, message: 'Conclusion is required' }]}
                            >
                                <CustomTextAreaWithButton
                                    placeholder="Let Gmind AI generate the conclusion"
                                    uniqueKey="conclusion"
                                    loadingStates={loadingStates}
                                    setLoadingStates={setLoadingStates}
                                    value={form.getFieldValue('conclusion')} // Bind value to form field
                                    onChange={(e) => form.setFieldValue('conclusion', e.target.value)} // Update form value on change
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <Form.Item
                                label="Audience Targeting"
                                name="audienceTargeting"
                                rules={[{ required: true, message: 'Audience Targeting is required' }]}
                            >
                                <CustomTextAreaWithButton
                                    placeholder="Let Gmind AI generate audience targeting"
                                    uniqueKey="audienceTargeting"
                                    loadingStates={loadingStates}
                                    setLoadingStates={setLoadingStates}
                                    value={form.getFieldValue('audienceTargeting')} // Bind value to form field
                                    onChange={(e) => form.setFieldValue('audienceTargeting', e.target.value)} // Update form value on change
                                />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                label="Language and Tone"
                                name="languageTone"
                                rules={[{ required: true, message: 'Language and Tone are required' }]}
                            >
                                <CustomTextAreaWithButton
                                    placeholder="Let Gmind AI generate language and tone"
                                    uniqueKey="languageTone"
                                    loadingStates={loadingStates}
                                    setLoadingStates={setLoadingStates}
                                    value={form.getFieldValue('languageTone')} // Bind value to form field
                                    onChange={(e) => form.setFieldValue('languageTone', e.target.value)} // Update form value on change
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <Form.Item
                                label="SEO Optimization"
                                name="seoOptimization"
                                rules={[{ required: true, message: 'SEO Optimization is required' }]}
                            >
                                <CustomTextAreaWithButton
                                    placeholder="Let Gmind AI generate SEO optimization"
                                    uniqueKey="seoOptimization"
                                    loadingStates={loadingStates}
                                    setLoadingStates={setLoadingStates}
                                    value={form.getFieldValue('seoOptimization')} // Bind value to form field
                                    onChange={(e) => form.setFieldValue('seoOptimization', e.target.value)} // Update form value on change
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="flex justify-between mt-4">
                        <button
                            onClick={previousPage}
                            className="w-full md:w-[8rem] bg-transparent border border-customOrange text-customOrange rounded p-2"
                        >
                            Previous
                        </button>
                        <button type="submit" className="w-full md:w-[8rem] bg-customOrange text-white rounded p-2">
                            Generate
                        </button>
                    </div>
                </>
            )}
        </Form>
    );
};

export default NewsWriter;

/* eslint-disable prefer-const */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState } from 'react';

import TopNavBar from '@src/components/Top';
import { io } from 'socket.io-client';
import { GetHistory, SaveHistory } from '@src/core/endpoints/chat';
import { v4 as uuidv4 } from 'uuid';
import { message, notification } from 'antd';
import { prompts } from '@src/utils/prompts';
import { BASE_URL } from '@src/utils/constants';
import History from '../../components/history';

import Welcome from './welcome';
import { ChatScreen } from '../../components/chatScreen';
import Input from '../../components/Input';
import DashboardFrame from '@src/components/DashboardFrame';
import moment from 'moment';
import MobileNavbar from '../../components/mobileNavBar';
import Sidebar from '@src/components/Sidebar';
import { useNavigate } from 'react-router';
import { ChatContent, Chats, isChatContentArray } from '../../components/interface/interface';
import { Helmet } from 'react-helmet-async';
import { handleDeleteAllHistory, handleDeleteHistory } from '../../endpoints';
import { toast } from 'react-toastify';
import { HistoryTypeFormat } from '@src/core/interfaces/chat';
import FileUploader from './components/file_uploader';

const Chat = () => {
    const [socket, setSocket] = useState<any>();
    const [streaming, setStreaming] = useState(false);
    const [histories, setHistories] = useState<HistoryTypeFormat[]>([]);
    const currentEntity = useRef<Chats>({ role: 'user', content: '', type: 'gpt-4o' });
    const [chatList, setChatList] = useState<Chats[]>([]);
    const _chatsRef = useRef<Chats[]>([]);
    const [showMobileElements, setShowMobileElement] = useState({ showSidebar: false, showHistory: false });
    const [isMobile, setIsMobile] = useState(true);
    const [isTyping, setIsTyping] = useState(false);
    const StreamId = useRef<string>('');
    const historyId = useRef<string | number>();
    const token = localStorage.getItem('token');
    const [cardPrompt, setCardPrompt] = useState('');
    const [img, setImg] = useState<File | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [suggestedQuestions, setSuggestedQuestions] = useState<string[]>([]);

    const [showUploader, setShowUploader] = useState(false);

    const handlePaste = (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
        const items = event.clipboardData.items;
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if (item.type.startsWith('image/')) {
                const file = item.getAsFile();
                console.log('File', file);
                if (file) {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        setImg(file);
                    };
                    reader.readAsDataURL(file);
                }
                break; // Only handle the first image
            }
        }
    };

    const handleDragEnter = (e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setIsLoading(true); // Show the uploader when the drag enters
    };

    const handleDragLeave = (e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setIsLoading(false); // Hide the uploader when the drag leaves
    };

    const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const bottomRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (isTyping && chatList.length > 0) {
            // console.log("scrolling")
            bottomRef.current?.scrollTo({
                top: bottomRef.current?.scrollHeight,
                behavior: 'smooth',
            });
        }
    }, [isTyping, chatList]);

    useEffect(() => {
        const _socket = io(BASE_URL.slice(0, -2));
        setSocket(_socket);

        const activeChat = localStorage.getItem('activeChat');

        if (activeChat) {
            const parsed = JSON.parse(activeChat);
            setChatList(parsed);
            _chatsRef.current = parsed;
        }
    }, []);

    useEffect(() => {
        _chatsRef.current = chatList;
    }, [chatList]);

    useEffect(() => {
        if (!socket) return;
        socket.on('data', (data: string) => {
            !streaming && setStreaming(true);
            if (currentEntity.current.role !== 'assistant') {
                currentEntity.current = { role: 'assistant', content: data };
                setChatList((c) => [...c, { role: 'assistant', content: data }]);
                return;
            }

            const updateCallback = (current: Chats[]) => {
                const updated = current.map((item, i) => {
                    if (i === current.length - 1) {
                        return { ...item, content: item.content + data };
                    }
                    return item;
                });
                // console.log(updated);
                return updated;
            };

            setChatList(updateCallback);
        });

        socket.on('stream_end', async (data: { streamId: string; assistant: Chats[] }) => {
            setIsTyping(false);
            const { streamId, assistant } = data;
            if (streamId === StreamId.current) {
                setStreaming(false);
                const uuid = uuidv4();

                if (!historyId.current) {
                    historyId.current = uuid;
                }

                console.log(assistant);

                const response = await SaveHistory(token, {
                    conversation: assistant,
                    chat_id: historyId.current ?? uuid,
                });

                if (response?.statusCode === 1) {
                    localStorage.setItem('activeChat', JSON.stringify(_chatsRef.current));

                    await getHistory();
                }
            }
        });

        socket.on('suggested_questions', (suggestedQuestionData: string[]) => {
            console.log(suggestedQuestionData);
            setSuggestedQuestions(suggestedQuestionData);
        });
        return () => {
            socket.off('message');
        };
    }, [socket]);

    async function getHistory() {
        try {
            const data = await GetHistory(token);

            if (data?.statusCode === 1) {
                const _data = data?.data;
                const filtered: HistoryTypeFormat[] = _data?.map((d: any) => {
                    return {
                        date: d.date,
                        histories: d.histories?.map((history: any) => {
                            return [
                                ...history.conversation,
                                { chat_id: history?.chat_id },
                                { id: history._id },
                                { date: history.updatedAt },
                            ];
                        }),
                    };
                });

                console.log(filtered);

                setHistories(filtered);
            } else if (!data || data?.statusCode === 0) {
                console.log('No history');
                // alert('hdhdhd');
                setHistories([]);
                // notification.error({ message: data?.message || 'You are not connected.' });
            }
        } catch (error) {
            console.log(error);
            setHistories([]);
        }
    }

    useEffect(() => {
        getHistory();
    }, []);

    function setClickedHistory(id: string) {
        // let filterHistory: any = histories
        //     .filter((h: any) => {
        //         const { id: history_id } = h[h.length - 2];
        //         return String(history_id) === String(id);
        //     })
        //     .flat();
        // historyId.current = filterHistory.find((d: any) => d.chat_id)?.chat_id;
        // filterHistory = filterHistory.filter((h: any) => h?.role && h?.content);
        // setChatList(filterHistory);
        // localStorage.setItem('activeChat', JSON.stringify(filterHistory));
        let filterHistory: any = histories
            .flatMap((historyFormat) => historyFormat.histories)
            .filter((history) => {
                const chatIdObj = history.find((h: any) => h.id === id);
                return chatIdObj !== undefined;
            })
            .flat();

        // historyId.current = filterHistory.find((h: any) => h.chat_id)?.chat_id;
        filterHistory = filterHistory.filter((h: any) => h?.role && (h?.content || h?.new_content));
        // setChatList(filterHistory);
        const modifiedConversation = filterHistory.map((item: any) => {
            return {
                ...item, // Copy all existing properties of the item
                content: item.content || (item.new_content.length > 0 ? item.new_content : item.content), // Use `new_content` if `content` is empty
                new_content: item.content ? item.new_content : [], // Clear `new_content` if it was moved to `content`
            };
        });

        // Set the chat list and save the updated history to localStorage
        setChatList(modifiedConversation);
        localStorage.setItem('activeChat', JSON.stringify(filterHistory));
    }

    function handleChat(chat: any) {
        setSuggestedQuestions([]);
        setIsTyping(true);
        const uuid = uuidv4();
        StreamId.current = uuid;

        socket?.emit(
            'data',
            {
                data: {
                    messages: [
                        { role: 'system', content: cardPrompt ? prompts[cardPrompt] : prompts.chat, type: 'gpt-4o' },
                        ...chat,
                    ],
                },
                streamId: uuid,
            },
            true,
            true,
        );
    }

    function howToGuide(chat: any) {
        const uuid = uuidv4();
        StreamId.current = uuid;

        let promptMessage = [{ role: 'system', content: prompts.howToGuide, type: 'gpt-4' }, ...chat];

        socket?.emit('data', {
            data: {
                messages: promptMessage,
            },
            streamId: uuid,
        });
    }

    function topicExploration(chat: any) {
        const uuid = uuidv4();
        StreamId.current = uuid;

        let promptMessage = [{ role: 'system', content: prompts.exploration, type: 'gpt-4' }, ...chat];

        socket?.emit('data', {
            data: {
                messages: promptMessage,
            },
            streamId: uuid,
        });
    }

    function exitTicketGenerator(chat: any) {
        const uuid = uuidv4();
        StreamId.current = uuid;

        let promptMessage = [{ role: 'system', content: prompts.exit_ticket, type: 'gpt-4' }, ...chat];

        socket?.emit('data', {
            data: {
                messages: promptMessage,
            },
            streamId: uuid,
        });
    }

    function lessonHooks(chat: any) {
        const uuid = uuidv4();
        StreamId.current = uuid;

        let promptMessage = [{ role: 'system', content: prompts.lessonHooks, type: 'gpt-4' }, ...chat];
        // console.log(promptMessage.length);

        socket?.emit('data', {
            data: {
                messages: promptMessage,
            },
            streamId: uuid,
        });
    }

    function handleAction(action: string, index: number) {
        if (action === 'regenerate') {
            const selected_chat = chatList[index];
            if (selected_chat.role !== 'assistant') return;

            setChatList((current) => {
                const sliced = current.slice(0, index);
                return sliced;
            });

            _chatsRef.current = _chatsRef.current.slice(0, index);
            currentEntity.current = { role: 'user', content: '' };
            handleChat(_chatsRef.current);
        }
    }

    useEffect(() => {
        if (window.innerWidth >= 820) {
            setIsMobile(false);
        }
    }, [window.innerWidth]);

    const navigate = useNavigate();

    const handleClickChatRoute = () => {
        let length = histories.length;
        let historyLength = histories[length - 1].histories.length;
        const historyToFilter: Chats[] = histories[length - 1].histories[historyLength - 1];
        const finalHistory = historyToFilter.filter(chatFilter);
        setChatList(finalHistory);
        console.log(finalHistory);
    };

    const chatFilter = (chat: Chats): boolean => {
        return chat.content != undefined && (typeof chat.content === 'string' || isChatContentArray(chat.content));
    };

    const onHandleButtonClick = (type: string) => {
        currentEntity.current = { role: 'user', content: 'hello' };
        let chat = [...chatList, { role: 'user', content: 'hello', type: 'gpt-4' }];
        setChatList(chat);
        _chatsRef.current = chat;
        if (type === 'hooks') {
            setIsTyping(true);
            setCardPrompt('lessonHooks');
            setTimeout(() => {
                lessonHooks(chat);
            }, 1000);
        } else if (type === 'how_to') {
            setIsTyping(true);
            setCardPrompt('howToGuide');
            setTimeout(() => {
                howToGuide(chat);
            }, 1000);
        } else if (type === 'explore') {
            setIsTyping(true);
            setCardPrompt('exploration');
            setTimeout(() => {
                topicExploration(chat);
            }, 1000);
        } else if (type === 'exit_ticket') {
            setIsTyping(true);
            setCardPrompt('exit_ticket');
            setTimeout(() => {
                exitTicketGenerator(chat);
            }, 1000);
        }
    };

    const handleDeleteContentHistory = async (id: string) => {
        await handleDeleteHistory(id, 'chats');
        await getHistory();
        toast.success('Chat deleted successfully');
    };

    const handleDeleteAllChatHistory = async () => {
        await handleDeleteAllHistory('chats');
        await getHistory();
        toast.success('Chat cleared successfully');
    };

    return (
        <div
            className={`${isLoading ? 'w-full h-full mt-[20%] flex self-center flex-col justify-center items-center' : ''}`}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
        >
            {isLoading ? (
                <div>
                    {' '}
                    <FileUploader
                        uploadedFile={img}
                        setUploadedFile={setImg}
                        loading={isLoading}
                        setLoading={setIsLoading}
                    />{' '}
                </div>
            ) : (
                <DashboardFrame
                    canNavigateHome={true}
                    showSidebar={!isMobile}
                    showHistory={!isMobile}
                    showTop={!isMobile}
                    history={histories}
                    selectedHistory={(v: string) => {
                        setClickedHistory(v);
                    }}
                    onClickPage={handleClickChatRoute}
                    gmindTyping={isTyping}
                    onDeleteHistory={(id: string) => {
                        handleDeleteContentHistory(id);
                    }}
                    onDeleteAllHistory={() => handleDeleteAllChatHistory()}
                    showHistoryOption={true}
                >
                    <Sidebar
                        onMobileClose={() => setShowMobileElement((c) => ({ ...c, showSidebar: false }))}
                        style={{
                            position: 'fixed',
                            transform: showMobileElements.showSidebar ? 'translateX(0)' : 'translateX(-20rem)',
                            transition: 'all 0.2s ease',
                        }}
                        isMobile
                        className="top-0 bottom-0 z-[1000] left-0 w-64 h-[100vh!important]"
                        selected={(v) => {
                            navigate(`/dashboard/${v}`);
                        }}
                    />

                    {isMobile && (
                        <History
                            isMobile={isMobile}
                            onClose={() => {
                                setShowMobileElement((c) => ({ ...c, showHistory: false }));
                            }}
                            data={[]}
                            className="transition-all w-64 z-[1000]"
                            click={(v) => {}}
                            style={{
                                transform: showMobileElements.showHistory ? 'translateX(0)' : 'translateX(20rem)',
                                position: isMobile ? 'fixed' : 'relative',
                                top: '0',
                                right: '0',
                                width: '16rem',
                                bottom: '0',
                            }}
                        />
                    )}
                    <section className="w-full relative bg-custom-opacity flex flex-col h-[90vh] chat_con">
                        <Helmet>
                            <title>Chat</title>
                            <meta
                                title="GMIND Ai: Best AI Assistant for TEACHERS and CONTENT CREATORS"
                                name="description"
                                content="Chat with Gmind AI account to generate exceptional educational content."
                            />
                            <link rel="canonical" href="https://gmind.ai/pricing" />
                            <meta name="keywords" content="gmind, ai, chat" />
                        </Helmet>
                        <section className="xl:flex w-full overflow-hidden  2xl:mx-auto">
                            <div className="flex flex-col w-full">
                                <div ref={bottomRef} className="w-full overflow-auto left-0 right-0 top-0 h-[80vh]">
                                    {chatList.length ? (
                                        <ChatScreen
                                            isMobile={isMobile}
                                            actions={(v, i) => {
                                                handleAction(v, i);
                                                const selectedChat = chatList[i];
                                            }}
                                            streaming={streaming}
                                            backClick={() => {
                                                setCardPrompt('');
                                                setChatList([]);
                                                localStorage.removeItem('activeChat');
                                                historyId.current = undefined;
                                            }}
                                            chatList={chatList}
                                            showNavBar={!isMobile}
                                            typing={isTyping}
                                            suggestions={suggestedQuestions}
                                            handleSuggestionClick={(question) => {
                                                currentEntity.current = { role: 'user', content: question };
                                                setChatList([
                                                    ...chatList,
                                                    { role: 'user', content: question, type: 'gpt-4' },
                                                ]);
                                                _chatsRef.current = [...chatList, { role: 'user', content: question }];
                                                handleChat([
                                                    ...chatList,
                                                    { role: 'user', content: question, type: 'gpt-4' },
                                                ]);
                                            }}
                                        />
                                    ) : (
                                        <Welcome onclick={onHandleButtonClick} />
                                    )}
                                </div>
                                <div
                                    style={
                                        isMobile
                                            ? {
                                                  position: 'fixed',
                                                  bottom: '0',
                                                  left: '0',
                                                  right: '0',
                                                  paddingBottom: '1rem',
                                              }
                                            : { position: 'absolute', bottom: '0', left: '0', right: '0' }
                                    }
                                    className="bg-transparent mx-auto pb-5 max-lg:pb-8 max-w-[56rem]  z-[100] max-lg:basis-0 px-10"
                                >
                                    <Input
                                        onPaste={handlePaste}
                                        img={img}
                                        setImg={setImg}
                                        showLeftField={false}
                                        value={(v: string, image: string[]) => {
                                            let contentValue: ChatContent[] = [
                                                {
                                                    type: 'text',
                                                    text: v,
                                                },
                                            ];
                                            if (image.length !== 0) {
                                                for (let i = 0; i < image.length; i++) {
                                                    contentValue.push({
                                                        type: 'image_url',
                                                        image_url: {
                                                            url: image[i],
                                                        },
                                                    });
                                                }
                                            }

                                            currentEntity.current = { role: 'user', content: contentValue };
                                            setChatList([
                                                ...chatList,
                                                { role: 'user', content: contentValue, type: 'gpt-4' },
                                            ]);
                                            _chatsRef.current = [...chatList, { role: 'user', content: contentValue }];
                                            handleChat([
                                                ...chatList,
                                                { role: 'user', content: contentValue, type: 'gpt-4' },
                                            ]);
                                        }}
                                    />
                                </div>
                            </div>
                        </section>
                    </section>
                </DashboardFrame>
            )}
        </div>
    );
};

export default Chat;

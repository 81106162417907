/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import DashboardFrame from '@src/components/DashboardFrame';
import TransactionTable from '../components/transaction_table';
import { Input, Menu, Select, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import '../components/TeacherTable.scss';
import { useNavigate } from 'react-router';
import { ReferralInterface, TransactionsInterface } from '../interfaces';
import { getAllReferrals, getAllTransactions } from '../apis';

const Transactions = () => {
    const [histories, setHistories] = useState<any[]>([]);
    const [isMobile, setIsMobile] = useState(true);
    const navigate = useNavigate();
    const [transactions, setTransactions] = useState<TransactionsInterface[]>([]);

    useEffect(() => {
        if ( window.innerWidth >= 1024 ) {
            setIsMobile(false);
        }
    }, [window.innerWidth]);


    const dataSource = [
        {
            key: '1',
            id: 'T001',
            name: 'John Doe',
            email: 'john.doe@example.com',
            status: 'active',
        },
        {
            key: '2',
            id: 'T002',
            name: 'Jane Smith',
            email: 'jane.smith@example.com',
            status: 'inactive',
        },
    ];

    function handleOpenEditor(id?: string) {
        const filterHistory = histories
            .filter((h: any) => {
                const { id: history_id } = h[h.length - 1];
                return String(history_id) === String(id);
            })
            .flat()
            .filter((h: any) => !h?.id);

        console.log(filterHistory);
    }
    const menu = (
        <Menu>
          <Menu.Item key="1">Option 1</Menu.Item>
          <Menu.Item key="2">Option 2</Menu.Item>
          <Menu.Item key="3">Option 3</Menu.Item>
        </Menu>
    );

    const fetchData = async () => {

        try {
            let response = await getAllTransactions();

            // console.log(response);
    
            if ( response.success ){
                let values = response.data;
                setTransactions(values)

                return;
            }  

            setTransactions([])
        } catch (error) {
            setTransactions([]) 
        }        
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <DashboardFrame
        menu={menu}
            showHistory={!isMobile}
            showSidebar={!isMobile}
            showRightComponent={false}
            mobileNavStyle={isMobile ? { position: 'fixed' } : {}}
            history={histories}
            schoolAdminPageName="Transactions"
            showTop={!isMobile}
            showSchoolHeader={true}
            selectedHistory={(v) => {
                handleOpenEditor(v);
            }}
            showPagePath={false}
            onDeleteHistory={(id: string) => {}}
            canNavigateHome={true}
        >
            <div className="bg-[#F9FAFC] w-full h-full pr-[36px] pl-[36px] pt-16 lg:pt-[32px] ">
                <div className="flex flex-col lg:flex-row gap-5 items-center pr-[28px] pl-[28px] bg-white justify-between w-full h-[80px] mb-4">
                    <span className="text-xl font-medium leading-8 text-left">All Transactions</span>
                    <button
                        onClick={() => {}}
                        className="w-[175px] h-[44px] bg-customOrange rounded-md p-[10px] gap-[8px] flex flex-row items-center justify-center text-white font-Poppins text-base font-normal leading-6 text-left"
                    >
                        Download CSV
                    </button>
                </div>

                <FilterAndSearch />

                

                <div className="w-full h-auto lg:h-full bg-white p-[24px] mt-[8px] overflow-x-scroll">
                    <TransactionTable transactions ={transactions} />
                </div>
            </div>
        </DashboardFrame>
    );
};

export default Transactions;


const FilterIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.9328 15.8885C11.9328 16.3969 11.5995 17.0635 11.1745 17.3219L9.99948 18.0802C8.90781 18.7552 7.39114 17.9969 7.39114 16.6469V12.1885C7.39114 11.5969 7.05781 10.8385 6.71615 10.4219L3.51612 7.0552C3.09112 6.6302 2.75781 5.88021 2.75781 5.37188V3.43854C2.75781 2.4302 3.51616 1.67188 4.44116 1.67188H15.5578C16.4828 1.67188 17.2411 2.4302 17.2411 3.3552V5.2052C17.2411 5.8802 16.8161 6.72187 16.3995 7.13854"
            stroke="#E55109"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M13.3932 13.763C14.866 13.763 16.0599 12.5691 16.0599 11.0964C16.0599 9.62361 14.866 8.42969 13.3932 8.42969C11.9205 8.42969 10.7266 9.62361 10.7266 11.0964C10.7266 12.5691 11.9205 13.763 13.3932 13.763Z"
            stroke="#E55109"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M16.5599 14.263L15.7266 13.4297"
            stroke="#E55109"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const FilterAndSearch = () => {
    return (
        <div className="flex flex-col lg:flex-row gap-y-5 justify-between items-center p-4">
            <Select
                placeholder={
                    <div className="flex flex-row gap-2 items-center text-[#E55109]">
                        <FilterIcon /> Search
                    </div>
                }
                className="w-[176px] h-[42px]"
                options={[
                    { value: 'option1', label: 'Option 1' },
                    { value: 'option2', label: 'Option 2' },
                ]}
            />

            <Space direction="horizontal" size="middle">
                <Input
                    placeholder="Search"
                    suffix={<SearchOutlined style={{ color: '#E55109' }} />}
                    className="w-[336px] h-[42px] custom-input"
                />
            </Space>
        </div>
    );
};

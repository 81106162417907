import { Form, Input } from "antd";
import { SyllabusInputType, SyllabusType } from "../interfaces";
import BaseForm from "./base_form";
import { useEffect, useState } from "react";
import addCarbon from "@icons/carbon_add.svg";
import icBaselineMinus from "@icons/ic_baseline-minus.svg";
import { toast } from "react-toastify";
import AIGenerate from "@icons/generate.svg"
import { io, Socket } from "socket.io-client";
import { prompts } from "@src/utils/prompts";
import { onGenerateWithAI } from "../functions";
import { ChatType } from "@src/pages/dashboard/endpoints";
import { curriculumOptions } from "../constants";
import { BASE_URL } from "@src/utils/constants";

const SyllabusScreen = ({className, onGenerate, onSetTopic}: SyllabusType) => {
    // comment fir syllabus screen
    // 0 - form page
    // 1 - syllabus advance form
    
    const [isMobile, setIsMobile] = useState(true);
    const [basePrompt, setBasePrompt] = useState('');
    const [subject, setCurrentSubject] = useState('');
    const [description, setDescription] = useState('');
    const [objective, setObjective] = useState('');
    const [materials, setMaterials] = useState('');
    const [courseOutline, setCourseOutline] = useState('');
    const [additionalDetails, setAdditionalDetails] = useState('');
    const [showAdditional, setShowAdditional] = useState(true);
    const [sockets, setSockets] = useState<{ [key: string]: Socket | null}>({});
    const [visible, setVisibility] = useState<boolean>(true);
    const [objectiveVisible, setObjectiveVisibility] = useState<boolean>(true);
    const [materialsVisible, setMaterialVisibility] = useState<boolean>(true);
    const [outlineVisible, setOutlineVisibility] = useState<boolean>(true);
    const [detailsVisible, setDetailsVisibility] = useState<boolean>(true);
    const [inputTyping, setInputTyping] = useState<{[key: string]: boolean}>({})
    const baseurl = BASE_URL;


    useEffect(() => {
        if (window.innerWidth >= 820) {
            setIsMobile(false);
        }
    }, [window.innerWidth]);

    const inputValues: Array<SyllabusInputType> = [
        {
            showAI: visible,
            value: description,
            name: "description",
            label: "Course Overview*",
            additionalInput: false,
            onChange: (e) => {
                setDescription(e)
            },
            placeholder: "What is the course overview?\ne.g., This course will cover the British curriculum",
            onGenrate: () => {
                let prompt = `Generate a short course description for the syllabus for ${subject}`;
                console.log(prompt);
                const socket = sockets["description"];
                onGenerateWithAI(socket, prompt);
            },
            setData: setDescription,
            setVisible: setVisibility,
        },

        {
            showAI: objectiveVisible,
            value: objective,
            name: "objective",
            label: "Training Objective*",
            additionalInput: false,
            onChange: (e) => {
                setObjective(e)
            },
            placeholder: "What are the course objective?\ne.g., By the end of this course, students will be able to...",
            onGenrate: () => {
                let prompt = `Generate a short course objective for the syllabus for ${subject}`;
                const socket = sockets["objective"];
                onGenerateWithAI(socket, prompt);
            },
            setData: setObjective,
            setVisible: setObjectiveVisibility,
        },
        {
            showAI: materialsVisible,
            value: materials,
            name: "materials",
            label: "Required Materials*",
            additionalInput: false,
            onChange: (e) => {
                setMaterials(e)
            },
            placeholder: "List materials needed for the course\ne.g. British curriculum, Calculator, etc.",
            onGenrate: () => {
                let prompt = `Generate a list of required materials for the syllabus for ${subject}`;
                const socket = sockets["materials"];
                onGenerateWithAI(socket, prompt);  
            },
            setData: setMaterials,
            setVisible: setMaterialVisibility,
        },
        {
            showAI: outlineVisible,
            value: courseOutline,
            name: "outline",
            label: "Course Outline*",
            additionalInput: true,
            onChange: (e) => {
                setCourseOutline(e)
            },
            placeholder: "What is the course overview?\ne.g., This course will cover the British curriculum",
            onGenrate: () => {
                let prompt = `Generate a short course outline for the syllabus for ${subject}`;
                const socket = sockets["outline"];
                onGenerateWithAI(socket, prompt);
            },
            setData: setCourseOutline, 
            setVisible: setOutlineVisibility,
        },
        {
            showAI: detailsVisible,
            value: additionalDetails,
            name: "details",
            label: "Other Details",
            additionalInput: true,
            onChange: (e) => {
                setAdditionalDetails(e)
            },
            placeholder: "What is the course overview?\ne.g., This course will cover the British curriculum", 
            onGenrate: () => {
                let prompt = `Generate other details for the syllabus for ${subject}`;
                const socket = sockets["details"];
                onGenerateWithAI(socket, prompt);
            },
            setData: setAdditionalDetails,
            setVisible: setDetailsVisibility,
        }
    ];

    

    useEffect(() => {
        const newSockets: { [key: string]: Socket } = {};
        const newTyping: { [key: string]: boolean } = {};
        inputValues.forEach(item => {
            newSockets[item.name] = io(baseurl.slice(0, -2));
            newTyping[item.name]= false;
            setInputTyping(newTyping);
        });
        setSockets(newSockets);

        return () => {
            Object.values(newSockets).forEach(socket => {
                socket.close();
            });
        };
    }, []);

    const setTyping = (key: string, value:boolean) => {
        setInputTyping((prevData) => ({
            ...prevData,
            [key]: value,
        }))
    }

    

    useEffect(() => {
        inputValues.forEach(item => {
            const socket = sockets[item.name];
            if (!socket) return;

            const handleData = (data: string) => {
                item.setData((response) => response + data);
            };

            const handleStreamEnd = () => {
                setTyping(item.name, false);
            };

            socket.on('data', handleData);
            socket.on('stream_end', handleStreamEnd);
            return () => {
                socket.off('data', handleData);
                socket.off('stream_end', handleData);
            };
        });
    }, [sockets]);


    const generate = () => {
        // check if properties are passed
        if (  !description || !objective || !materials  ){
            toast.error(`Kindly enter details for ${subject} standard syllabus`);
            return;
        }

        let outline: string = courseOutline ? `\nCourse Outline: ${courseOutline}`: '';
        let details: string = additionalDetails ? `\nAdditional Details: ${additionalDetails}`: '';

        let additionalinfo = outline + details 


        let promptToGenerate = `Create a Standard Syallabus for ${subject} using this details\nDescription: ${description}\nObjective: ${objective}\nMaterials: ${materials}${additionalinfo} with the following base info ${basePrompt}. Kindly ensure all the details sent is shown in the response. Also ensure that the response is grade level appropriate and strictly follows the requested curriculum.`

     
        onGenerate(promptToGenerate, 'syllabus');
    }

    const hasTrueValue: boolean = Object.keys(inputTyping).some((key: string) => inputTyping[key]);
    
    return (
        <div className={`${className} px-2 md:px-[1rem] w-full flex flex-col gap-y-3`}>
            {subject == '' && <div className={`${className}`}>
                <div className="flex flex-col gap-y-3 w-full md:w-[70%]">
                    <h2 className="font-medium text-[2rem] md:text-[2.75rem] text-[var(--gmind-black)]">Syllabus Generator</h2>
                    <p className="text-[0.875rem] text-[var(--gmind-white60)] text-normal">Provide us the information requested below to get your syllabus ready.</p>
                </div>
                <BaseForm firstText="Curriculum" dropDownOptions={curriculumOptions} onSubmit={(baseValue) => {
                    setCurrentSubject(baseValue.subject);
                    onSetTopic(baseValue.subject);
                    setBasePrompt(baseValue.basePrompt);
                }} />
            </div>}
            {
                subject != '' && 
                <div className="flex flex-col gap-y-3 w-full">
                    {/* <div className="items-center flex gap-x-3">
                        <p className="font-semibold text-[0.9rem] text-[var(--gmind-light-black)]">{subject}</p>
                        <button className="p-1 rounded-[0.25rem] text-[var(--gmind-orange)] text-[0.9rem]" style={
                            {
                                border: "1px solid var(--gmind-orange)"
                            }
                        }>Syllabus Generator</button>
                    </div> */}

                   
                    <h2 className="whitespace-normal font-medium text-[2rem] text-[2.75rem] text-[var(--gmind-black)]" style={{lineHeight: "2.8rem"}}>Create a standard syllabus</h2>
                    <p className="text-[0.875rem] text-[var(--gmind-white60)] text-normal">Provide details about the course, including objectives, materials, and additional information.</p>
                 
                    
                    
                    
                    <Form onFinish={generate} className="flex flex-col md:flex-row gap-y-3 gap-x-3" layout="vertical">
                        <div className="flex flex-col gap-y-2 w-full md:w-[45%]">
                            {
                                inputValues.map((item, index) => (
                                    !item.additionalInput &&
                                    <Form.Item
                                        key={index}
                                        className="w-full"
                                        name={item.name}
                                        label={<label className="font-normal text-[0.9rem] text-[var(--gmind-light-black)]">{item.label}</label>}
                                        rules={[
                                            {
                                                message: 'field is required',
                                                required: false,
                                            },
                                        ]}
                                    >

                                    <div className="relative">
                                        <Input.TextArea
                                            className="rounded-[0.25rem] bg-transparent hover:bg-transparent placeholder-gray-700"
                                            autoSize={{ minRows: 4}}
                                            style={{border: "1px solid gray"}}
                                            value={item.value}
                                            onChange={ (e) =>  item.onChange(e.target.value) } 
                                            placeholder={item.placeholder}
                                        />

                                        <div onClick={() => {item.onGenrate(); item.setVisible(false); setTyping(item.name, true);}} className={`${item.showAI ? '': 'hidden'} cursor-pointer shadow-md rounded-[1rem] absolute top-[55px] right-[10px] py-[0.3rem] px-[1rem] bg-white text-normal text-[0.75rem] text-[var(--gmind-black)] flex gap-x-2`}>
                                            <img src={AIGenerate} alt="" />
                                            <span>Use Gmind AI</span>
                                        </div>
                                    </div>
                                    
                                    </Form.Item>
                                ))
                            }
                            {/* <div onClick={() => setShowAdditional(!showAdditional)} className="w-full flex items-center justify-between p-2 bg-[var(--gmind-orange15)]" style={{border: "1px solid var(--gmind-orange)"}}>
                                <h2 className="text-[0.9rem] font-normal text-[var(--gmind-orange)]">{`${showAdditional ? 'Hide Advanced Inputs' :'Show Advanced Inputs'}`}</h2>
                                <img src={showAdditional ? icBaselineMinus :addCarbon} alt={`${showAdditional ? 'ic baseline minus': 'addCarbon'}`} />
                            </div> */}

                            { !showAdditional && <button type="submit" className="w-[11rem] p-3 rounded-[0.375rem] text-white bg-[var(--gmind-orange)]">
                                Generate Syllabus
                            </button> }
                        </div>

                        <div className="w-full md:w-[50%] flex flex-col gap-y-3 px-3 py-3">
                            {
                                showAdditional && (
                                    <div className="border-[2px] border-dashed border-[var(--gmind-orange)] rounded-[0.5rem] px-5 py-3 flex flex-col">
                                        <h2 className="mb-5 self-center hidden md:flex text-center font-medium text-[1.5rem]">Advance Options</h2>
                                        {
                                            inputValues.map((item, index) => (
                                                item.additionalInput && <Form.Item
                                                    key={index}
                                                    className="w-full"
                                                    name={item.name}
                                                    label={<label className="font-normal text-[0.9rem] text-[var(--gmind-light-black)]">{item.label}</label>}
                                                >
            
                                                <div className="relative">
                                                    <Input.TextArea
                                                        className="rounded-[0.25rem] bg-transparent hover:bg-transparent placeholder-gray-700"
                                                        autoSize={{ minRows: 4}}
                                                        style={{border: "1px solid gray"}}
                                                        value={item.value}
                                                        onChange={ (e) =>  item.onChange(e.target.value) } 
                                                        placeholder={item.placeholder}
                                                    />
            
                                                    { 
                                                        item.showAI && ( <div onClick={() => { item.onGenrate(); item.setVisible(false); setTyping(item.name, true);}} className="cursor-pointer shadow-md rounded-[1rem] absolute top-[55px] right-[15px] py-[0.3rem] px-[1rem] bg-white text-normal text-[0.75rem] text-[var(--gmind-black)] flex gap-x-2">
                                                        <img src={AIGenerate} alt="" />
                                                        <span>Use Gmind AI</span>
                                                    </div>)}
                                                </div>
                                                
                                                </Form.Item>
                                            ))
                                        }
                                    </div>
                                )
                            }
                            { showAdditional && <button type="submit" className={`${ isMobile ? 'self-start': 'self-end'} w-[11rem] p-3 rounded-[0.375rem] text-white bg-[var(--gmind-orange)]`}>
                                    Generate Syllabus
                                </button>
                            }
                        </div>
                    </Form>
                   
                </div>
            }
        </div>
    );
}


export default SyllabusScreen;
import {  Col, Form, Input, Row } from 'antd';
import { useEffect, useState } from 'react';
import './style.scss';
import { toast } from 'react-toastify';

interface CopywritingPlanInterface {
  onGenerateCopy: (v: string) => void;
  setTopic: (e: string) => void;
}

const CopywritingPlanTemplate = ({ onGenerateCopy, setTopic  }: CopywritingPlanInterface) => {
  const [form] = Form.useForm();
  const [isPageOne, setIsPageOne] = useState(true); // Control page visibility

  const [pageOneValues, setPageOneValues] = useState<{[key: string]: string}>({
    'projectName': '',
    'objective': '',
    'noOfWords': '',
  });

  useEffect(() => {
    console.log(pageOneValues);
  }, [pageOneValues])

  const onFinish = (values: any) => {
    let promptMessage = '';

    const projectName = pageOneValues['projectName'];
    const objective = pageOneValues['objective'];
    const numberOfWords = pageOneValues['noOfWords'];
    const headline = values.headline;
    const valueProposition = values.valueProposition;
    const offer = values.offer;
    const coreMessage = values.coreMessage;
    const cta = values.cta;

    // Validate if all required fields are filled
    if (
      !projectName ||
      !objective ||
      !numberOfWords ||
      !headline ||
      !valueProposition ||
      !offer ||
      !coreMessage ||
      !cta
    ) {
      toast.error('Please fill all the required fields before generating.');
      return;
    }

    // Create a structured prompt
    let informationData = `
      Project Name: ${projectName}
      Objective: ${objective}
      Number of Words: ${numberOfWords}
      Headline: ${headline}
      Value Proposition: ${valueProposition}
      Offer: ${offer}
      Core Message: ${coreMessage}
      Call to Action: ${cta}
    `;

    promptMessage = `Generate a copy based on the following details: ${informationData}. Kindly ensure to follow the details provided`;
    onGenerateCopy(promptMessage);
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      initialValues={{
        projectName: '',
        objective: '',
        numberOfWords: '',
        headline: '',
        valueProposition: '',
        offer: '',
        coreMessage: '',
        cta: '',
      }}
    >
      <h1 className="text-xl font-bold font-Poppins mb-[16px] mt-[16px]">Copywriting Tool </h1>
      <p className="text-md font-Poppins mb-[16px]">
       Create a comprehensive copywriting plan for your project.
      </p>

      {/* Page 1 Inputs */}
      {isPageOne && (
        <>
          <div className='grid grid-cols-1  md:grid-cols-2 gap-4'>
            <div>
              <Form.Item
                label="Project Name"
                name="projectName"
                rules={[{ required: true, message: 'Project Name is required' }]}
              >
                <Input placeholder="Enter project name" />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                label="Objective"
                name="objective"
                rules={[{ required: true, message: 'Objective is required' }]}
              >
                <Input placeholder="What is the main objective of this copy?" />
              </Form.Item>
            </div>
          </div>

          <div className='grid grid-cols-1  md:grid-cols-2 gap-4'>
            <div>
              <Form.Item
                label="Number of Words"
                name="numberOfWords"
                rules={[{ required: true, message: 'Number of Words is required' }]}
              >
                <Input placeholder="Enter number of words" />
              </Form.Item>
            </div>
          </div>

          {/* Next Button */}
          <Row gutter={16}>
            <Col span={24}>
              <button
                onClick={() => form.validateFields().then(() => {
                  setPageOneValues({
                    'projectName': form.getFieldValue('projectName'),
                    'objective': form.getFieldValue('objective'),
                    'noOfWords': form.getFieldValue('numberOfWords'),
                  });
                  setIsPageOne(false);
                })}
                className="w-full md:w-[8rem] bg-customOrange text-white rounded p-2"
              >
                Next
              </button>
            </Col>
          </Row>
        </>
      )}

      {/* Page 2 Inputs */}
      {!isPageOne && (
        <>
          <div className='grid grid-cols-1  md:grid-cols-2 gap-4'>
            <div>
              <Form.Item
                label="Headline"
                name="headline"
                rules={[{ required: true, message: 'Headline is required' }]}
              >
                <Input placeholder="Clear and compelling to grab attention" />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                label="Value Proposition"
                name="valueProposition"
                rules={[{ required: true, message: 'Value Proposition is required' }]}
              >
                <Input placeholder="Explain the unique benefits and advantages" />
              </Form.Item>
            </div>
          </div>

          <div className='grid grid-cols-1  md:grid-cols-2 gap-4'>
            <div>
              <Form.Item
                label="Offer"
                name="offer"
                rules={[{ required: true, message: 'Offer is required' }]}
              >
                <Input placeholder="Specify the promotion or deal" />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                label="Core Message"
                name="coreMessage"
                rules={[{ required: true, message: 'Core Message is required' }]}
              >
                <Input placeholder="Define the primary message" />
              </Form.Item>
            </div>
          </div>

          <div  className='grid grid-cols-1  md:grid-cols-2 gap-4'>
            <div>
              <Form.Item
                label="Call to Action (CTA)"
                name="cta"
                rules={[{ required: true, message: 'CTA is required' }]}
              >
                <Input placeholder="Specify the action you want the audience to take" />
              </Form.Item>
            </div>
          </div>

          {/* Submit and Previous Buttons */}
          <div className='flex flex-row justify-between'>
            <div>
              <button
                onClick={() => setIsPageOne(true)}
                className="w-full md:w-[8rem] bg-transparent border border-customOrange text-customOrange rounded p-2"
              >
                Prev
              </button>
            </div>
            <div>
              <button
                type="submit"
                className="no-hover-effect mt-2 w-full md:w-[8rem] py-2 px-4 bg-customOrange text-white rounded-lg"
              >
                Generate
              </button>
            </div>
          </div>
        </>
      )}
    </Form>
  );
};

export default CopywritingPlanTemplate;

import useImageStore from '@src/providers/useImageStore';
import { Input, Select } from 'antd';
import React, { useState } from 'react';
import { BiMicrophone } from 'react-icons/bi';
import { Spinner } from '../../pages/slide/component/Forms/first-form';
import useFormStore from '@src/providers/formStore';
import { toast } from 'react-toastify';
const { Option } = Select;

type Props = {};

function FirstScreen({}: Props) {
    const nextStep = useFormStore((state) => state.nextStep);
    const [selectedSize, setSelectedSize] = useState<string>('1024x1024');

    const [imageSubject, setImageSubject] = useState<string>('');
    const [imageBg, setImageBg] = useState<string>('');
    const [imageStyle, setImageStyle] = useState<string>('');
    const [imageColor, setImageColor] = useState<string>('');

    const setImageType = useImageStore((state) => state.setImageType);
    const { prompt, setPrompt } = useImageStore();
    let loading = false;

    const [imageType, setImageTypeState] = useState<'' | 'avatars' | 'anime' | 'realistic' | 'cartoon' | 'abstract'>(
        '',
    );
    const [inputPrompt, setInputPrompt] = useState(prompt);
    const handleMicrophoneClick = () => {
        console.log('Microphone clicked!');
    };
    const setSize = useImageStore((state) => state.setSize);

    const handleSizeSelect = (size: string) => {
        setSize(size);
        setSelectedSize(size);
    };
    const handleGenerateImage = () => {
        if ( !imageSubject ){
            toast.error("Kindly enter the image description");
            return;
        }

        const backgroundSettings = imageBg ? ` in ${imageBg}`: '';
        const imageStyleValue = imageStyle ? ` with ${imageStyle}`: '';
        const colorStyleValue = imageColor ? ` and ${imageColor}`: '';
        
        const newPrompt = `Generate an image that matches the description: ${imageSubject}${backgroundSettings}${imageStyleValue}${colorStyleValue}.`;
        setInputPrompt(newPrompt);
        setPrompt(newPrompt);
        handleNext();
    };
    function selectChange(value: any) {
        setImageTypeState(value);
        setImageType(value as 'avatars' | 'anime' | 'realistic' | 'cartoon' | 'abstract');
    }
    function handleNext() {
        nextStep();
    }
    const imageSizeOptions = [
        { value: '1024x1024', label: '1024x1024' },
        { value: '1792x1024', label: '1792x1024' },
        { value: '1024x1792', label: '1024x1792' },
    ];
    return (
        <div className="md:max-w-[80%] md:mt-[2vh] md:mb-[2vh]">
            <h1 className="font-[600] text-[20px] md:text-[30px] md:max-w-[90%]">Unlock your Creativity with Image Maker</h1>
            <p className="text-gray-500 font-[400] text-[14px] mb-3">
                Provide us the information below to create an awesome image.
            </p>
            <p className="text-[#E55109] font-[400] text-[14px] mb-2">
                Please select a size to create your awesome image.
            </p>
            <div className="flex md:flex-row flex-wrap gap-4 mb-4">
                {imageSizeOptions.map((option) => (
                    <button
                        key={option.value}
                        className={`text-sm py-2 px-3 rounded-md  ${selectedSize === option.value ? 'bg-[#E55109] text-white' : 'bg-white text-black hover:bg-[#E55109] hover:text-white'}`}
                        style={{ minWidth: '120px' }}
                        onClick={() => handleSizeSelect(option.value)}
                    >
                        {option.label}
                    </button>
                ))}
            </div>

            <div className="flex gap-4 flex-col justify-center align-center py-4 mb-3">
                <Select
                    className="text-[#434246] font-[400] focus:border-[#E55109] active:border-[none] focus:outline-none focus:shadow-none w-full md:w-[25%] hover:!border-[#E55109] h-full !rounded-lg"
                    value={imageType}
                    onChange={(value) => selectChange(value)}
                    placeholder="Select Image Type"
                    style={{ width: '200px', height: '30px' }}
                >
                    <Option value="">Select Image Type</Option>

                    <Option value="avatars">Avatars</Option>
                    <Option value="anime">Anime</Option>
                    <Option value="realistic">Realistic</Option>
                    <Option value="cartoon">Cartoon</Option>
                    <Option value="abstract">Abstract</Option>
                </Select>
                <div className="flex flex-col md:flex-row gap-2 w-full items-start md:items-center">
                    {/* <button
                        onClick={handleMicrophoneClick}
                        className="ml-2 bg-transparent border-none outline-none cursor-pointer py-2"
                    >
                        <BiMicrophone size={24} color="gray" />
                    </button> */}
                    <label className="text-gray-500 w-full md:w-[35%]">
                        Image description*:
                    </label>
                    <div className="border border-gray-700 w-full rounded-lg">
                        <Input.TextArea
                            className="placeholder-slate-500 focus:border-[#E55109] py-2 active:border-[#E55109] hover:border-[#E55109] !border-0 rounded-none  focus:shadow-none focus:border-0"
                            value={imageSubject}
                            onChange={(e) => setImageSubject(e.target.value)}
                            placeholder="e.g. business team, technology, nature"
                            autoSize={{ minRows: 1 }}
                        />
                    </div>
                    
                </div>
                <div className="flex flex-col md:flex-row gap-2 w-full items-start md:items-center">
                    {/* <button
                        onClick={handleMicrophoneClick}
                        className="ml-2 bg-transparent border-none outline-none cursor-pointer py-2"
                    >
                        <BiMicrophone size={24} color="gray" />
                    </button> */}
                    <label className="text-gray-500 w-full md:w-[35%]">
                        Background:
                    </label>
                    <div className="border border-gray-700 w-full rounded-lg">
                        <Input.TextArea
                            className="placeholder-slate-500 focus:border-[#E55109] py-2 active:border-[#E55109] hover:border-[#E55109] !border-0 rounded-none  focus:shadow-none focus:border-0"
                            value={imageBg}
                            onChange={(e) => setImageBg(e.target.value)}
                            placeholder="e.g. office, cityscape, abstract"
                            autoSize={{ minRows: 1 }}
                        />
                    </div>
                    
                </div>
                <div className="flex flex-col md:flex-row gap-2 w-full items-start md:placeholder:items-center">
                    {/* <button
                        onClick={handleMicrophoneClick}
                        className="ml-2 bg-transparent border-none outline-none cursor-pointer py-2"
                    >
                        <BiMicrophone size={24} color="gray" />
                    </button> */}
                    <label className="text-gray-500 w-full md:w-[35%]">
                        Style / mood:
                    </label>
                    <div className="border border-gray-700 w-full rounded-lg">
                        <Input.TextArea
                            className="placeholder-slate-500 focus:border-[#E55109] py-2 active:border-[#E55109] hover:border-[#E55109] !border-0 rounded-none  focus:shadow-none focus:border-0"
                            value={imageStyle}
                            onChange={(e) => setImageStyle(e.target.value)}
                            placeholder="e.g. modern, minimalist, vibrant"
                            autoSize={{ minRows: 1 }}
                        />
                    </div>
                    
                </div>
                <div className="flex flex-col md:flex-row gap-2 w-full items-start md:items-center">
                    {/* <button
                        onClick={handleMicrophoneClick}
                        className="ml-2 bg-transparent border-none outline-none cursor-pointer py-2"
                    >
                        <BiMicrophone size={24} color="gray" />
                    </button> */}
                    <label className="text-gray-500 w-full md:w-[35%]">
                        Colors / Textures:
                    </label>
                    <div className="border border-gray-700 w-full rounded-lg">
                        <Input.TextArea
                            className="focus:border-[#E55109] py-2 active:border-[#E55109] hover:border-[#E55109] !border-0 rounded-none  focus:shadow-none focus:border-0 placeholder-slate-500"
                            value={imageColor}
                            onChange={(e) => setImageColor(e.target.value)}
                            placeholder="e.g. blue and white, wooden, metallic"
                            autoSize={{ minRows: 1 }}
                        />
                    </div>
                    
                </div>
            </div>
            <button
                className="bg-[#E55109] text-[white] mt-2 border-none hover:bg-[#b6511e] text-sm py-2 px-3 disabled:bg-[#e4af95] rounded-md"
                disabled={loading}
                onClick={handleGenerateImage}
            >
                {!loading ? (
                    'Get started'
                ) : (
                    <div className="flex items-center text-sm text-center">
                        <Spinner />
                        <small>Generating your slides' content...</small>
                    </div>
                )}
            </button>
        </div>
    );
}

export default FirstScreen;

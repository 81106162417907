import { Col, Form, Input, Row } from 'antd';
import { useEffect, useState } from 'react';
import './style.scss';
import AIGenerate from '@icons/generate.svg';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import { BASE_URL } from '@src/utils/constants';
import { io, Socket } from 'socket.io-client';
import { onGenerateWithAI } from '../../curriculum/functions';

const { TextArea } = Input;

interface JobDescriptionFormValues {
    jobTitle: string;
    department: string;
    reportsTo: string;
    jobSummary: string;
    keyResponsibilities: string;
    requiredQualifications: string;
    preferredQualifications: string;
    skillsCompetencies: string;
    compensationBenefits: string;
    applicationProcess: string;
}

interface JobDescriptionLoadingStates {
    jobSummary: boolean;
    keyResponsibilities: boolean;
    requiredQualifications: boolean;
    preferredQualifications: boolean;
    skillsCompetencies: boolean;
}

const CustomTextAreaWithButton = ({
    placeholder,
    uniqueKey,
    loadingStates,
    setLoadingStates,
    value, // Add value prop
    onChange,
    onClickButton,
    checkIfCanGenerate,
}: {
    placeholder: string;
    uniqueKey: keyof JobDescriptionLoadingStates;
    loadingStates: JobDescriptionLoadingStates;
    setLoadingStates: React.Dispatch<React.SetStateAction<JobDescriptionLoadingStates>>;
    value?: string; // Optional value prop
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onClickButton: () => void;
    checkIfCanGenerate: () => boolean;
}) => {
    const handleButtonClick = () => {
        let canGenerate = checkIfCanGenerate();
        if ( !canGenerate ) return;
        setLoadingStates((prevState) => ({
            ...prevState,
            [uniqueKey]: true,
        }));
        onClickButton();
    };

    return (
        <div className="relative">
            <TextArea
                autoSize={{ minRows: 4}}
                placeholder={placeholder}
                value={value} // Bind value
                onChange={onChange} // Bind onChange
                className="p-2 border border-gray-500 focus:outline-none"
                style={{ outline: 'none', boxShadow: 'none' }}
            />
            {!loadingStates[uniqueKey] ? (
                <div
                    onClick={handleButtonClick}
                    className="cursor-pointer shadow-md rounded-[1rem] absolute top-[55px] right-[10px] py-[0.3rem] px-[1rem] bg-white text-normal text-[0.75rem] text-[var(--gmind-black)] flex gap-x-2"
                >
                    <img src={AIGenerate} alt="" />
                    <span>Use Gmind AI</span>
                </div>
            ) : (
                <div className="absolute top-[65px] right-[20px] ">
                    <CircularProgress size={15} className="w-1 h-1" />
                </div>
            )}
        </div>
    );
};

const JobDescriptionWriter = ({ onGenerate }: { onGenerate: (e: string) => void;}) => {
    const [form] = Form.useForm();
    const [page, setPage] = useState(1);
    const [loadingStates, setLoadingStates] = useState<JobDescriptionLoadingStates>({
        jobSummary: false,
        keyResponsibilities: false,
        requiredQualifications: false,
        preferredQualifications: false,
        skillsCompetencies: false,
    });
    const [sockets, setSockets] = useState<{ [key: string]: Socket | null}>({});
    const baseurl = BASE_URL;
    const socketKey = ['jobSummary', 'keyResponsibilities', 'requiredQualifications', 'preferredQualifications', 'skillsCompetencies']
    const [pageOneValues, setPageOneValues] = useState<{[key: string]: string}>({
        'jobTitle': '',
        'department': '',
        'reportsTo': '',
        'compensationBenefits': '',
        'jobSummary': '',
        'keyResponsibilities': '',
    });
    
    useEffect(() => {
        const newSockets: { [key: string]: Socket } = {};
        socketKey.forEach(item => {
            newSockets[item] = io(baseurl.slice(0, -2));
        });
        setSockets(newSockets);

        return () => {
            Object.values(newSockets).forEach(socket => {
                socket.close();
            });
        };
    }, []);

    useEffect(() => {
        socketKey.forEach(item => {
            const socket = sockets[item];
            if (!socket) return;

            const handleData = (data: string) => {
                let previousValue = form.getFieldValue(item);
                form.setFieldValue( item, previousValue + data);
            };

            const handleStreamEnd = () => {
                setLoadingStates((prevState) => ({
                    ...prevState,
                    [item]: false,
                }));
            };

            socket.on('data', handleData);
            socket.on('stream_end', handleStreamEnd);
            return () => {
                socket.off('data', handleData);
                socket.off('stream_end', handleData);
            };
        });
    }, [sockets]);

    const onFinish = (values: any) => {

        const {
            jobTitle,
            department,
            reportsTo,
            jobSummary,
            keyResponsibilities,
            compensationBenefits,
        } = pageOneValues;

        const {
            requiredQualifications,
            preferredQualifications,
            skillsCompetencies,
            applicationProcess,
        } = values;

        console.log({
            jobTitle,
            department,
            reportsTo,
            jobSummary,
            keyResponsibilities, 
            requiredQualifications, 
            preferredQualifications, 
            skillsCompetencies, 
            compensationBenefits, 
            applicationProcess, 
        })

        if (
            !jobTitle ||
            !department ||
            !reportsTo ||
            !jobSummary ||
            !keyResponsibilities ||
            !requiredQualifications ||
            !preferredQualifications ||
            !skillsCompetencies ||
            !compensationBenefits ||
            !applicationProcess
        ) {
            toast.error('Please fill all the required fields before generating.');
            return;
        }

        const promptMessageInfo = `
            Job Title: ${jobTitle}
            Department: ${department}
            Reports To: ${reportsTo}
            Job Summary: ${jobSummary}
            Key Responsibilities: ${keyResponsibilities}
            Required Qualifications: ${requiredQualifications}
            Preferred Qualifications: ${preferredQualifications}
            Skills and Competencies: ${skillsCompetencies}
            Compensation and Benefits: ${compensationBenefits}
            Application Process: ${applicationProcess}
        `;
        

        let promptMessage = `Generate a detailed and professional job description for the specified position with the following details: ${promptMessageInfo}. Kindly ensure to follow the details provided`;
        onGenerate(promptMessage);
    };

    const handleNext = () => {
        if (page < 2) {
            form.validateFields().then(() => {
                setPage(page + 1);
            });
        }
    };

    const handlePrevious = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    return (
        <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            initialValues={{
                jobTitle: '',
                department: '',
                reportsTo: '',
                jobSummary: '',
                keyResponsibilities: '',
                requiredQualifications: '',
                preferredQualifications: '',
                skillsCompetencies: '',
                compensationBenefits: '',
                applicationProcess: '',
            }}
        >
            <h1 className="text-xl font-bold font-Poppins mb-4 mt-4">Job Description Writer</h1>
            <p className="text-md font-Poppins mb-4">
                Create a comprehensive and informative job description with the help of this tool.
            </p>

            {page === 1 && (
                <>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <Form.Item
                                label="Job Title"
                                name="jobTitle"
                                rules={[{ required: true, message: 'Job Title is required' }]}
                            >
                                <Input placeholder="Enter job title" />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                label="Department"
                                name="department"
                                rules={[{ required: true, message: 'Department is required' }]}
                            >
                                <Input placeholder="Enter department" />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <Form.Item
                                label="Reports To"
                                name="reportsTo"
                                rules={[{ required: true, message: 'Reports To is required' }]}
                            >
                                <Input placeholder="Enter manager/supervisor" />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                label="Compensation and Benefits"
                                name="compensationBenefits"
                                rules={[{ required: true, message: 'Compensation and Benefits are required' }]}
                            >
                                <Input placeholder="Enter compensation and benefits details" />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <Form.Item
                                label="Job Summary"
                                name="jobSummary"
                                rules={[{ required: true, message: 'Job Summary is required' }]}
                            >
                                <CustomTextAreaWithButton
                                    placeholder="Let Gmind AI generate job summary"
                                    uniqueKey="jobSummary"
                                    loadingStates={loadingStates}
                                    setLoadingStates={setLoadingStates}
                                    value={form.getFieldValue('jobSummary')} // Bind value to form field
                                    onChange={(e) => form.setFieldValue('jobSummary', e.target.value)} // Update form value on change
                                    checkIfCanGenerate={() => {
                                        let jobTitle = form.getFieldValue('jobTitle');
                                        if ( !jobTitle ){
                                            toast.error('Enter job title to use this feature');
                                            return false;
                                        }

                                        let department = form.getFieldValue('department');
                                        if ( !department ){
                                            toast.error('Enter department to use this feature');
                                            return false;
                                        }

                                        let reportsTo = form.getFieldValue('reportsTo');
                                        if ( !reportsTo ){
                                            toast.error('Fill in reports to value to use this feature');
                                            return false;
                                        }

                                        let compensationBenefits = form.getFieldValue('compensationBenefits');
                                        if ( !compensationBenefits ){
                                            toast.error('Fill in compensation and benefits value to use this feature');
                                            return false;
                                        }
                                        
                                        return true;
                                    }}
                                    onClickButton={() => {
                                        form.setFieldValue('jobSummary', '')
                                        let jobDetails = `
                                            Title: ${ form.getFieldValue('jobTitle')}
                                            Department: ${ form.getFieldValue('department')}
                                            Reports To: ${ form.getFieldValue('reportsTo')}
                                            Compensation and Benefits: ${ form.getFieldValue('compensationBenefits')}
                                        `
                                        let prompt = `Generate a job summary with not more than 300 characters for a job with this details ${jobDetails}`;
                                        const socket = sockets["jobSummary"];
                                        onGenerateWithAI(socket, prompt);
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                label="Key Responsibilities"
                                name="keyResponsibilities"
                                rules={[{ required: true, message: 'Key Responsibilities are required' }]}
                            >
                                <CustomTextAreaWithButton
                                    placeholder="Let Gmind AI generate key responsibilities"
                                    uniqueKey="keyResponsibilities"
                                    loadingStates={loadingStates}
                                    setLoadingStates={setLoadingStates}
                                    value={form.getFieldValue('keyResponsibilities')} // Bind value to form field
                                    onChange={(e) => form.setFieldValue('keyResponsibilities', e.target.value)} // Update form value on change
                                    checkIfCanGenerate={() => {
                                        let jobTitle = form.getFieldValue('jobTitle');
                                        if ( !jobTitle ){
                                            toast.error('Enter job title to use this feature');
                                            return false;
                                        }

                                        let department = form.getFieldValue('department');
                                        if ( !department ){
                                            toast.error('Enter department to use this feature');
                                            return false;
                                        }
                                        
                                        return true;
                                    }}
                                    onClickButton={() => {
                                        form.setFieldValue('keyResponsibilities', '')
                                        let jobDetails = `
                                            Title: ${ form.getFieldValue('jobTitle')}
                                            Department: ${ form.getFieldValue('department')}
                                        `
                                        let prompt = `Generate a job key responsibilities with not more than 300 characters for a job with this details ${jobDetails}. Ensure to itemize key responsibilities`;
                                        const socket = sockets["keyResponsibilities"];
                                        onGenerateWithAI(socket, prompt);
                                    }}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <Row gutter={16}>
                        <Col span={24}>
                            <button
                                onClick={() =>
                                    form.validateFields().then(() => {
                                        setPageOneValues({
                                            'jobTitle': form.getFieldValue('jobTitle'),
                                            'department': form.getFieldValue('department'),
                                            'reportsTo': form.getFieldValue('reportsTo'),
                                            'compensationBenefits': form.getFieldValue('compensationBenefits'),
                                            'jobSummary': form.getFieldValue('jobSummary'),
                                            'keyResponsibilities': form.getFieldValue('keyResponsibilities'),
                                        })
                                        setPage(page + 1);
                                    })
                                }
                                className="w-full md:w-[8rem] bg-customOrange text-white rounded p-2"
                            >
                                Next
                            </button>
                        </Col>
                    </Row>
                </>
            )}
            {page === 2 && (
                <>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <Form.Item
                                label="Required Qualifications"
                                name="requiredQualifications"
                                rules={[{ required: true, message: 'Required Qualifications are required' }]}
                            >
                                <CustomTextAreaWithButton
                                    placeholder="Let Gmind AI generate required qualifications"
                                    uniqueKey="requiredQualifications"
                                    loadingStates={loadingStates}
                                    setLoadingStates={setLoadingStates}
                                    value={form.getFieldValue('requiredQualifications')} // Bind value to form field
                                    onChange={(e) => form.setFieldValue('requiredQualifications', e.target.value)} // Update form value on change
                                    checkIfCanGenerate={() => {
                                        return true;
                                    }}
                                    onClickButton={() => {
                                        form.setFieldValue('requiredQualifications', '')
                                        let jobDetails = `
                                            Title: ${ pageOneValues['jobTitle']}
                                            Department: ${ pageOneValues['department']}
                                            Reports To: ${ pageOneValues['reportsTo']}
                                            Compensation and Benefits: ${ pageOneValues['compensationBenefits']}
                                            Job Summary: ${ pageOneValues['jobSummary']}
                                            Key Responsibilities: ${ pageOneValues['keyResponsibilities']}
                                        `
                                        let prompt = `Generate a job Required Qualifications with not more than 500 characters for a job with this details ${jobDetails}. Ensure to itemize key responsibilities`;
                                        const socket = sockets["requiredQualifications"];
                                        onGenerateWithAI(socket, prompt);
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                label="Preferred Qualifications"
                                name="preferredQualifications"
                                rules={[{ required: true, message: 'Preferred Qualifications are required' }]}
                            >
                                <CustomTextAreaWithButton
                                    placeholder="Let Gmind AI generate preferred qualifications"
                                    uniqueKey="preferredQualifications"
                                    loadingStates={loadingStates}
                                    setLoadingStates={setLoadingStates}
                                    value={form.getFieldValue('preferredQualifications')} // Bind value to form field
                                    onChange={(e) => form.setFieldValue('preferredQualifications', e.target.value)} // Update form value on change
                                    checkIfCanGenerate={() => {
                                        return true;
                                    }}
                                    onClickButton={() => {
                                        form.setFieldValue('preferredQualifications', '')
                                        let jobDetails = `
                                            Title: ${ pageOneValues['jobTitle']}
                                            Department: ${ pageOneValues['department']}
                                            Reports To: ${ pageOneValues['reportsTo']}
                                            Compensation and Benefits: ${ pageOneValues['compensationBenefits']}
                                            Job Summary: ${ pageOneValues['jobSummary']}
                                            Key Responsibilities: ${ pageOneValues['keyResponsibilities']}
                                        `
                                        let prompt = `Generate a job preferred Qualifications with not more than 500 characters for a job with this details ${jobDetails}. Ensure to itemize key responsibilities`;
                                        const socket = sockets["preferredQualifications"];
                                        onGenerateWithAI(socket, prompt);
                                    }}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-4">
                        <div>
                            <Form.Item
                                label="Skills and Competencies"
                                name="skillsCompetencies"
                                rules={[{ required: true, message: 'Skills and Competencies are required' }]}
                            >
                                <CustomTextAreaWithButton
                                    placeholder="Let Gmind AI generate skills and competencies"
                                    uniqueKey="skillsCompetencies"
                                    loadingStates={loadingStates}
                                    setLoadingStates={setLoadingStates}
                                    value={form.getFieldValue('skillsCompetencies')} // Bind value to form field
                                    onChange={(e) => form.setFieldValue('skillsCompetencies', e.target.value)} // Update form value on change
                                    checkIfCanGenerate={() => {
                                        return true;
                                    }}
                                    onClickButton={() => {
                                        form.setFieldValue('skillsCompetencies', '')
                                        let jobDetails = `
                                            Title: ${ pageOneValues['jobTitle']}
                                            Department: ${ pageOneValues['department']}
                                            Reports To: ${ pageOneValues['reportsTo']}
                                            Compensation and Benefits: ${ pageOneValues['compensationBenefits']}
                                            Job Summary: ${ pageOneValues['jobSummary']}
                                            Key Responsibilities: ${ pageOneValues['keyResponsibilities']}
                                        `
                                        let prompt = `Generate a job skills and Competencies requirement with not more than 500 characters for a job with this details ${jobDetails}. Ensure to itemize key responsibilities`;
                                        const socket = sockets["skillsCompetencies"];
                                        onGenerateWithAI(socket, prompt);
                                    }}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-4">
                        <div>
                            <Form.Item
                                label="Application Process"
                                name="applicationProcess"
                                rules={[{ required: true, message: 'Application Process is required' }]}
                            >
                                <Input placeholder="Enter application process details" />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="flex justify-between">
                        <button
                            className="w-full md:w-[8rem] bg-transparent border border-customOrange text-customOrange rounded p-2"
                            onClick={handlePrevious}
                        >
                            Previous
                        </button>

                        <button type="submit" className="w-full md:w-[8rem] bg-customOrange text-white rounded p-2">
                            Generate
                        </button>
                    </div>
                </>
            )}
        </Form>
    );
};

export default JobDescriptionWriter;

/* eslint-disable react-hooks/exhaustive-deps */
import { SetStateAction, useEffect, useState } from 'react';
import Input from '../../components/Input';
import BackButton from '@src/components/BackButton';

const Welcome = ({
    option,
    textInput,
    isMobile,
}: {
    option: (v: string) => void;
    textInput: (v: string) => void;
    isMobile?: boolean;
}) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const options = [
        { value: 'Argumentative' },
        { value: 'Descriptive' },
        { value: 'Narrative' },
        { value: 'Expository' },
        { value: 'Analytical' },
        { value: 'Persuasive' },
        { value: 'Reflective' },
        { value: 'Academic' },
    ];

    useEffect(() => {
        option(options[selectedIndex].value);
    }, [selectedIndex]);

    return (
        <section className="w-full fixed h-full pt-20 max-md:top-10 px-8 flex flex-col gap-10">
            <BackButton />
            <div className="max-w-[65vh] max-md:max-w-[80vw] space-y-2">
                <h1 className="text-3xl md:text-4xl font-semibold">Article Writer</h1>
                <p style={{ wordSpacing: '0.8px' }} className="font-normal text-xs md:text-lg text-gray-500">
                    To get started select the tone of the article you want to generate below.
                </p>
            </div>

            <div className="grid max-md:grid grid-cols-4 max-md:grid-cols-2 flex-wrap max-w-[90%] md:w-[650px] max-md:max-w-full gap-4 items-center w-full">
                {options.map((op, i) => {
                    return (
                        <button
                            type="button"
                            onClick={() => setSelectedIndex(i)}
                            style={
                                selectedIndex === i
                                    ? { borderColor: '#ed5405', color: '#ed5405', background: '#fdf1eb' }
                                    : {}
                            }
                            key={op.value}
                            className="w-fit max-md:w-full flex items-center gap-2 text-sm p-2 px-2  border border-gray-500 text-gray-500 rounded-xl"
                        >
                            <span
                                style={
                                    selectedIndex === i
                                        ? { borderWidth: '5px' }
                                        : { borderWidth: '2px', padding: '0.45rem' }
                                }
                                className="border-brandOrange p-[0.3rem] rounded-full"
                            />
                            <p style={selectedIndex === i ? { fontWeight: '500' } : {}}>{op.value}</p>
                        </button>
                    );
                })}
            </div>
            <div
                style={
                    isMobile
                        ? { position: 'fixed', bottom: '0', left: '0', right: '0', paddingBottom: '1rem' }
                        : { position: 'absolute', bottom: '5rem', left: '0', right: '22%' }
                }
                className="bg-custom-opacity z-[100] max-lg:basis-0 px-10"
            >
                <Input
                    placeholder="Provide your information"
                    value={(v: string) => {
                        textInput(v);
                    } }
                    img={null} 
                    onPaste={() => {}} 
                    setImg={() => {}}               
                />
            </div>
        </section>
    );
};

export default Welcome;
